"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STATUS_LIST = exports.TRANSITION_REASON_LIST = exports.PRIORITY_LIST = exports.WW_RESPONSIBLE_LIST = exports.RESPONSIBLE_LIST = exports.CONTEXT_LIST = void 0;
const CONTEXT_LIST = ['Data Uptime', 'Metering', 'FDD', 'Optimisation', 'Warranty', 'Other'];
exports.CONTEXT_LIST = CONTEXT_LIST;
const RESPONSIBLE_LIST = ['Bueno', 'Site Local', 'Site National', 'Mech. Contractor', 'BMS Contractor', 'Other Contractor', 'Consultant Engineer', 'Monitoring'];
/**
 * Woolworth specific responsibilities list
 */

exports.RESPONSIBLE_LIST = RESPONSIBLE_LIST;
const WW_RESPONSIBLE_LIST = ['Asset Team - Building', 'Asset Team - Electrical', 'Asset Team - Plumbing', 'Asset Team - Refrigeration', 'EMC - Energy Optimisation Specialist', 'EMC - Operations/IT', 'External Contractors (Back to Back)', 'External Contractors (Others)', 'Field Maintenance Technician (FMT) - HVAC', 'Field Maintenance Technician (FMT) - Refrigeration', 'Service Team (STL)', 'State Admin Manager (SAM)', 'Technical Support Team (TST)', 'WFM Support Centre', 'Other', 'Bueno'];
exports.WW_RESPONSIBLE_LIST = WW_RESPONSIBLE_LIST;
const PRIORITY_LIST = ['Blocker', 'Critical', 'Major', 'Minor'];
exports.PRIORITY_LIST = PRIORITY_LIST;
const TRANSITION_REASON_LIST = [// Closed
'Fixed', 'False alert', 'Cannot fix (known issue)', 'Duplicate', // parked
'Too difficult/expensive', 'Low priority', 'Other', 'Rejected'];
/**
 * Status only applicable to creation and can be changed later
 */

exports.TRANSITION_REASON_LIST = TRANSITION_REASON_LIST;
const STATUS_LIST = ['Triage', // 'To Be Raised MM', deprecated
'New Action', 'Work In Progress', 'Scope Required', 'To Be Quoted', 'Pending PO', 'In Review', 'Parked', 'Closed', 'Rejected'];
exports.STATUS_LIST = STATUS_LIST;