"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getContextMap = getContextMap;
exports.getContextOptions = getContextOptions;
exports.getResponsibleMap = getResponsibleMap;
exports.getResponsibleOptions = getResponsibleOptions;
exports.getPriorityMap = getPriorityMap;
exports.getPriorityOptions = getPriorityOptions;
exports.getTransitionReasonMap = getTransitionReasonMap;
exports.getTransitionReasonOptions = getTransitionReasonOptions;
exports.getStatusMap = getStatusMap;
exports.getStatusOrder = getStatusOrder;
exports.getStatusOptions = getStatusOptions;

var _lists = require("../../../utils/lists");

function getContextMap(i18n) {
  return {
    Warranty: i18n._({
      id: 'context_list.warranty_label',
      message: 'Warranty'
    }),
    Metering: i18n._({
      id: 'context_list.metering_label',
      message: 'Metering'
    }),
    FDD: i18n._({
      id: 'context_list.fdd_label',
      message: 'FDD'
    }),
    Optimisation: i18n._({
      id: 'context_list.optimisation_label',
      message: 'Optimisation'
    }),
    'Data Uptime': i18n._({
      id: 'context_list.data_uptime_label',
      message: 'Data Uptime'
    }),
    Other: i18n._({
      id: 'context_list.other_label',
      message: 'Other'
    })
  };
}

function getContextOptions(i18n) {
  const labelMap = getContextMap(i18n);
  return _lists.CONTEXT_LIST.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}

function getResponsibleMap(i18n, isWOOL = false) {
  return isWOOL ? {
    'Asset Team - Building': i18n._({
      id: 'responsible_list.asset_team_building',
      message: `Asset Team - Building`
    }),
    'Asset Team - Electrical': i18n._({
      id: 'responsible_list.asset_team_electrical',
      message: `Asset Team - Electrical`
    }),
    'Asset Team - Plumbing': i18n._({
      id: 'responsible_list.asset_team_plumbing',
      message: `Asset Team - Plumbing`
    }),
    'Asset Team - Refrigeration': i18n._({
      id: 'responsible_list.asset_team_refrigeration',
      message: `Asset Team - Refrigeration`
    }),
    'EMC - Energy Optimisation Specialist': i18n._({
      id: 'responsible_list.emc_energy_optimisation_specialist',
      message: `EMC - Energy Optimisation Specialist`
    }),
    'EMC - Operations/IT': i18n._({
      id: 'responsible_list.emc_operations_it',
      message: `EMC - Operations/IT`
    }),
    'External Contractors (Back to Back)': i18n._({
      id: 'responsible_list.external_contractors_back_to_back',
      message: `External Contractors (Back to Back)`
    }),
    'External Contractors (Others)': i18n._({
      id: 'responsible_list.external_contractors_others',
      message: `External Contractors (Others)`
    }),
    'Field Maintenance Technician (FMT) - HVAC': i18n._({
      id: 'responsible_list.fmt_hvac',
      message: `Field Maintenance Technician (FMT) - HVAC`
    }),
    'Field Maintenance Technician (FMT) - Refrigeration': i18n._({
      id: 'responsible_list.fmt_refrigeration',
      message: `Field Maintenance Technician (FMT) - Refrigeration`
    }),
    'Service Team (STL)': i18n._({
      id: 'responsible_list.service_team',
      message: `Service Team (STL)`
    }),
    'State Admin Manager (SAM)': i18n._({
      id: 'responsible_list.state_admin_manager',
      message: `State Admin Manager (SAM)`
    }),
    'Technical Support Team (TST)': i18n._({
      id: 'responsible_list.technical_support_team',
      message: `Technical Support Team (TST)`
    }),
    'WFM Support Centre': i18n._({
      id: 'responsible_list.wfm_support_centre',
      message: `WFM Support Centre`
    }),
    Other: i18n._({
      id: 'responsible_list.other',
      message: `Other`
    }),
    Bueno: i18n._({
      id: 'responsible_list.bueno',
      message: `Bueno`
    })
  } : {
    Bueno: i18n._({
      id: 'responsible_list.bueno',
      message: `Bueno`
    }),
    'Site Local': i18n._({
      id: 'responsible_list.site_local',
      message: `Site Local`
    }),
    'Site National': i18n._({
      id: 'responsible_list.site_national',
      message: `Site National`
    }),
    'Mech. Contractor': i18n._({
      id: 'responsible_list.mech_contractor',
      message: `Mech. Contractor`
    }),
    'BMS Contractor': i18n._({
      id: 'responsible_list.bms_contractor',
      message: `BMS Contractor`
    }),
    'Other Contractor': i18n._({
      id: 'responsible_list.other_contractor',
      message: `Other Contractor`
    }),
    'Consultant Engineer': i18n._({
      id: 'responsible_list.consultant_engineer',
      message: `Consultant Engineer`
    }),
    Monitoring: i18n._({
      id: 'responsible_list.monitoring',
      message: `Monitoring`
    })
  };
}

function getResponsibleOptions(i18n, legacySiteId = '') {
  // HACK: Mistakes were made...
  // If selected site is a WOOL site, return WOOL responsible list
  const isWoolSite = legacySiteId.split('~')[0] === 'WOOL';
  const list = isWoolSite ? _lists.WW_RESPONSIBLE_LIST : _lists.RESPONSIBLE_LIST;
  const labelMap = getResponsibleMap(i18n, isWoolSite);
  return list.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}

function getPriorityMap(i18n) {
  return {
    Blocker: i18n._({
      id: 'priority_list.blocker',
      message: `Blocker`
    }),
    Critical: i18n._({
      id: 'priority_list.critical',
      message: `Critical`
    }),
    Major: i18n._({
      id: 'priority_list.major',
      message: `Major`
    }),
    Moderate: i18n._({
      id: 'priority_list.moderate',
      message: `Moderate`
    }),
    Minor: i18n._({
      id: 'priority_list.minor',
      message: `Minor`
    }),
    Trivial: i18n._({
      id: 'priority_list.trivial',
      message: `Trivial`
    })
  };
}

function getPriorityOptions(i18n) {
  const labelMap = getPriorityMap(i18n);
  return _lists.PRIORITY_LIST.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}

function getTransitionReasonMap(i18n) {
  return {
    Fixed: i18n._({
      id: 'transition_reason_list.fixed',
      message: `Fixed`
    }),
    'False alert': i18n._({
      id: 'transition_reason_list.false_alert',
      message: `False alert`
    }),
    'Cannot fix (known issue)': i18n._({
      id: 'transition_reason_list.cannot_fix',
      message: `Cannot fix (known issue)`
    }),
    Duplicate: i18n._({
      id: 'transition_reason_list.duplicate',
      message: `Duplicate`
    }),
    'Too difficult/expensive': i18n._({
      id: 'transition_reason_list.too_difficult_expensive',
      message: `Too difficult/expensive`
    }),
    'Low priority': i18n._({
      id: 'transition_reason_list.low_priority',
      message: `Low priority`
    }),
    Other: i18n._({
      id: 'transition_reason_list.other',
      message: `Other`
    })
  };
}

function getTransitionReasonOptions(i18n) {
  const labelMap = getTransitionReasonMap(i18n);
  return _lists.TRANSITION_REASON_LIST.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}

function getStatusMap(i18n) {
  return {
    Triage: i18n._({
      id: 'status_list.triage',
      message: `Triage`
    }),
    'To Be Raised MM': i18n._({
      id: 'status_list.to_be_raised_mm',
      message: `New Action`
    }),
    'New Action': i18n._({
      id: 'status_list.new_action',
      message: `New Action`
    }),
    'Work In Progress': i18n._({
      id: 'status_list.work_in_progress',
      message: `In Progress`
    }),
    'Scope Required': i18n._({
      id: 'status_list.scope_required',
      message: `Scope Required`
    }),
    'To Be Quoted': i18n._({
      id: 'status_list.to_be_quoted',
      message: `To Be Quoted`
    }),
    'Pending PO': i18n._({
      id: 'status_list.pending_po',
      message: `Pending PO`
    }),
    'In Review': i18n._({
      id: 'status_list.in_review',
      message: `In Review`
    }),
    Parked: i18n._({
      id: 'status_list.parked',
      message: `Parked`
    }),
    Closed: i18n._({
      id: 'status_list.closed',
      message: `Closed`
    }),
    Rejected: i18n._({
      id: 'status_list.rejected',
      message: `Rejected`
    })
  };
}

function getStatusOrder(status) {
  var _Triage$NewAction$Wo;

  return (_Triage$NewAction$Wo = {
    Triage: 1,
    // cyan
    'New Action': 2,
    'Work In Progress': 3,
    // green
    'In Review': 4,
    'To Be Raised MM': 5,
    'Scope Required': 6,
    // orange
    'To Be Quoted': 7,
    'Pending PO': 8,
    Parked: 9,
    Closed: 10,
    // grey
    Rejected: 11
  }[status]) !== null && _Triage$NewAction$Wo !== void 0 ? _Triage$NewAction$Wo : -1;
}
/**
 * @param i18n I18n instance
 * @param exclude array of keys to exlude from teh STATUS_LIST array
 */


function getStatusOptions(i18n, exclude) {
  const labelMap = getStatusMap(i18n);
  let statuses = _lists.STATUS_LIST;

  if (exclude !== undefined) {
    statuses = _lists.STATUS_LIST.filter(s => !exclude.includes(s));
  }

  return statuses.map(l => ({
    value: l,
    label: labelMap[l]
  }));
}