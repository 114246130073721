"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _types = require("./types");

function reducer(state, action) {
  const actionType = action.type;

  switch (action.type) {
    case 'LOAD_METRICS':
      return { ...state,
        metricsLoading: true
      };

    case 'SET_SELECTED_SITES':
      return { ...state,
        selectedSites: action.selectedSites,
        metricsLoaded: {
          [_types.MetricsType.Electricity]: false,
          [_types.MetricsType.Gas]: false,
          [_types.MetricsType.Water]: false
        }
      };

    case 'SET_METRICS_TYPE':
      return { ...state,
        metricsType: action.metricsType,
        metricsUnit: _types.MetricsUnit[action.metricsType]
      };

    case 'SET_PRIMARY_COMPARE_BY':
      return { ...state,
        primaryCompareBy: action.value
      };

    case 'SET_RANKED_COMPARE_BY':
      return { ...state,
        rankedCompareBy: action.value
      };

    case 'METRICS_REQUEST_SUCCESS':
      {
        const isEmpty = Object.keys(action.metrics.portfolio).length === 0;
        const newState = { ...state,
          metricsLoading: false,
          metricsLoadingError: false,
          metricsLoaded: { ...state.metricsLoaded,
            [action.metricsType]: true
          },
          metricsType: action.metricsType,
          metricsUnit: action.metricsUnit,
          metrics: { ...state.metrics,
            [action.metricsType]: isEmpty ? undefined : { ...action.metrics
            }
          }
        };
        return newState;
      }

    case 'METRICS_REQUEST_FAIL':
      return { ...state,
        metricsLoading: false,
        metricsLoadingError: true,
        metricsLoaded: { ...state.metricsLoaded,
          [state.metricsType]: false
        }
      };

    default:
      throw new Error(`Unhandled action type: ${actionType}`);
  }
}