"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFeatureFlag = useFeatureFlag;

var _launchdarklyReactClientSdk = require("launchdarkly-react-client-sdk");

function useFeatureFlag(flag) {
  var _useFlags$flag;

  return (_useFlags$flag = (0, _launchdarklyReactClientSdk.useFlags)()[flag]) !== null && _useFlags$flag !== void 0 ? _useFlags$flag : false;
}