"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.truncateString = truncateString;
exports.escapeRegExp = escapeRegExp;

function truncateString(str, maxSize) {
  if (str.length > maxSize) {
    return `${str.substring(0, maxSize - 3)}...`;
  }

  return str;
}
/**
 * Escape regex-sensitive characters
 */


function escapeRegExp(text = '') {
  return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
}