"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadAttachment = uploadAttachment;

var _axios = _interopRequireDefault(require("axios"));

/** Upload a file to Hydra's attachment storage */
async function uploadAttachment({
  organisationId,
  actionId,
  userId,
  commentId,
  file
}) {
  const formData = new FormData();
  formData.append('file', file);
  const body = {
    organisationId,
    filename: file.name,
    actionId,
    createdBy: userId,
    commentId
  };
  formData.append('body', new Blob([JSON.stringify(body)], {
    type: 'application/json'
  }));
  const hydraResponse = await _axios.default.post('/v1/attachments', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return hydraResponse.data;
}