"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FieldLabel = exports.CheckboxWrap = exports.RuleName = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const RuleName = (0, _styledComponents.default)(_blocks.DisplaySmall)`
  margin-bottom: ${_blocks.spacing.default};
`;
exports.RuleName = RuleName;
const CheckboxWrap = _styledComponents.default.div`
  margin-bottom: ${_blocks.spacing.default};
`;
exports.CheckboxWrap = CheckboxWrap;
const FieldLabel = (0, _styledComponents.default)(_blocks.Body)`
  margin-bottom: ${_blocks.spacing.tight};
  font-weight: 700;
`;
exports.FieldLabel = FieldLabel;