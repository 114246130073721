"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.siteSelectEvent = siteSelectEvent;
exports.exportInsightDataEvent = exports.exportActionDataEvent = exports.routeChangeEvent = exports.expandAllEvent = exports.messagePostedEvent = exports.associatedInsightWithActionEvent = exports.viewedActionEvent = exports.addedCommentToActionEvent = exports.addedActionEvent = exports.searchedEvent = exports.snoozeEquipEvent = exports.clickedHistoryViewerLinkEvent = exports.clickedTrendGraphEvent = exports.viewedInsightEvent = void 0;

var _segment = _interopRequireDefault(require("./segment"));

var _eventTrackingHelper = require("./eventTrackingHelper");

/**
 * Segment.io tracking calls for action events
 */
const viewedInsightEvent = ruleGroup => {
  try {
    _segment.default.track('Viewed Insight', (0, _eventTrackingHelper.parseRuleViewedEventPayload)(ruleGroup));
  } catch (err) {
    console.error(`Segment Error: viewedInsightEvent - ${err}`);
  }
};

exports.viewedInsightEvent = viewedInsightEvent;

const clickedTrendGraphEvent = asset => {
  try {
    _segment.default.track('Clicked Link To Trend Graph', (0, _eventTrackingHelper.parseReportViewedEventPayload)(asset));
  } catch (err) {
    console.error(`Segment Error: clickedTrendGraphEvent - ${err}`);
  }
};

exports.clickedTrendGraphEvent = clickedTrendGraphEvent;

const clickedHistoryViewerLinkEvent = asset => {
  try {
    // NOTE: we are tracking identical properties for Clicked Link To History View
    // as we track Clicked Link To Trend Graph
    _segment.default.track('Clicked Link To History Viewer', (0, _eventTrackingHelper.parseReportViewedEventPayload)(asset));
  } catch (err) {
    console.error(`Segment Error: clickedHistoryViewerLinkEvent - ${err}`);
  }
};

exports.clickedHistoryViewerLinkEvent = clickedHistoryViewerLinkEvent;

const snoozeEquipEvent = (siteName, duration, equipRuleId, successResult) => {
  try {
    _segment.default.track('Snoozed Equip', (0, _eventTrackingHelper.parseSnoozeEquipRuleEventPayload)(siteName, duration, equipRuleId, successResult));
  } catch (err) {
    console.error(`Segment Error: snoozeEquipEvent - ${err}`);
  }
};

exports.snoozeEquipEvent = snoozeEquipEvent;

const searchedEvent = () =>
/* searchName, searchString */
{// TODO: Reinstate once search tracking behaviour has been finalised.
  // try {
  //   if (searchString) {
  //     segment.track(`Searched ${searchName}`, {
  //       query: searchString
  //     })
  //   }
  // } catch (err) {
  //   console.error(`Segment Error: searchedEvent - ${err}`)
  // }
};

exports.searchedEvent = searchedEvent;

function siteSelectEvent(selected, userSites = [], userProjects = []) {
  try {
    const siteCount = selected.length;
    const projectIds = selected.map(id => userSites[id].projectId); // Unique project names from selected sites

    const projects = Array.from(new Set(projectIds.map(id => userProjects[id].project_name)));
    const projectCount = projects.length;

    _segment.default.track('Updated Site Select', {
      projects,
      projectCount,
      siteCount
    });
  } catch (err) {
    console.error(`Segment Error: siteSelectEvent - ${err}`);
  }
}
/**
 * FYI: This event currently used for create action in Insights and Add action in Actions scene
 */


const addedActionEvent = (issueKey, issue, site = null, associateResult = null, associateError = null) => {
  try {
    _segment.default.track('Added Action', (0, _eventTrackingHelper.parseAddedActionEventPayload)(issueKey, issue, site, associateResult, associateError));
  } catch (err) {
    console.error(`Segment Error: addedActionEvent - ${err}`);
  }
};

exports.addedActionEvent = addedActionEvent;

const addedCommentToActionEvent = (action, comment) => {
  try {
    _segment.default.track('Added Comment to Action', (0, _eventTrackingHelper.parseAddedCommentToActionEventPayload)(action, comment));
  } catch (err) {
    console.error(`Segment Error: addedCommentToActionEvent - ${err}`);
  }
};

exports.addedCommentToActionEvent = addedCommentToActionEvent;

const viewedActionEvent = actionId => {
  try {
    _segment.default.track('Viewed Action', (0, _eventTrackingHelper.parseViewedActionEventPayload)(actionId));
  } catch (err) {
    console.error(`Segment Error: viewedActionEvent - ${err}`);
  }
};

exports.viewedActionEvent = viewedActionEvent;

const associatedInsightWithActionEvent = (betaIssues, successResult) => {
  try {
    _segment.default.track('Associated Insight With Action', (0, _eventTrackingHelper.parseAssociateInsightWithActionEventPayload)(betaIssues, successResult));
  } catch (err) {
    console.error(`Segment Error: associatedInsightWithActionEvent - ${err}`);
  }
};

exports.associatedInsightWithActionEvent = associatedInsightWithActionEvent;

const messagePostedEvent = (issue, commentResult) => {
  try {
    _segment.default.track('Message Posted', (0, _eventTrackingHelper.parsePostCommentEventPayload)(issue, commentResult));
  } catch (err) {
    console.error(`Segment Error: messagePostedEvent - ${err}`);
  }
};

exports.messagePostedEvent = messagePostedEvent;

const expandAllEvent = expanded => {
  try {
    _segment.default.track('Expanded/Collapsed Monitoring List', {
      intent: expanded ? 'expand' : 'collapse'
    });
  } catch (err) {
    console.error(`Segment Error: expandAllEvent - ${err}`);
  }
};

exports.expandAllEvent = expandAllEvent;

const routeChangeEvent = ({
  search: rawSearch,
  pathname
}) => {
  try {
    const search = (0, _eventTrackingHelper.stripSitesQuery)(rawSearch);

    _segment.default.track('Page Viewed', {
      search,
      pathname
    });
  } catch (err) {
    console.error(`Segment Error: expandAllEvent - ${err}`);
  }
}; // * Track when user export action csv data


exports.routeChangeEvent = routeChangeEvent;

const exportActionDataEvent = (formattedData, startDate, endDate) => {
  try {
    const siteNames = new Set();
    const priority = new Set();
    const status = new Set();
    formattedData.forEach(data => {
      siteNames.add(data.siteName);
      priority.add(data.priority);
      status.add(data.status);
    });

    _segment.default.track(`Export action data`, {
      siteNames: [...siteNames],
      priority: [...priority],
      status: [...status],
      totalActions: formattedData.length,
      startDate,
      endDate
    });
  } catch (err) {
    console.error(`Segment Error: exportEvent - ${err}`);
  }
}; // * Track when user export insight csv data


exports.exportActionDataEvent = exportActionDataEvent;

const exportInsightDataEvent = (formattedData, startDate, endDate) => {
  try {
    const siteNames = new Set();
    const equipNames = new Set();
    const ruleNames = new Set();
    formattedData.forEach(data => {
      siteNames.add(data.siteName);
      equipNames.add(data.equipName);
      ruleNames.add(data.ruleName);
    });

    _segment.default.track(`Export insight data`, {
      siteNames: [...siteNames],
      equipNames: [...equipNames],
      ruleNames: [...ruleNames],
      totalInsights: formattedData.length,
      startDate,
      endDate
    });
  } catch (err) {
    console.error(`Segment Error: exportEvent - ${err}`);
  }
};

exports.exportInsightDataEvent = exportInsightDataEvent;