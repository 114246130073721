"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trimWithEllipsis = trimWithEllipsis;

/**
 * Trims a string to the designated length (plus the ellipsis characters)
 * If `str` length is less than `length` then `str` is returned unaltered
 *
 * @param str The string to trim
 * @param length Number of characters to trim to
 * @param ellipsis Characters to append to end of trimmed string
 */
function trimWithEllipsis(str = '', length = 100, ellipsis = '...') {
  if (str.length <= length) return str;
  const trimCharLength = ellipsis.length;
  return `${str.substring(0, length - trimCharLength)}${ellipsis}`;
}