"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.launchDarklyUserSelector = void 0;

var _reselect = require("reselect");

var _lodash = _interopRequireDefault(require("lodash.isequal"));

var _user = require("../../redux/selectors/user");

const launchDarklyUserSelector = (0, _reselect.createSelector)([_user.selectUserId, _user.selectUserDisplayName, _user.selectUserEmail], (userId, userDisplayName, userEmail) => {
  if (userId == null) return undefined;
  const user = {
    kind: 'user',
    key: userId,
    name: userDisplayName,
    email: userEmail
  };
  return user;
}, {
  memoizeOptions: {
    resultEqualityCheck: _lodash.default
  }
});
exports.launchDarklyUserSelector = launchDarklyUserSelector;