"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zendeskSettings = void 0;

/**
 * Zendesk web widget settings
 *
 * [Settings reference](https://developer.zendesk.com/embeddables/docs/widget/settings)
 */
const zendeskSettings = {
  webWidget: {
    contactOptions: {
      enabled: true,
      contactButton: {
        '*': 'Chat or Message'
      },
      contactFormLabel: {
        '*': 'Leave us a Message'
      },
      chatLabelOnline: {
        '*': 'Live Chat'
      },
      chatLabelOffline: {
        '*': 'Chat is unavailable'
      }
    },
    contactForm: {
      title: {
        '*': 'Message Us'
      },
      selectTicketForm: {
        '*': 'What would you like to do?'
      },
      ticketForms: [{
        id: 360000594553
      }, {
        id: 360000600433
      }, {
        id: 360000600493
      }]
    },
    launcher: {
      label: {
        '*': 'Help'
      },
      chatLabel: {
        '*': 'Chat'
      }
    },
    helpCenter: {
      title: {
        '*': 'Help Center'
      },
      searchPlaceholder: {
        '*': 'Search our Help Center'
      },
      messageButton: {
        '*': 'Message us'
      },
      filter: {
        category: [{
          id: '360001343673'
        }, {
          id: '360001363453'
        }]
      }
    },
    zIndex: 1
  },
  chat: {
    title: {
      '*': 'Chat'
    },
    offlineForm: {
      greeting: {
        '*': "We aren't online right now, please leave a message and we'll get back to you shortly."
      }
    }
  }
};
exports.zendeskSettings = zendeskSettings;