"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMonthYearObj = getMonthYearObj;
exports.getPreviousMonths = getPreviousMonths;
exports.todayYesterday = todayYesterday;
exports.hoursToSeconds = hoursToSeconds;
exports.secondsToHours = secondsToHours;
exports.daysToSeconds = daysToSeconds;
exports.secondsToDays = secondsToDays;
exports.MONTHS = void 0;

var _dateFns = require("date-fns");

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
exports.MONTHS = MONTHS;

/**
 * Create a `MonthYear` object given a moment object
 */
function getMonthYearObj(from) {
  const m = from ? new Date(from) : new Date();
  return {
    timestamp: m,
    month: MONTHS[(0, _dateFns.getMonth)(m)],
    year: `${(0, _dateFns.getYear)(m)}`
  };
}
/**
 * Return a list of previous `MonthYear`s
 */


function getPreviousMonths(delta, count = 0, months = []) {
  if (count <= delta - 1) {
    months.push(getMonthYearObj((0, _dateFns.sub)(new Date(), {
      months: count
    })));
    return getPreviousMonths(delta, count + 1, months);
  }

  return months;
}

function todayYesterday(ts) {
  if ((0, _dateFns.isToday)(new Date(ts))) {
    return 'Today';
  }

  if ((0, _dateFns.isYesterday)(new Date(ts))) {
    return 'Yesterday';
  }

  return (0, _dateFns.formatDistanceToNowStrict)(new Date(ts));
}

function hoursToSeconds(hours) {
  return hours * 3600;
}

function secondsToHours(seconds) {
  return seconds / 3600;
}

function daysToSeconds(days) {
  return days * 86400;
}

function secondsToDays(seconds) {
  return seconds / 86400;
}