"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _ = require("./");

function useActions() {
  const [state, dispatch] = (0, _.useActionField)();
  const timeoutRef = React.useRef();
  const updateFields = React.useCallback(async (actionId, fields) => {
    /**
     * If there's a pending RESET timer, clear it
     */
    if (timeoutRef.current !== undefined) {
      clearTimeout(timeoutRef.current);
    }

    dispatch({
      type: 'UPDATE_FIELDS',
      actionId,
      fields
    });

    try {
      const endpoint = `v2/actions/${actionId}`;
      const response = await _axios.default.put(endpoint, fields);

      if (response.status === 200) {
        dispatch({
          type: 'SET_COMPLETE'
        });
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        error
      });
      throw error;
    }
  }, []);
  React.useEffect(() => {
    /**
     * Reset the status to 'idle' 5 seconds after
     * a successful field update.
     */
    if (state.status === _.Status.complete) {
      timeoutRef.current = setTimeout(() => {
        dispatch({
          type: 'RESET'
        });
      }, 5000);
    }

    return () => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [state.status]);
  return {
    updateFields
  };
}