"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAction = addAction;
exports.addActionFlow = addActionFlow;

var _effects = require("redux-saga/effects");

var actionTypes = _interopRequireWildcard(require("./actionTypes"));

var _actionCreators = require("./actionCreators");

var _serialiseAction = require("./utils/serialiseAction");

var _eventTracking = require("../../../utils/eventTracking");

var _associate = require("../_BETA/associate");

/* eslint-disable no-eq-null */

/**
 * Create a new action in Jira. Only exposed for tests, this generator is used
 * internally.
 * @param {Object} action A serialised action object
 * @param {Array<number>} equipList A list of equipRule ids for associating post action creation
 * @param {Object?} attachment
 */
function* addAction(action, equipList, attachmentData) {
  yield (0, _effects.put)((0, _actionCreators.addActionRequestChain)(action));
  const {
    actionCreated
  } = yield (0, _effects.race)({
    actionCreated: (0, _effects.take)(actionTypes.ADD_ACTION_REQUEST_SUCCESS),
    failure: (0, _effects.take)(actionTypes.ADD_ACTION_REQUEST_FAIL)
  });

  if (actionCreated && equipList.length > 0) {
    yield (0, _effects.put)((0, _associate.postAssociateEquipRule)({
      equipRuleIds: equipList,
      issueKey: actionCreated.result.issueKey
    })); // NOTE: Wait for the association to succeed. The error for this is
    // "handled" in the associate saga (TODO: it's just thrown)

    yield (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS);
  }

  if (actionCreated && attachmentData != null) {
    const payload = {
      actionId: Number(actionCreated.result.issueKey.split('-')[1]),
      attachment: attachmentData.attachment,
      organisationId: attachmentData.organisationId,
      userId: attachmentData.userId
    };
    yield (0, _effects.put)((0, _actionCreators.uploadAttachmentRequestChain)(payload));
    yield (0, _effects.take)(actionTypes.UPLOAD_HYDRA_ATTACHMENT_REQUEST_SUCCESS);
  }

  if (actionCreated) {
    yield (0, _effects.put)((0, _actionCreators.addActionComplete)());
    (0, _eventTracking.addedActionEvent)(actionCreated.result.issueKey, action);
  }
}
/**
 * The saga a user will follow to create a new action in the jira backend
 */


function* addActionFlow() {
  while (true) {
    const {
      action,
      equipList = []
    } = yield (0, _effects.take)(actionTypes.ADD_ACTION);
    const {
      organisationId,
      userId,
      attachment
    } = action; // Serialise the action into a format the create API can consume

    const serialisedAction = (0, _serialiseAction.serialiseCreateAction)(action);
    const attachmentProps = action.screenshot == null && attachment == null ? undefined : {
      attachment,
      organisationId,
      userId
    };
    yield (0, _effects.fork)(addAction, serialisedAction, equipList, attachmentProps);
  }
}