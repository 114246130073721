"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.groupByRule = exports.groupByPriority = exports.groupBySite = void 0;

var _lodash = _interopRequireDefault(require("lodash.groupby"));

var _utils = require("../../../redux/modules/insights/utils");

const groupBySite = (occurrences, uniqActionCount = false) => {
  if (occurrences.length < 1) return [];
  return (0, _utils.composeInsights)((0, _lodash.default)(occurrences, o => o.ruleId), uniqActionCount);
};

exports.groupBySite = groupBySite;

const groupByPriority = (occurrences, uniqActionCount = false) => {
  if (occurrences.length < 1) return []; // NOTE: generating group key as combination of siteId + ruleId
  // to ensure site/rule relationship is maintained when grouping by priority

  return (0, _utils.composeInsights)((0, _lodash.default)(occurrences, o => `${o.siteId} ${o.ruleId}`), uniqActionCount);
};

exports.groupByPriority = groupByPriority;

const groupByRule = occurrences => {
  if (occurrences.length < 1) return [];
  return (0, _utils.composeInsights)((0, _lodash.default)(occurrences, o => o.siteId));
};

exports.groupByRule = groupByRule;