"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GoBack = exports.ActivityColumn = exports.Column = exports.Wrapper = exports.Frame = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactRouterDom = require("react-router-dom");

var _blocks = require("@bueno/blocks");

const Frame = (0, _styledComponents.default)(_blocks.FrameContent)`
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-row-gap: ${_blocks.spacing.default};
  height: 100%;
  max-height: 100%;
  position: relative;
`;
exports.Frame = Frame;
const Wrapper = _styledComponents.default.section`
  border: 0;
  background-color: ${_blocks.palette.pureWhite};
  border-radius: ${_blocks.borderRadius.large};
  box-shadow: ${_blocks.elevation.content};
  /* NOTE: this min-height is used to take up the grid template row space
    it seems like the value doesn't matter but the property need to be present
  */
  min-height: 1px;
  /* to show the complete border radius */
  overflow: hidden;
`;
exports.Wrapper = Wrapper;
const Column = _styledComponents.default.div`
  flex-basis: 50%;
  max-width: 50%;
  overflow: auto;
`;
exports.Column = Column;
const ActivityColumn = (0, _styledComponents.default)(Column)`
  border-left: 1px solid ${_blocks.palette.neutral3};
  background: ${_blocks.palette.neutral1};
  overflow: hidden;
`;
exports.ActivityColumn = ActivityColumn;
const GoBack = (0, _styledComponents.default)(_reactRouterDom.Link)`
  text-decoration: none;
`;
exports.GoBack = GoBack;