"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Chip = exports.StyledBodyLarge = exports.Detail = exports.SiteLabel = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const SiteLabel = (0, _styledComponents.default)(_blocks.BodyLarge)`
  padding: ${_blocks.spacing.loose};
  border-bottom: 1px solid ${_blocks.palette.neutral3};
  font-weight: ${_blocks.fontWeight.bold};

  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: max-content;
  grid-auto-rows: max-content;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
`;
exports.SiteLabel = SiteLabel;
const Detail = _styledComponents.default.section`
  padding: ${_blocks.spacing.loose};
`;
exports.Detail = Detail;
const StyledBodyLarge = (0, _styledComponents.default)(_blocks.BodyLarge)`
  margin-bottom: ${_blocks.spacing.default};
`;
exports.StyledBodyLarge = StyledBodyLarge;
const Chip = _styledComponents.default.span`
  ${_blocks.typography.mainText};

  display: inline-block;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  font-weight: bold;
  line-height: 13px;
  padding: ${_blocks.spacing.tight} ${_blocks.spacing.snug};
  margin-right: ${_blocks.spacing.extraTight};
  margin-bottom: ${_blocks.spacing.extraTight};

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
exports.Chip = Chip;