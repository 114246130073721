"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSession = useSession;

var _react = require("react");

var _SessionProvider = require("./SessionProvider");

var _types = require("./types");

var _utils = require("./utils");

var _locale = require("../locale");

/**
 * The bulk of this hook is borrowed from `next-auth` as we intend to replace
 * this implementation with the `useSession` from `next-auth` when we have a
 * single application.
 */
function useSession(options) {
  const {
    locale
  } = (0, _locale.useLocale)(); // @ts-expect-error Satisfy TS if branch on line below

  const value = (0, _react.useContext)(_SessionProvider.sessionContext);

  if (value === undefined) {
    throw Error('useSession must be used within a "<SessionProvider />"');
  }

  const {
    required,
    onUnauthenticated
  } = options !== null && options !== void 0 ? options : {};
  const requiredAndNotLoading = required === true && value.status === _types.SessionStatus.Unauthenticated;
  (0, _react.useEffect)(() => {
    if (requiredAndNotLoading) {
      if (onUnauthenticated !== undefined) onUnauthenticated();else (0, _utils.signIn)(undefined, locale);
    }
  }, [requiredAndNotLoading, onUnauthenticated]);

  if (requiredAndNotLoading) {
    return {
      data: value.data,
      status: _types.SessionStatus.Loading
    };
  }

  return value;
}