"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOGOUT = exports.SESSION_FAILURE = exports.SESSION_SUCCESS = exports.LOAD_PORTFOLIO_FAIL = exports.LOAD_PORTFOLIO_SUCCESS = exports.LOAD_PORTFOLIO = void 0;
// Portfolio
const LOAD_PORTFOLIO = 'bonfire/auth/LOAD_PORTFOLIO';
exports.LOAD_PORTFOLIO = LOAD_PORTFOLIO;
const LOAD_PORTFOLIO_SUCCESS = 'bonfire/auth/LOAD_PORTFOLIO_SUCCESS';
exports.LOAD_PORTFOLIO_SUCCESS = LOAD_PORTFOLIO_SUCCESS;
const LOAD_PORTFOLIO_FAIL = 'bonfire/auth/LOAD_PORTFOLIO_FAIL';
exports.LOAD_PORTFOLIO_FAIL = LOAD_PORTFOLIO_FAIL;
const SESSION_SUCCESS = 'bonfire/auth/SESSION_SUCCESS';
exports.SESSION_SUCCESS = SESSION_SUCCESS;
const SESSION_FAILURE = 'bonfire/auth/SESSION_FAILURE';
exports.SESSION_FAILURE = SESSION_FAILURE;
// Logout
const LOGOUT = 'bonfire/auth/LOGOUT';
exports.LOGOUT = LOGOUT;