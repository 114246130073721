"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = void 0;

const reducer = (state, action) => {
  const actionType = action.type;

  switch (action.type) {
    case 'LOAD_RULES':
      return { ...state,
        loadingRules: true
      };

    case 'LOAD_RULES_SUCCESS':
      {
        return { ...state,
          loadingRules: false,
          rules: action.rules,
          ruleList: action.ruleList.sort((a, b) => b.rule.name.toLowerCase() > a.rule.name.toLowerCase() ? -1 : 1)
        };
      }

    case 'LOAD_RULES_FAIL':
      throw new Error(`Request for rules failed: ${action.error}`);

    default:
      throw new Error(`Unhandled action type: ${actionType}`);
  }
};

exports.reducer = reducer;