"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCounts = void 0;

var React = _interopRequireWildcard(require("react"));

var _ = require("..");

const useCounts = (group, grouped, composedGrouped, showSnoozed) => {
  const [siteCount, setSiteCount] = React.useState(0);
  const [equipCount, setEquipCount] = React.useState(0);
  const [insightsCount, setInsightsCount] = React.useState(0);
  const [snoozedCount, setSnoozedCount] = React.useState(0);
  React.useEffect(() => {
    const {
      siteCount: sites,
      equipCount: equips
    } = (0, _.getMonitoringCounts)(group, grouped, showSnoozed);
    setSiteCount(sites);
    setEquipCount(equips);
  }, [group, grouped, showSnoozed]);
  React.useEffect(() => {
    setInsightsCount((0, _.getInsightsCount)(composedGrouped, showSnoozed));
    setSnoozedCount((0, _.getSnoozedCount)(composedGrouped));
  }, [composedGrouped, showSnoozed]);
  return {
    siteCount,
    equipCount,
    insightsCount,
    snoozedCount
  };
};

exports.useCounts = useCounts;