"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navigate = exports.getLinkTo = exports.getQueryString = void 0;

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _browserHistory = require("../../browserHistory");

const getQueryString = () => {
  const qs = _queryStringForAll.default.parse(_browserHistory.history.location.search);

  return _queryStringForAll.default.stringify({ ...qs
  });
};

exports.getQueryString = getQueryString;

const getLinkTo = pathname => {
  return {
    pathname,
    search: _browserHistory.history.location.search
  };
};

exports.getLinkTo = getLinkTo;

const navigate = (pathname, method = 'push') => {
  return () => {
    _browserHistory.history[method](getLinkTo(pathname));
  };
};

exports.navigate = navigate;