"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

function reducer(state, action) {
  const actionType = action.type;

  switch (action.type) {
    // * Data state
    case 'LOAD_OCCURRENCES':
      // 1
      return { ...state,
        isLoading: true
      };

    case 'LOAD_OCCURRENCES_SUCCESS':
      // 2
      return { ...state,
        isLoading: false,
        isLoaded: true,
        rawData: action.rawData
      };

    case 'SET_COMPOSED_GROUPED_OCCURRENCES':
      // 3
      return { ...state,
        composedOccurrences: action.composedOccurrences,
        composedFilteredOccurrences: action.composedOccurrences,
        composedOccurrencesBySite: action.composedOccurrencesBySite,
        isComposed: true
      };

    case 'SET_COMPOSED_FILTERED_OCCURRENCES':
      // 4
      return { ...state,
        composedFilteredOccurrences: action.composedFilteredOccurrences,
        search: action.search,
        isFiltered: true
      };

    case 'SET_COMPOSED_AND_GROUPED_INSIGHTS':
      return { ...state,
        composedAndGroupedInsights: action.composedAndGroupedInsights,
        isProcessed: true
      };

    case 'SET_PROCESSED_INSIGHTS':
      return { ...state,
        composedAndGroupedOccurrences: action.occurrences,
        composedAndGroupedInsights: action.insights,
        isProcessed: true
      };
    // * UI State

    case 'SET_SORT_DIRECTION':
      return { ...state,
        sortDirection: action.direction
      };

    case 'SET_GROUP_BY':
      return { ...state,
        groupBy: action.groupBy
      };

    case 'SET_SORT_BY':
      return { ...state,
        sortBy: action.sortBy
      };

    case 'TOGGLE_EXPAND_ALL':
      return { ...state,
        expandAll: !state.expandAll
      };

    case 'SET_CATEGORY':
      return { ...state,
        category: action.category
      };

    case 'SET_LAST_ACTIVE_ROW':
      return { ...state,
        lastActiveRow: action.id
      };

    default:
      throw new Error(`Unhandled action type: ${actionType}`);
  }
}