"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.composeIssueData = composeIssueData;

/**
 * Adds the valueString property, which is a composition
 * of all the properties that this data should be
 * searchable on.
 */
function composeIssueData(issues) {
  return issues.map(issue => ({ ...issue,
    valueString: `${issue.components} ${issue.bonfire_status} ${issue.context} ${issue.description} ${issue.priority} ${issue.responsible} ${issue.spark_type} ${issue.summary} ${issue.target} ${issue.key} ${issue.project_name} ${issue.transition_reason}`
  }));
}