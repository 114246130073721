"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "LocaleContext", {
  enumerable: true,
  get: function () {
    return _context.LocaleContext;
  }
});
Object.defineProperty(exports, "LocaleProvider", {
  enumerable: true,
  get: function () {
    return _context.LocaleProvider;
  }
});
Object.defineProperty(exports, "useLocale", {
  enumerable: true,
  get: function () {
    return _context.useLocale;
  }
});

var _context = require("./context");