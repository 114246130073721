"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultLocale = exports.locales = void 0;
const locales = {
  en: 'English',
  fr: 'Français'
};
exports.locales = locales;
const defaultLocale = 'en';
exports.defaultLocale = defaultLocale;