"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AttachmentImage = exports.NonImageAttachmentLink = exports.AttachmentLink = exports.AttachmentListItem = exports.AttachmentList = exports.Separator = exports.RemoveButton = exports.MetaText = exports.Meta = exports.TransitionContainer = exports.TextArea = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const Container = _styledComponents.default.div`
  background-color: ${_blocks.color.pureWhite};
  border-radius: ${_blocks.borderRadius.large};
  margin-bottom: ${_blocks.spacing.default};
  box-shadow: ${_blocks.elevation.content};
  overflow: hidden;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
exports.Container = Container;
const TextArea = _styledComponents.default.div`
  padding: ${_blocks.spacing.default};
`;
exports.TextArea = TextArea;
const TransitionContainer = _styledComponents.default.p`
  padding: ${_blocks.spacing.tight};
  background-color: ${_blocks.palette.yellow1};
  border: 1px solid ${_blocks.palette.yellow5};
  border-radius: ${_blocks.borderRadius.default};
  color: ${_blocks.palette.yellow9};
  ${_blocks.typography.body}
`;
exports.TransitionContainer = TransitionContainer;
const Meta = _styledComponents.default.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${_blocks.spacing.tight};
`;
exports.Meta = Meta;
const MetaText = _styledComponents.default.p`
  color: ${_blocks.palette.neutral7};
  ${_blocks.typography.bodySmall}
`;
exports.MetaText = MetaText;
const RemoveButton = _styledComponents.default.button`
  display: inline-block;
  border: 0;
  background: 0;
  color: ${_blocks.palette.neutral7};
  ${_blocks.typography.bodySmall}
  text-decoration: underline;
`;
exports.RemoveButton = RemoveButton;
const Separator = _styledComponents.default.hr`
  margin-top: ${_blocks.spacing.snug};
  margin-bottom: ${_blocks.spacing.snug};
  background-color: ${_blocks.color.theme.divider.solid};
`;
exports.Separator = Separator;
const AttachmentList = _styledComponents.default.ul`
  border-width: 1px 0px 0px;
  border-color: initial;
  border-top: 1px solid;
  border-image: linear-gradient(
      270deg,
      rgb(247, 249, 250) 0%,
      rgb(207, 211, 214) 50%,
      rgb(247, 249, 250) 100%
    )
    1 / 1 / 0 stretch;
  padding: ${_blocks.spacing.default};
  background-color: ${_blocks.palette.neutral1};
`;
exports.AttachmentList = AttachmentList;
const AttachmentListItem = _styledComponents.default.li`
  list-style-type: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 80px;
  height: 80px;
`;
exports.AttachmentListItem = AttachmentListItem;
const AttachmentLink = _styledComponents.default.a.attrs({
  target: '_blank',
  rel: 'noopener'
})`
  display: inline-block;
  width: 100%;
  height: 100%;
`;
exports.AttachmentLink = AttachmentLink;
const NonImageAttachmentLink = (0, _styledComponents.default)(AttachmentLink)`
  display: inline-flex;
  width: auto;
  color: ${_blocks.palette.blue7};
  ${_blocks.typography.body}
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  svg {
    margin-right: ${_blocks.spacing.extraTight};
  }
`;
exports.NonImageAttachmentLink = NonImageAttachmentLink;
const AttachmentImage = _styledComponents.default.div`
  background-image: ${props => `url('${props.imgLink}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  border: 1px solid ${_blocks.palette.neutral6};
  box-sizing: border-box;
  border-radius: ${_blocks.borderRadius.default};
`;
exports.AttachmentImage = AttachmentImage;