"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThreeColsLayout = exports.TwoColsLayout = exports.PaddingWrapper = exports.Divider = exports.SubHeading = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const SubHeading = (0, _styledComponents.default)(_blocks.BodyLarge)`
  margin-bottom: ${_blocks.spacing.default};
`;
exports.SubHeading = SubHeading;
const Divider = _styledComponents.default.div`
  height: ${_blocks.spacing.default};
`;
exports.Divider = Divider;
const PaddingWrapper = _styledComponents.default.div`
  padding: ${_blocks.spacing.loose};

  /* TODO: This overwrites the max-width of the Field elements from blocks, I think we should remove the max-width property from Blocks */
  > div {
    max-width: 100%;
  }
`;
exports.PaddingWrapper = PaddingWrapper;
const TwoColsLayout = _styledComponents.default.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
`;
exports.TwoColsLayout = TwoColsLayout;
const ThreeColsLayout = _styledComponents.default.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
`;
exports.ThreeColsLayout = ThreeColsLayout;