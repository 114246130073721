"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$ButtonItem = exports.$Item = exports.$MenuTitle = exports.$Menu = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

var _App = require("../AppChrome/App.constants");

// NOTE: Must be used in a relatively positioned container
function openClose({
  isOpen
}) {
  return isOpen ? 'block' : 'none';
}

const $Menu = _styledComponents.default.div`
  display: ${openClose};
  position: absolute;
  border-radius: ${_blocks.borderRadius.default};
  height: auto;
  min-width: 180px;
  z-index: ${_blocks.layer.contextNavigation};
  background: ${_blocks.color.pureWhite};
  box-shadow: ${_blocks.elevation.contentNavigation};
  overflow: hidden;
  transform: translateY(${_blocks.spacing.tight});
`;
exports.$Menu = $Menu;
const $Item = _styledComponents.default.div`
  a,
  button {
    display: block;
    padding: ${_blocks.spacing.snug};
    font-size: 14px;
    border: none;
    outline: 0;
    background: none;
    text-align: left;
    color: ${_blocks.color.theme.typography.ink};
    white-space: nowrap;
  }

  a:not([disabled]):hover,
  button:not([disabled]):hover {
    background: ${_blocks.palette.neutral2};
    text-decoration: none;
  }

  &:last-child {
    border-bottom: none;
  }
`;
exports.$Item = $Item;

function selected({
  isSelected
}) {
  return isSelected ? `${_App.TYPOGRAPHY.WEIGHTS.BOLD}` : `${_App.TYPOGRAPHY.WEIGHTS.REGULAR}`;
}

const $ButtonItem = _styledComponents.default.button.attrs({
  type: 'button'
})`
  display: block;
  margin: 0;
  width: 100%;
  padding: ${_blocks.spacing.tight} ${_blocks.spacing.snug};
  font-weight: ${selected};
  border: none;
  outline: 0;
  font-size: 12px;
  background: transparent;
  text-align: left;
  white-space: nowrap;

  &:not([disabled]):focus {
    outline: 0;
  }

  &:not([disabled]):hover {
    background: ${_blocks.palette.neutral2};
  }
`;
exports.$ButtonItem = $ButtonItem;
const $MenuTitle = _styledComponents.default.span`
  display: block;
  border-bottom: 1px solid ${_blocks.color.theme.divider.solid};
  padding: ${_blocks.spacing.snug};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: ${_blocks.color.theme.icon.default};
  text-align: left;
`;
exports.$MenuTitle = $MenuTitle;