"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _ = require("./");

var _types = require("./types");

function reducer(state, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      {
        return { ...state,
          equipRuleIds: action.equipRuleIds,
          modalStatus: _types.ModalStatus.open
        };
      }

    case 'HIDE_MODAL':
      {
        return { ...state,
          equipRuleIds: [],
          modalStatus: _types.ModalStatus.closed
        };
      }

    case 'LOAD_ACTIONS':
      {
        return { ...state,
          listStatus: _types.Status.loading
        };
      }

    case 'SET_ACTIONS':
      {
        return { ...state,
          actions: action.action,
          listStatus: _types.Status.ready
        };
      }

    case 'SET_ERROR':
      {
        return { ...state,
          listStatus: _types.Status.error,
          error: action.error
        };
      }

    case 'SELECT_ACTION':
      {
        return { ...state,
          selectedAction: action.selectedAction
        };
      }

    case 'UPDATE_ACTION':
      {
        const updateIndex = state.actions.findIndex(a => a.key === action.action.key);
        const updatedActionList = [...state.actions];
        updatedActionList[updateIndex] = action.action;
        return { ...state,
          actions: updatedActionList,
          selectedAction: action.action
        };
      }

    case 'RESET':
      {
        return { ..._.initialState
        };
      }
  }
}