"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentWrap = exports.PageHeader = exports.Wrap = exports.Container = exports.Page = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

/** Implements the background for the entire page */
const Page = _styledComponents.default.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 1200px;
  background: ${_blocks.color.theme.appBackground};
  /* ensure the app chrome always wraps the content */
  min-width: min-content;
`;
/**
 * The flex child to push the entire content area to the bottom
 */

exports.Page = Page;
const Container = _styledComponents.default.div`
  flex-grow: 1;
`;
/**
 * Wraps the page content to impose a maximum width, balanced margins, and
 * padding
 */

exports.Container = Container;
const Wrap = _styledComponents.default.div`
  position: relative;
  max-width: ${_blocks.dimension.maxWidth.mainContent};
  min-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: ${_blocks.spacing.extraLoose};
`;
/**
 * Wraps the area above PageContent. Typically contains text of some sort
 * describing the page.
 */

exports.Wrap = Wrap;
const PageHeader = _styledComponents.default.div`
  display: flex;
  margin-bottom: ${_blocks.spacing.default};
  align-items: center;
  justify-content: center;
`;
/**
 * Typically a child of PageContent, wraps the primary content on the page and
 * applies a Frame-esque style.
 */

exports.PageHeader = PageHeader;
const ContentWrap = _styledComponents.default.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${_blocks.spacing.extraLoose};
  border-radius: ${_blocks.borderRadius.large};
  box-shadow: ${_blocks.elevation.content};
  background: ${_blocks.color.pureWhite};
`;
exports.ContentWrap = ContentWrap;