"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _actionTypes = require("./actionTypes");

const INITIAL_STATE = {
  appSetupComplete: false,
  appSetupError: undefined
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case _actionTypes.APP_SETUP_COMPLETE:
      return { ...state,
        appSetupComplete: true,
        appSetupError: undefined
      };

    case _actionTypes.APP_SETUP_ERROR:
      return { ...state,
        appSetupComplete: false,
        appSetupError: action.error
      };

    case _actionTypes.APP_SETUP_RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
}