"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function IdGenerator() {
  let id = 0;
  return () => {
    id += 1;
    return `UniqueId-${id}`;
  };
}

const getUniqueId = new IdGenerator();
var _default = getUniqueId;
exports.default = _default;
module.exports = exports.default;