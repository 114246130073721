"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SessionStatus = void 0;
let SessionStatus;
/**
 * This type and logic is borrowed from `next-auth` as we intend to replace this
 * implementation with that when we have a single application.
 *
 * @returns a loading state or fully authenticated state if required: true.
 * Otherwise, return the previous or an additional unauthenticated state.
 */

exports.SessionStatus = SessionStatus;

(function (SessionStatus) {
  SessionStatus["Loading"] = "loading";
  SessionStatus["Authenticated"] = "authenticated";
  SessionStatus["Unauthenticated"] = "unauthenticated";
})(SessionStatus || (exports.SessionStatus = SessionStatus = {}));