"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createContextHook = void 0;

var _ = require(".");

/**
 * Create a hook and return a pair of state and dispatch
 */
const createContextHook = (stateContext, dispatchContext) => {
  return () => [(0, _.createStateHook)(stateContext)(), (0, _.createDispatchHook)(dispatchContext)()];
};

exports.createContextHook = createContextHook;