"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RestrictRanked = exports.CompareBy = exports.RankBy = exports.MetricsType = exports.MetricsUnit = void 0;
let MetricsUnit; // Keep values equivalent to API keys

exports.MetricsUnit = MetricsUnit;

(function (MetricsUnit) {
  MetricsUnit["Electricity"] = "kWh";
  MetricsUnit["Gas"] = "MJ";
  MetricsUnit["Water"] = "kL";
})(MetricsUnit || (exports.MetricsUnit = MetricsUnit = {}));

let MetricsType;
exports.MetricsType = MetricsType;

(function (MetricsType) {
  MetricsType["Electricity"] = "Electricity";
  MetricsType["Gas"] = "Gas";
  MetricsType["Water"] = "Water";
})(MetricsType || (exports.MetricsType = MetricsType = {}));

let RankBy;
exports.RankBy = RankBy;

(function (RankBy) {
  RankBy["Absolute"] = "Absolute";
  RankBy["Relative"] = "Relative";
})(RankBy || (exports.RankBy = RankBy = {}));

let CompareBy;
exports.CompareBy = CompareBy;

(function (CompareBy) {
  CompareBy["Direct"] = "direct";
  CompareBy["MAT"] = "mat";
})(CompareBy || (exports.CompareBy = CompareBy = {}));

let RestrictRanked;
exports.RestrictRanked = RestrictRanked;

(function (RestrictRanked) {
  RestrictRanked["All"] = "All";
  RestrictRanked["YTD"] = "Year To Date";
})(RestrictRanked || (exports.RestrictRanked = RestrictRanked = {}));