"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHistoryDateRange = getHistoryDateRange;

var _dateFns = require("date-fns");

/**
 * Returns a 7 day window.
 *
 * If the insight occurred in the last 5 days, the 7 day window
 * starts from 7 days before today and ends on today
 *
 * If the insight occurred more than 5 days ago, the 7 day window
 * starts from 2 days before the occurred date and ends 2 days after
 *
 * @param occurredOn string
 */
function getHistoryDateRange(occurredOn, today = new Date()) {
  if (occurredOn === undefined) {
    const start = (0, _dateFns.startOfDay)((0, _dateFns.sub)(today, {
      days: 6
    }));
    const end = (0, _dateFns.startOfDay)((0, _dateFns.add)(today, {
      days: 1
    }));
    return {
      start,
      end
    };
  }

  const occurredToday = (0, _dateFns.isToday)(new Date(occurredOn));
  const daysDiff = (0, _dateFns.differenceInDays)(today, new Date(occurredOn));

  if (occurredToday || daysDiff <= 5) {
    const start = (0, _dateFns.startOfDay)((0, _dateFns.sub)(today, {
      days: 6
    }));
    const end = (0, _dateFns.startOfDay)((0, _dateFns.add)(today, {
      days: 1
    }));
    return {
      start,
      end
    };
  }

  const occurredOnDate = new Date(occurredOn);
  const start = (0, _dateFns.startOfDay)((0, _dateFns.sub)(occurredOnDate, {
    days: 4
  }));
  const end = (0, _dateFns.startOfDay)((0, _dateFns.add)(occurredOnDate, {
    days: 3
  }));
  return {
    start,
    end
  };
}