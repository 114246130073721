"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Clear = exports.Building = exports.IconWrapper = exports.GlobalSite = exports.ActiveSite = exports.Triangle = exports.ColumnContainer = exports.MenuContainer = exports.Search = exports.SearchWrapper = exports.MenuWrapper = exports.LoadingContainer = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

var _App = require("../../components/AppChrome/App.constants");

var _Search = _interopRequireDefault(require("./components/Search"));

const LoadingContainer = _styledComponents.default.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: space-around;
  align-items: center;
  height: calc(100% - 45px);
`;
exports.LoadingContainer = LoadingContainer;
const MenuWrapper = _styledComponents.default.div`
  position: relative;
  flex: 1;
  min-width: 0;
`;
exports.MenuWrapper = MenuWrapper;
const SearchWrapper = _styledComponents.default.div`
  display: flex;
  padding: ${_blocks.spacing.snug} ${_blocks.spacing.default};
  border-bottom: 1px solid ${_blocks.color.theme.divider.solid};
  background: ${_blocks.palette.neutral2};
`;
exports.SearchWrapper = SearchWrapper;
const Search = (0, _styledComponents.default)(_Search.default)`
  flex-basis: calc(100% / 1.5);

  input {
    height: 32px;
  }
`;
exports.Search = Search;
const MenuContainer = _styledComponents.default.div`
  display: ${({
  isOpen
}) => isOpen ? 'block' : 'none'};
  position: absolute;
  left: 0;
  top: 0;
  width: 760px;
  min-height: 400px;
  border-radius: ${_blocks.borderRadius.default};
  border: 1px solid ${_blocks.color.theme.divider.solid};
  box-shadow: ${_blocks.elevation.contentNavigation};
  z-index: ${_blocks.layer.contextNavigation};
  background: ${_blocks.color.pureWhite};
`;
exports.MenuContainer = MenuContainer;
const ColumnContainer = _styledComponents.default.div`
  display: flex;
  height: 344px;
  border-bottom: 1px solid ${_blocks.color.theme.divider.solid};

  .Column {
    display: inline-flex;
    flex-direction: column;
    width: ${100 / 3}%;

    border-right: solid 1px ${_blocks.color.theme.divider.solid};
    overflow: hidden;

    &:last-child {
      border-right: none;
    }
  }

  .MenuSection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: ${_blocks.spacing.default} ${_blocks.spacing.default} ${_blocks.spacing.snug};
  }

  h2 {
    margin: 0;
    font-size: 12px;
    font-weight: ${_App.TYPOGRAPHY.WEIGHTS.BOLD};
    text-transform: uppercase;
    color: ${_blocks.palette.neutral8};
  }

  .MenuSection-list {
    height: 100%;
    width: 100%;
    padding: 0;
    overflow-y: scroll;
  }

  .MenuSection-inner {
    height: 100%;
  }
`;
exports.ColumnContainer = ColumnContainer;
const Triangle = (0, _styledComponents.default)(_blocks.ArrowDropdownDown)`
  display: inline-block;
  position: relative;
  margin-left: ${_blocks.spacing.tight};
  fill: ${_blocks.color.theme.white};
  width: 10px;
  min-width: 10px;
`;
exports.Triangle = Triangle;
const ActiveSite = _styledComponents.default.div`
  margin-left: ${_blocks.spacing.tight};
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
exports.ActiveSite = ActiveSite;
const GlobalSite = _styledComponents.default.button`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding: 0;
  border: none;
  outline: 0;
  background: transparent;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: hsl(206, 30%, 98.8%);
  /* needed for the text overflow of long site names */
  max-width: 100%;

  &:hover ${ActiveSite} {
    text-decoration: underline;
  }
`;
exports.GlobalSite = GlobalSite;
const IconWrapper = _styledComponents.default.div`
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  margin-right: ${_blocks.spacing.tight};
  height: ${_App.AVATARS.BASE};
  width: ${_App.AVATARS.BASE};
  border-radius: 50%;
  background: rgba(${_blocks.color.pureWhite}, 0.1);
`;
exports.IconWrapper = IconWrapper;
const Building = (0, _styledComponents.default)(_blocks.Site)`
  display: inline-block;
  fill: ${_blocks.color.theme.white};
  background: transparent;
`;
exports.Building = Building;
const Clear = (0, _styledComponents.default)(_blocks.Button)`
  text-transform: uppercase;
  font-size: 12px;
  color: ${_blocks.palette.neutral8};
`;
exports.Clear = Clear;