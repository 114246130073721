"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$SelectedSite = exports.$Site = exports.$Project = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

function isSelected({
  active
}) {
  const weight = active ? _blocks.fontWeight.bold : _blocks.fontWeight.normal;
  return `font-weight: ${weight}`;
}

function isNoMatch({
  noMatch
}) {
  return noMatch ? `
    font-weight: normal;
    color: #dadada;
    opacity: 0.4;
  ` : '';
}

const $Project = _styledComponents.default.button.attrs({
  type: 'button'
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: 0;
  width: 100%;
  /* TODO: hacky  */
  padding: ${_blocks.spacing.tight} ${_blocks.spacing.default} !important;
  font-size: 14px;
  line-height: 1;
  text-align: left;
  color: ${_blocks.color.theme.black};
  ${isSelected};
  ${isNoMatch};
  cursor: pointer;
  background: transparent;

  &:hover {
    background: ${_blocks.color.theme.supportingColor.tint};
  }

  &:active,
  &:focus {
    outline: 0;
  }
`;
exports.$Project = $Project;
const $Site = _styledComponents.default.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 ${_blocks.spacing.default};
  color: ${_blocks.color.theme.black};
  font-size: 14px;
  line-height: 32px;
  outline: 0;
  border: none;
  background: none;
  ${isNoMatch};

  .Site-label {
    padding-right: ${_blocks.spacing.default};
    flex-grow: 1;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:hover {
    background: ${_blocks.color.theme.supportingColor.tint};
  }
`;
exports.$Site = $Site;
const $SelectedSite = (0, _styledComponents.default)($Site)`
  color: ${_blocks.palette.blue7};
  font-size: 13px;
  font-weight: bold;

  svg {
    display: none;
    fill: ${_blocks.palette.blue7};
  }

  &:hover svg {
    display: inline-block;
  }
`;
exports.$SelectedSite = $SelectedSite;