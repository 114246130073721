"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _utils = require("../utils");

var _ = require("./");

function useActions() {
  const dispatch = (0, _.useDispatch)();
  const showModal = React.useCallback(function showModal(equipRuleIds) {
    dispatch({
      type: 'SHOW_MODAL',
      equipRuleIds
    });
  }, []);
  const hideModal = React.useCallback(function hideModal() {
    dispatch({
      type: 'HIDE_MODAL'
    });
    dispatch({
      type: 'RESET'
    });
  }, []);
  const loadActions = React.useCallback(async function loadActions(siteId, projectJiraKey) {
    dispatch({
      type: 'LOAD_ACTIONS',
      siteId,
      projectJiraKey
    });

    try {
      const response = await _axios.default.post('/issues', {
        site: [siteId],
        project: [projectJiraKey],
        status: 'open'
      });
      const filtered = response.data.length > 0 ? response.data.filter(i => i.bonfire_status !== 'Rejected') : [];
      const action = (0, _utils.composeIssueData)(filtered);
      dispatch({
        type: 'SET_ACTIONS',
        action
      });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        error: 'Something went wrong'
      });
    }
  }, []);
  const selectAction = React.useCallback(function selectAction(selectedAction) {
    dispatch({
      type: 'SELECT_ACTION',
      selectedAction
    });
  }, []);
  const updateAction = React.useCallback(function updateAction(action) {
    dispatch({
      type: 'UPDATE_ACTION',
      action
    });
  }, []);
  const clearSelectedAction = React.useCallback(function clearSelectedAction() {
    dispatch({
      type: 'RESET'
    });
  }, []);
  const setActions = React.useCallback(function setActions(action) {
    dispatch({
      type: 'SET_ACTIONS',
      action
    });
  }, []);
  return {
    showModal,
    hideModal,
    setActions,
    loadActions,
    selectAction,
    updateAction,
    clearSelectedAction
  };
}