"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearPersistedUI = clearPersistedUI;
exports.setStorage = setStorage;
exports.getStorage = getStorage;
exports.clearStorage = clearStorage;
exports.getPersisted = getPersisted;
exports.setPersisted = setPersisted;
const BONFIRE_STORAGE_KEY = 'BonfireLS';

function clearPersistedUI(prop) {
  const persisted = window.sessionStorage.getItem(BONFIRE_STORAGE_KEY);
  const state = persisted !== null ? JSON.parse(persisted) : {};
  const newState = { ...state,
    [prop]: undefined
  };
  window.sessionStorage.setItem(BONFIRE_STORAGE_KEY, JSON.stringify(newState));
}

function setStorage({
  key,
  prop,
  data,
  useLocalStorage
}) {
  const storage = useLocalStorage ? window.localStorage : window.sessionStorage;
  const persisted = storage.getItem(BONFIRE_STORAGE_KEY);
  const state = persisted !== null ? JSON.parse(persisted) : {
    [key]: {}
  };
  const newState = { ...state,
    [key]: { ...state[key],
      [prop]: data
    }
  };
  storage.setItem(BONFIRE_STORAGE_KEY, JSON.stringify(newState));
}

function getStorage({
  key,
  prop,
  useLocalStorage
}) {
  const storage = useLocalStorage ? window.localStorage : window.sessionStorage;
  const persisted = storage.getItem(BONFIRE_STORAGE_KEY);
  const state = persisted !== null ? JSON.parse(persisted) : {
    [key]: {}
  };
  return state[key] ? state[key][prop] : undefined;
}

function clearStorage({
  key,
  useLocalStorage
}) {
  const storage = useLocalStorage ? window.localStorage : window.sessionStorage;
  const persisted = storage.getItem(BONFIRE_STORAGE_KEY);
  const state = persisted !== null ? JSON.parse(persisted) : {};
  const newState = { ...state,
    [key]: undefined
  };
  storage.setItem(BONFIRE_STORAGE_KEY, JSON.stringify(newState));
}

function getPersisted(config, initial) {
  const d = getStorage(config);

  if (d !== undefined) {
    return d;
  }

  return initial;
}

function setPersisted(config, data) {
  setStorage({ ...config,
    data
  });
}