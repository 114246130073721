"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TIME_RANGE_OPTIONS = exports.SORT_BY_OPTIONS = exports.GROUP_BY_OPTIONS = void 0;

var _types = require("./types");

/**
 * This file contains all the dropdown options, values, and labels for the
 * ToolBar. They should all contain the same key as their sort function and
 * group function.
 */
// Group By
const groupBySite = {
  value: _types.GroupBy.Site,
  label: _i18n => 'Site'
};
const groupByPriority = {
  value: _types.GroupBy.Priority,
  label: _i18n => 'Priority'
};
const groupByRule = {
  value: _types.GroupBy.Rule,
  label: _i18n => 'Rule'
}; // Sort By

const sortByHits = {
  value: _types.SortBy.Hits,
  label: _i18n => 'Number of Hits',
  sub: _i18n => 'Hits'
};
const sortByEquips = {
  value: _types.SortBy.Equips,
  label: _i18n => 'Number of Equips',
  sub: _i18n => 'Equips'
};
const sortByInsightsAsc = {
  value: _types.SortBy.InsightsAsc,
  label: _i18n => 'Insight (Low-High)',
  sub: _i18n => 'Insight (Low-High)'
};
const sortByInsightsDesc = {
  value: _types.SortBy.InsightsDesc,
  label: _i18n => 'Insight (High-Low)',
  sub: _i18n => 'Insight (High-Low)'
};
const sortByAlphaAsc = {
  value: _types.SortBy.AlphaAsc,
  label: _i18n => 'Alphabetical (A-Z)',
  sub: _i18n => 'A-Z'
};
const sortByAlphaDesc = {
  value: _types.SortBy.AlphaDesc,
  label: _i18n => 'Alphabetical (Z-A)',
  sub: _i18n => 'Z-A'
}; // Time Range
// TODO: i18n

const rangeOneDay = {
  value: _types.TimeRange.Last24Hours,
  label: _i18n => 'Last 24 hours'
};
const rangeThreeDays = {
  value: _types.TimeRange.Last3Days,
  label: _i18n => 'Last 3 days'
};
const rangeSevenDays = {
  value: _types.TimeRange.Last7Days,
  label: _i18n => 'Last 7 days'
};
const rangeOneMonth = {
  value: _types.TimeRange.Last30Days,
  label: _i18n => 'Last 30 days'
};
const GROUP_BY_OPTIONS = {
  monitoring: [groupByRule, groupByPriority, groupBySite],
  insights: [groupByRule, groupByPriority, groupBySite]
};
exports.GROUP_BY_OPTIONS = GROUP_BY_OPTIONS;
const SORT_BY_OPTIONS = {
  monitoring: [sortByHits, sortByEquips, sortByInsightsAsc, sortByInsightsDesc, sortByAlphaAsc, sortByAlphaDesc],
  insights: [sortByHits, sortByEquips, sortByInsightsAsc, sortByInsightsDesc, sortByAlphaAsc, sortByAlphaDesc]
};
exports.SORT_BY_OPTIONS = SORT_BY_OPTIONS;
const TIME_RANGE_OPTIONS = {
  monitoring: [rangeOneDay, rangeThreeDays, rangeSevenDays, rangeOneMonth],
  insights: [rangeOneDay, rangeThreeDays, rangeSevenDays, rangeOneMonth]
};
exports.TIME_RANGE_OPTIONS = TIME_RANGE_OPTIONS;