"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SitesBoxContent = exports.BoxContent = exports.BoxHeading = exports.BoxHeader = exports.Foot = exports.SettingsBoxWrapper = exports.RulesBoxWrapper = exports.SitesBoxWrapper = exports.StyledActionList = exports.BoxWrapper = exports.ContentWrapper = exports.Menu = exports.Frame = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const Frame = (0, _styledComponents.default)(_blocks.Frame)`
  display: flex;
  overflow: hidden;
  min-height: calc(100vh - 245px);
`;
exports.Frame = Frame;
const Menu = _styledComponents.default.div`
  flex: 0 20%;
  background-color: ${_blocks.palette.neutral2};
  padding: ${_blocks.spacing.default} ${_blocks.spacing.loose};
  border-right: 2px solid ${_blocks.palette.neutral4};
`;
exports.Menu = Menu;
const ContentWrapper = _styledComponents.default.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  min-height: 0;
`;
exports.ContentWrapper = ContentWrapper;
const BoxWrapper = _styledComponents.default.div`
  flex-basis: 50%;
  min-height: 0;
  height: calc(100vh - 245px - 72px);
  overflow: hidden;
`;
exports.BoxWrapper = BoxWrapper;
const StyledActionList = (0, _styledComponents.default)(_blocks.ActionList)`
  position: absolute;
  top: 104px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
`;
exports.StyledActionList = StyledActionList;
const SitesBoxWrapper = (0, _styledComponents.default)(BoxWrapper)`
  position: relative;
  border-right: 2px solid ${_blocks.palette.neutral4};
`;
exports.SitesBoxWrapper = SitesBoxWrapper;
const RulesBoxWrapper = (0, _styledComponents.default)(BoxWrapper)`
  position: relative;
  border-right: 2px solid ${_blocks.palette.neutral4};
`;
exports.RulesBoxWrapper = RulesBoxWrapper;
const SettingsBoxWrapper = (0, _styledComponents.default)(BoxWrapper)``;
exports.SettingsBoxWrapper = SettingsBoxWrapper;
const Foot = _styledComponents.default.div`
  flex: 0 100%;
  background-color: ${_blocks.palette.neutral2};
  border-top: 1px solid ${_blocks.palette.neutral4};
  text-align: right;
  padding: 16px;
  height: 72px;
`;
exports.Foot = Foot;
const BoxHeader = _styledComponents.default.header`
  padding: 0 ${_blocks.spacing.snug};
`;
exports.BoxHeader = BoxHeader;
const BoxHeading = _styledComponents.default.div`
  margin-top: ${_blocks.spacing.snug};
  color: ${_blocks.palette.neutral7};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 36px;
  text-transform: uppercase;
`;
exports.BoxHeading = BoxHeading;
const BoxContent = _styledComponents.default.div`
  position: absolute;
  top: 106px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
`;
exports.BoxContent = BoxContent;
const SitesBoxContent = (0, _styledComponents.default)(BoxContent)`
  padding: 0 ${_blocks.spacing.tight};
`;
exports.SitesBoxContent = SitesBoxContent;