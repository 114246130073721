"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRuleSettings = useRuleSettings;

var React = _interopRequireWildcard(require("react"));

var _config = require("../../../context/settings/config");

var _calcActiveConfig = require("../utils/calcActiveConfig");

var _useActions = require("../../../context/settings/config/useActions");

function useRuleSettings() {
  const configState = (0, _config.useConfigState)();
  const {
    updateConfigRules,
    updateRuleConfig,
    bulkUpdateRuleConfig
  } = (0, _useActions.useActions)();
  const [propagated, setPropagated] = React.useState({});
  const handleCheck = React.useCallback(function handleCheck(rule) {
    return () => {
      void updateConfigRules(rule);
    };
  }, []);
  const updatePriority = React.useCallback(function updatePriority(updated) {
    void updateRuleConfig(updated);
  }, []);
  const resetRules = React.useCallback(function resetRules() {
    setPropagated({ ...configState.saved
    });
  }, [configState.shouldReset]);
  const updateActive = React.useCallback(function updateActive() {
    setPropagated(configState.active);
  }, [configState.active]);
  const clearAll = React.useCallback(function clearAll(visible) {
    return () => {
      const active = (0, _calcActiveConfig.calcRemoveAllConfig)(visible, configState);
      setPropagated(active);
      bulkUpdateRuleConfig(active);
    };
  }, [configState.active]);
  const selectAll = React.useCallback(function selectAll(visible) {
    return () => {
      const active = (0, _calcActiveConfig.calcSelectAllConfig)(visible, configState);
      setPropagated(active);
      bulkUpdateRuleConfig(active);
    };
  }, [configState.active]);
  React.useEffect(() => {
    setPropagated({ ...configState.saved
    });
  }, [configState.saved]);
  React.useEffect(() => {
    if (configState.shouldReset) {
      setPropagated({ ...configState.saved
      });
    }
  }, [configState.shouldReset]);
  return React.useMemo(() => ({
    checked: configState.active,
    propagated,
    clearAll,
    selectAll,
    updateActive,
    handleCheck,
    resetRules,
    updatePriority,
    insightType: configState.insightType
  }), [configState.active, propagated, configState.insightType]);
}