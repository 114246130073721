"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AVATARS = exports.ICONS = exports.DIMENSIONS = exports.TYPOGRAPHY = exports.CONTROLS = exports.COLORS = exports.COLORS_RAW = exports.OPACITY = exports.DEPTHS = exports.LAYERS = exports.BORDER_RADIUS = exports.SPACING = exports.BASE_UNIT = void 0;
const BASE_UNIT = 8; // 8px

exports.BASE_UNIT = BASE_UNIT;
const SPACING = {
  EXTRA_TIGHT: `${BASE_UNIT * 0.5}px`,
  // 4px
  TIGHT: `${BASE_UNIT * 1}px`,
  // 8px
  SNUG: `${BASE_UNIT * 1.5}px`,
  // 12px
  BASE: `${BASE_UNIT * 2}px`,
  // Base 16px
  LOOSE: `${BASE_UNIT * 3}px`,
  // 24px
  EXTRA_LOOSE: `${BASE_UNIT * 4}px`,
  // 32px
  ULTRA_LOOSE: `${BASE_UNIT * 6}px` // 48px

};
exports.SPACING = SPACING;
const BORDER_RADIUS = {
  TIGHT: '2px',
  BASE: '3px',
  LARGE: '6px'
};
exports.BORDER_RADIUS = BORDER_RADIUS;
const LAYERS = {
  NEGATIVE: -1,
  FIRST: 1,
  CONTENT: 100,
  OVERLAY: 200,
  NAVIGATION: 300
};
exports.LAYERS = LAYERS;
const DEPTHS = {
  NAVIGATION: '0 2px 4px 0 rgba(0,0,0,0.15), 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
  CONTENT: '0 2px 6px 0 rgba(0,0,0,0.12), 0 0 4px 0 rgba(55,64,71,0.14)',
  OVERLAY: '0 2px 6px 0 rgba(0,0,0,0.12), 0 0 6px 0 rgba(55,64,71,0.18)'
};
exports.DEPTHS = DEPTHS;
const OPACITY = {
  SUBDUED: 0.5,
  SUBTLE: 0.34,
  OVERLAID: 0.9
};
exports.OPACITY = OPACITY;
const COLORS_RAW = {
  RGB: {
    INK: '55,64,71',
    PURE_WHITE: '255,255,255'
  },
  PURE_WHITE: '#fff',
  PURE_BLACK: '#000',
  WHITE: '#FAFBFC',
  BLACK: '#374047',
  INK: '#374047',
  GREY_001: '#F7F9FA',
  // Lightest
  GREY_002: '#F2F4F5',
  // Lighter
  GREY_003: '#E8EBED',
  // Light
  GREY_004: '#CFD3D6',
  // Base
  GREY_005: '#ADB5BA',
  // Dark
  GREY_006: '#7F8B94',
  // Darker
  GREY_007: '#5F6D78',
  // Darkest
  BLUE_001: '#2083DA',
  GREEN_001: '#50BD56',
  YELLOW_001: '#F6E710',
  RED_001: '#EF0C0C',
  ORANGE_001: '#FF6600',
  INDIGO_001: '#3151B7'
};
exports.COLORS_RAW = COLORS_RAW;
const COLORS = {
  WHITE: COLORS_RAW.WHITE,
  BLACK: COLORS_RAW.BLACK,
  INK: COLORS_RAW.INK,
  INFORMATIVE: COLORS_RAW.BLUE_001,
  POSITIVE: COLORS_RAW.GREEN_001,
  WARNING: COLORS_RAW.YELLOW_001,
  NEGATIVE: COLORS_RAW.RED_001,
  BRAND_PRIMARY: COLORS_RAW.ORANGE_001,
  BRAND_SECONDARY: COLORS_RAW.INDIGO_001,
  ACCENT_PRIMARY: COLORS_RAW.BLUE_001,
  ACCENT_SECONDARY: COLORS_RAW.RED_001,
  TYPOGRAPHY_WHITE: COLORS_RAW.WHITE,
  TYPOGRAPHY_DEFAULT: COLORS_RAW.INK,
  TYPOGRAPHY_SUBDUED: COLORS_RAW.GREY_007,
  TYPOGRAPHY_SUBTLE: COLORS_RAW.GREY_006,
  TYPOGRAPHY_HINT: COLORS_RAW.GREY_005,
  LINK: COLORS_RAW.BLUE_001,
  DIVIDER: COLORS_RAW.GREY_003,
  POPOVER_HIGHLIGHT: COLORS_RAW.GREY_001,
  ROW_HIGHLIGHT: COLORS_RAW.GREY_001,
  // #F5FAFF;
  ROW_ACTIVE: COLORS_RAW.GREY_001,
  ICON_BUTTON: COLORS_RAW.GREY_006,
  ICON_BUTTON_HIGHLIGHT: COLORS_RAW.GREY_003,
  ICON_BUTTON_SUBTLE: COLORS_RAW.GREY_004,
  APP_BACKGROUND: COLORS_RAW.GREY_001,
  OVERLAY: {
    LIGHT: COLORS_RAW.WHITE,
    DARK: COLORS_RAW.BLACK
  },
  INPUT: {
    BORDER: COLORS_RAW.GREY_004
  }
};
exports.COLORS = COLORS;
const CONTROLS = {
  HEIGHTS: {
    THIN: `${BASE_UNIT * 4}px`,
    // 32px
    BASE: `${BASE_UNIT * 6}px` // 48px

  },
  WIDTHS: {
    MAX_WIDTH: '380px'
  }
};
exports.CONTROLS = CONTROLS;
const ICONS = {
  SMALL: '16px',
  BASE: '20px',
  LARGE: '40px'
};
exports.ICONS = ICONS;
const AVATARS = {
  SMALL: `${BASE_UNIT * 4}px`,
  BASE: `${BASE_UNIT * 6}px`,
  LARGE: `${BASE_UNIT * 8}px`
};
exports.AVATARS = AVATARS;
const DIMENSIONS = {
  MASTHEAD: {
    HEIGHT: '72px'
  },
  PAGE: {
    MAX_HEIGHT: 'calc(100vh - 72px)',
    MAX_WIDTH: '1220px'
  },
  MAIN_CONTENT: {
    MAX_WIDTH: '1220px',
    MIN_WIDTH: '1024px',
    MIN_HEIGHT: '500px',
    MASTHEAD_HEIGHT: `${BASE_UNIT * 10}px`
  },
  MODAL: {
    OFFSET_TOP: '80px',
    MAX_WIDTH: '70%',
    MIN_WIDTH: '700px',
    MIN_HEIGHT: '500px'
  },
  ROWS: {
    HEADER: {
      HEIGHT: `${BASE_UNIT * 6}px`
    }
  }
};
exports.DIMENSIONS = DIMENSIONS;
const TYPOGRAPHY = {
  WEIGHTS: {
    REGULAR: 'normal',
    BOLD: 'bold'
  }
};
exports.TYPOGRAPHY = TYPOGRAPHY;