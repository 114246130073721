"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _ = require("./");

var _storage = require("../../utils/storage");

var _constants = require("../../components/Analysis/utils/constants");

function useActions() {
  const dispatch = (0, _.useDispatch)();
  const toggleExpandAll = React.useCallback(function setExpandAll() {
    dispatch({
      type: 'TOGGLE_EXPAND_ALL'
    });
  }, []);
  const setSortBy = React.useCallback(function setSortBy(sortBy) {
    dispatch({
      type: 'SET_SORT_BY',
      sortBy
    });
    (0, _storage.setPersisted)({
      key: _constants.INSIGHTS_LS_KEY,
      prop: _constants.SORT_BY_KEY
    }, sortBy);
  }, []);
  const setGroupBy = React.useCallback(function setGroupBy(groupBy) {
    dispatch({
      type: 'SET_GROUP_BY',
      groupBy
    });
    (0, _storage.setPersisted)({
      key: _constants.INSIGHTS_LS_KEY,
      prop: _constants.GROUP_BY_KEY
    }, groupBy);
  }, []);
  const setCategory = React.useCallback(function setCategory(history, category) {
    const qs = _queryStringForAll.default.parse(history.location.search);

    const stringified = _queryStringForAll.default.stringify({ ...qs,
      category
    });

    history.replace({
      search: stringified
    });
    dispatch({
      type: 'SET_CATEGORY',
      category
    });
  }, []);
  const setLastActiveRow = React.useCallback(function setLastActiveRow(id) {
    dispatch({
      type: 'SET_LAST_ACTIVE_ROW',
      id
    });
  }, []);
  return {
    toggleExpandAll,
    setGroupBy,
    setSortBy,
    setCategory,
    setLastActiveRow
  };
}