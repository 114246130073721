"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMessage = useMessage;
exports.useEditDisabledStatuses = useEditDisabledStatuses;

var React = _interopRequireWildcard(require("react"));

var _attachments = require("../../../context/attachments");

var _context = require("./context");

var _axios = _interopRequireDefault(require("axios"));

var _dateFns = require("date-fns");

var _store = require("../../../redux/store");

var _eventTracking = require("../../../utils/eventTracking");

/* eslint-disable no-eq-null */
function useMessage(actionId) {
  const [projectKey, actionNumber] = actionId.split('-');
  const user = (0, _store.useAppSelector)(state => state.auth.user);
  const organisation = React.useMemo(() => {
    var _user$portfolio$proje, _user$portfolio;

    return Object.values((_user$portfolio$proje = (_user$portfolio = user.portfolio) === null || _user$portfolio === void 0 ? void 0 : _user$portfolio.projects) !== null && _user$portfolio$proje !== void 0 ? _user$portfolio$proje : {}).find(project => project.jira_key === projectKey);
  }, [projectKey, user.portfolio]);
  const [message, setMessage] = React.useState({
    value: '',
    plainText: '',
    mentions: []
  });
  const {
    addAttachment,
    uploadAttachment,
    resetAttachment
  } = (0, _attachments.useActions)();
  const {
    attachment,
    error: attachmentError
  } = (0, _attachments.useState)();
  const {
    addMessageStatus,
    userDisplayName,
    mentionsList,
    action,
    error: viewActionError
  } = (0, _context.useState)();
  const {
    loadMessages,
    setAction,
    setAddMessageStatus
  } = (0, _context.useActions)();
  /**
   * change event listener for file input
   */

  const handleAttachment = React.useCallback(e => {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      void addAttachment(file);
    }
  }, []);
  /**
   * remove attachment from message
   */

  const handleRemoveAttachment = React.useCallback(() => {
    resetAttachment();
  }, []);
  /**
   * change event listener for MentionsInput
   */

  const handleChange = React.useCallback((_e, newVal, newPlainText, mentions) => {
    setMessage({
      value: newVal,
      plainText: newPlainText,
      mentions
    });
  }, []);
  /**
   * request flow for
   * 1. uploading attachments (if present)
   * 2. posting a message
   * 3. sending notification emails for tagged users
   * 4. refreshing the message list
   */

  const handleAddMessage = async () => {
    if (message.plainText !== '' && userDisplayName !== undefined) {
      try {
        setAddMessageStatus(_context.Status.loading);
        (0, _eventTracking.addedCommentToActionEvent)(action, message.value); // Post message

        const messageResponse = await _axios.default.post(`/v2/actions/${actionId}/comments`, {
          body: `${message.plainText} ?? ${userDisplayName} ??`
        }); // Upload attachment to hydra with a comment

        if (attachment !== undefined && messageResponse.status === 201 && messageResponse.data.hydraId != null && organisation !== undefined && user.forgeId !== undefined) {
          await uploadAttachment({
            organisationId: organisation.forge_id,
            actionId: Number(actionNumber),
            userId: user.forgeId,
            attachment,
            commentId: messageResponse.data.hydraId
          });
        } // Process notifications for any users mentioned in message


        if (message.mentions.length > 0 && messageResponse.status === 201 && action !== undefined) {
          const notificationResponse = await _axios.default.post('/hashtag-notification', {
            comment: {
              key: actionId,
              text: message.plainText
            },
            hashtagList: message.mentions,
            issue: action,
            workflow: false
          });

          if (notificationResponse.status !== 200) {
            throw new Error('failed to send notification emails to users tagged in message');
          }
        } // Reset attachment, message state, and refresh message list


        if (messageResponse.status === 201) {
          // Clear attachments state
          resetAttachment(); // Reset message state

          setMessage({
            value: '',
            plainText: '',
            mentions: []
          }); // update action updated time

          if (action !== undefined) {
            setAction({ ...action,
              updated: (0, _dateFns.format)(new Date(), `yyyy-MM-dd'T'HH:mm:ssXXX`)
            });
          } // Load updated comments


          await loadMessages(actionId);
          setAddMessageStatus(_context.Status.idle);
        }
      } catch (err) {
        setAddMessageStatus(_context.Status.error);
        console.error(err);
      }
    }
  };

  return {
    attachment,
    messageStatus: addMessageStatus,
    message,
    mentionsList,
    handleAttachment,
    handleRemoveAttachment,
    handleAddMessage,
    handleChange,
    error: attachmentError || viewActionError
  };
}

function useEditDisabledStatuses() {
  const permissions = (0, _store.useAppSelector)(state => state.auth.user.access);
  return React.useMemo(() => {
    if (!(permissions !== null && permissions !== void 0 && permissions.includes('admin_statuses'))) {
      return ['Triage'];
    }

    return [];
  }, [permissions]);
}