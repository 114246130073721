"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTypeColors = getTypeColors;

var _types = require("../../../../context/reports/utilities/types");

/**
 * Retrieve all colours needed for a metrics type
 * @param type
 */
function getTypeColors(type) {
  switch (type) {
    case _types.MetricsType.Gas:
      return {
        range: ['hsla(262,64%,41%,1)', 'hsla(262,75%,87%,1)'],
        single: 'hsla(262,64%,41%,1)',
        crosshatch: '#ffffff'
      };

    case _types.MetricsType.Water:
      return {
        range: ['hsla(210,98%,36%,1)', 'hsla(210,94%,83%,1)'],
        single: 'hsla(210,98%,36%,1)',
        crosshatch: '#ffffff'
      };

    case _types.MetricsType.Electricity:
    default:
      return {
        range: ['hsla(16,95%,50%,1)', 'hsla(16,95%,86%,1)'],
        single: 'hsla(16,95%,50%,1)',
        crosshatch: '#ffffff'
      };
  }
}