"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = clientMiddleware;

var _session = require("../../context/session");

// typically client will be an instance of ApiClient
function clientMiddleware(client) {
  return ({
    dispatch,
    getState
  }) => next => action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const {
      promise,
      types,
      ...rest
    } = action; // eslint-disable-line no-redeclare

    if (!promise) {
      return next(action);
    } // if the action that was dispatched has a 'promise' key
    // dispatch a REQUEST action (as extracted from the 'types' key)


    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest,
      type: REQUEST
    }); // call the 'promise' function (from the action)
    // and dispatch a SUCCESS or FAILURE action (depending on the result)

    const actionPromise = promise(client);
    actionPromise.then(result => next({ ...rest,
      result,
      type: SUCCESS
    }), error => {
      // if authentication fails, logout and navigate to the login screen
      if (error && error.status && error.status === 401) {
        // Fire off the logout action that triggers a global state reset.
        (0, _session.signOut)();
      }

      next({ ...rest,
        error,
        type: FAILURE
      });
    }).catch(error => {
      console.error('MIDDLEWARE ERROR:', error);
      next({ ...rest,
        error,
        type: FAILURE
      });
    });
    return actionPromise;
  };
}

module.exports = exports.default;