"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _types = require("./types");

function reducer(state, action) {
  switch (action.type) {
    case 'SET_ADD_MESSAGE_STATUS':
      return { ...state,
        addMessageStatus: action.status
      };

    case 'SHOW_ACTION_MODAL':
      return { ...state,
        isOpen: true,
        actionId: action.actionId,
        userDisplayName: action.userDisplayName
      };

    case 'HIDE_ACTION_MODAL':
      return { ...state,
        isOpen: false,
        action: undefined,
        messages: undefined,
        actionStatus: _types.Status.idle,
        messageStatus: _types.Status.idle
      };

    case 'LOAD_ACTION':
      return { ...state,
        actionStatus: _types.Status.loading
      };

    case 'SET_ACTION':
      return { ...state,
        actionStatus: _types.Status.ready,
        action: action.action
      };

    case 'UPDATE_ACTION':
      {
        return { ...state,
          actionStatus: _types.Status.ready,
          action: { ...state.action,
            ...action.updatedFields
          }
        };
      }

    case 'CLEAR_ACTION':
      return { ...state,
        actionStatus: _types.Status.idle,
        action: undefined,
        actionId: undefined,
        error: undefined,
        messages: undefined
      };

    case 'SET_USER_DISPLAY_NAME':
      return { ...state,
        userDisplayName: action.userDisplayName
      };

    case 'LOAD_MESSAGES':
      return { ...state,
        actionId: action.actionId,
        messageStatus: _types.Status.loading
      };

    case 'SET_MESSAGES':
      return { ...state,
        messageStatus: _types.Status.ready,
        messages: action.messages
      };

    case 'LOAD_MENTIONS_LIST':
      return { ...state,
        mentionsStatus: _types.Status.loading
      };

    case 'SET_MENTIONS_LIST':
      return { ...state,
        mentionsStatus: _types.Status.ready,
        mentionsList: action.mentionsList
      };

    case 'SET_ERROR':
      return { ...state,
        actionStatus: _types.Status.error,
        action: undefined,
        actionId: undefined,
        error: action.err
      };
  }
}