"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$MenuWrapper = exports.$Wrapper = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

const $Wrapper = _styledComponents.default.div`
  position: relative;
`;
exports.$Wrapper = $Wrapper;
const $MenuWrapper = _styledComponents.default.div`
  display: inline-flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 0;
`;
exports.$MenuWrapper = $MenuWrapper;