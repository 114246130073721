"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Category = exports.TimeRange = exports.SortBy = exports.GroupBy = void 0;
let GroupBy;
exports.GroupBy = GroupBy;

(function (GroupBy) {
  GroupBy["Site"] = "SITE";
  GroupBy["Rule"] = "RULE";
  GroupBy["Priority"] = "PRIORITY";
  GroupBy["Category"] = "CATEGORY";
})(GroupBy || (exports.GroupBy = GroupBy = {}));

let SortBy;
exports.SortBy = SortBy;

(function (SortBy) {
  SortBy["Hits"] = "HITS";
  SortBy["Equips"] = "EQUIPS";
  SortBy["InsightsDesc"] = "INSIGHTS_DESC";
  SortBy["InsightsAsc"] = "INSIGHTS_ASC";
  SortBy["AlphaAsc"] = "ALPHA_ASC";
  SortBy["AlphaDesc"] = "ALPHA_DESC";
})(SortBy || (exports.SortBy = SortBy = {}));

let TimeRange;
/** Possible categories that can be assigned to rules */

exports.TimeRange = TimeRange;

(function (TimeRange) {
  TimeRange[TimeRange["Last24Hours"] = 1] = "Last24Hours";
  TimeRange[TimeRange["Last3Days"] = 3] = "Last3Days";
  TimeRange[TimeRange["Last7Days"] = 7] = "Last7Days";
  TimeRange[TimeRange["Last30Days"] = 30] = "Last30Days";
})(TimeRange || (exports.TimeRange = TimeRange = {}));

let Category;
exports.Category = Category;

(function (Category) {
  Category["All"] = "all";
  Category["Metering"] = "metering";
  Category["DataUptime"] = "data_uptime";
  Category["Optimisation"] = "optimisation";
  Category["FDD"] = "fdd";
  Category["Other"] = "null";
})(Category || (exports.Category = Category = {}));