"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCurrentTenant = useCurrentTenant;
exports.useApertureUrl = useApertureUrl;

var _useAxiosSwr = require("./useAxiosSwr");

var _store = require("../redux/store");

var _locale = require("../context/locale");

/* eslint-disable no-eq-null */

/** Fetch the tenant from the API based on the current user's tenantId */
function useCurrentTenant() {
  const tenantId = (0, _store.useAppSelector)(state => state.auth.user.tenantId);
  if (tenantId == null) throw Error('user.tenantId not available');
  return (0, _useAxiosSwr.useAxiosSwr)(`/v2/tenants/${tenantId}`, {
    requestConfig: {
      method: 'get'
    },
    swrConfig: {
      revalidateOnFocus: false,
      shouldRetryOnError: false
    }
  });
}
/** Returns a tenant-aware, locale supported link to Aperture with an optional path */


function useApertureUrl(path = '') {
  var _window$APERTURE_URL;

  const {
    locale
  } = (0, _locale.useLocale)();
  const apertureBaseURL = (_window$APERTURE_URL = window.APERTURE_URL) !== null && _window$APERTURE_URL !== void 0 ? _window$APERTURE_URL : 'http://localhost:8000';
  const {
    data: tenant
  } = useCurrentTenant();
  const protocol = window.location.protocol;
  return (tenant === null || tenant === void 0 ? void 0 : tenant.uiDomain) == null ? `${apertureBaseURL}/${locale}${path}` : `${protocol}//${tenant.uiDomain}/${locale}${path}`;
}