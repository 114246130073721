"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubduedBodyLarge = exports.TodayCountWrapper = exports.IconWrapper = exports.Right = exports.Left = exports.Bar = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const Bar = _styledComponents.default.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
exports.Bar = Bar;
const Segment = _styledComponents.default.div`
  display: inline-flex;
  align-items: center;
`;
const Left = (0, _styledComponents.default)(Segment)`
  justify-content: flex-start;
`;
exports.Left = Left;
const Right = (0, _styledComponents.default)(Segment)`
  justify-content: flex-end;
`;
exports.Right = Right;
const IconWrapper = (0, _styledComponents.default)(Segment)`
  margin: ${_blocks.spacing.tight};
`;
exports.IconWrapper = IconWrapper;
const TodayCountWrapper = (0, _styledComponents.default)(_blocks.Body)`
  color: ${_blocks.color.theme.typography.subdued};
`;
exports.TodayCountWrapper = TodayCountWrapper;
const SubduedBodyLarge = (0, _styledComponents.default)(_blocks.BodyLarge)`
  color: ${_blocks.color.theme.typography.subdued};
`;
exports.SubduedBodyLarge = SubduedBodyLarge;