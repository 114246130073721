"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMonitoringState = void 0;

var React = _interopRequireWildcard(require("react"));

var _ = require("..");

var _types = require("../types");

var _storage = require("../../../../utils/storage");

var _constants = require("../constants");

var _useCounts = require("./useCounts");

const useMonitoringState = (data, category, showSnoozed, filterFirst = false) => {
  const [group, setGroup] = React.useState((0, _storage.getPersisted)({
    prop: _constants.GROUP_BY_KEY,
    key: _constants.MONITORING_LS_KEY
  }, _types.GroupBy.Priority) || _types.GroupBy.Priority);
  const [sort, setSort] = React.useState((0, _storage.getPersisted)({
    prop: _constants.SORT_BY_KEY,
    key: _constants.MONITORING_LS_KEY
  }, _types.SortBy.AlphaAsc) || _types.SortBy.AlphaAsc);
  const [preFiltered, setPreFiltered] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);
  const [composed, setComposed] = React.useState([]);
  const [grouped, setGrouped] = React.useState([]);
  const [composedGrouped, setComposedGrouped] = React.useState([]);
  const [finalGrouped, setFinalGrouped] = React.useState({
    composed: [],
    group: (0, _storage.getPersisted)({
      prop: _constants.GROUP_BY_KEY,
      key: _constants.MONITORING_LS_KEY
    }, _types.GroupBy.Priority) || _types.GroupBy.Priority
  });
  const counts = (0, _useCounts.useCounts)(group, grouped, composedGrouped, showSnoozed); // Process initial data

  React.useLayoutEffect(() => {
    if (data.composed) {
      setComposed(data.composed);
      setPreFiltered(data.composed);
    }
  }, [data.composed]); // Replace hits with composed insights on data

  React.useLayoutEffect(() => {
    const filt = filtered.length > 0 ? filtered : preFiltered;
    if (filt.length === 0) return; // TODO: This typing is kind of awful.  Fix it when possible

    const insights = filt.reduce((mapped, occ) => {
      mapped[occ.occurrenceId] = occ;
      return mapped;
    }, {});
    const newData = { ...data,
      hit: insights
    };
    const filteredByCategory = (0, _.filterByCategory)(category, group, newData);
    setGrouped(filteredByCategory);
  }, [category, group, filtered, composed, sort]); // Compose final insight groups

  React.useEffect(() => {
    const composedGroups = grouped.map(occ => (0, _.groupByGroupName)(group, occ));
    const sortedComposedGroups = (0, _.sortFilteredData)(composedGroups, group, sort);
    setComposedGrouped(sortedComposedGroups);
  }, [grouped, sort]);
  React.useEffect(() => {
    if (filterFirst && filtered.length > 0) {
      setFinalGrouped({
        composed: composedGrouped,
        group
      });
    }

    if (!filterFirst) {
      setFinalGrouped({
        composed: composedGrouped,
        group
      });
    }
  }, [composedGrouped]);
  return {
    composed,
    finalGrouped,
    counts,
    filtered,
    setFiltered,
    setGroup,
    setSort,
    sort
  };
};

exports.useMonitoringState = useMonitoringState;