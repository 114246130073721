"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PristineSpy = void 0;

var React = _interopRequireWildcard(require("react"));

var _reactFinalForm = require("react-final-form");

const PristineSpy = ({
  onPristineChange
}) => {
  const state = (0, _reactFinalForm.useFormState)();
  React.useEffect(() => {
    onPristineChange(state.pristine);
  }, [state.pristine]);
  return null;
};

exports.PristineSpy = PristineSpy;