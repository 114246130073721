"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localizedFormatDistanceStrict = localizedFormatDistanceStrict;
exports.localizedFormatDistanceToNowStrict = localizedFormatDistanceToNowStrict;
exports.localizedFormat = localizedFormat;

var _dateFns = require("date-fns");

var _locale = require("date-fns/locale");

/**
 * Localize the string return from formatDistanceStrict function, it will also add
 * word 'ago' after the date
 * @param start
 * @param end
 * @param locale
 */
function localizedFormatDistanceStrict(start, end, locale) {
  const localesMap = {
    en: _locale.enGB,
    fr: _locale.fr
  };
  return (0, _dateFns.formatDistanceStrict)(start, end, {
    addSuffix: true,
    locale: localesMap[locale]
  });
}

function localizedFormatDistanceToNowStrict(date, locale) {
  const localesMap = {
    en: _locale.enGB,
    fr: _locale.fr
  };
  return (0, _dateFns.formatDistanceToNowStrict)(date, {
    roundingMethod: 'floor',
    addSuffix: true,
    locale: localesMap[locale]
  });
}
/**
 *
 * @param date
 * @param dateFormat
 * @param locale
 */


function localizedFormat(date, dateFormat, locale) {
  const localesMap = {
    en: _locale.enGB,
    fr: _locale.fr
  };
  return (0, _dateFns.format)(date, dateFormat, {
    locale: localesMap[locale]
  });
}