"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = void 0;
let Status; // Types

exports.Status = Status;

(function (Status) {
  Status[Status["idle"] = 0] = "idle";
  Status[Status["loading"] = 1] = "loading";
  Status[Status["ready"] = 2] = "ready";
  Status[Status["error"] = 3] = "error";
})(Status || (exports.Status = Status = {}));