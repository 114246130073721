"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_ACTIONS_URL_HISTORY = exports.UPDATE_MONITORING_URL_HISTORY = exports.UPDATE_INSIGHTS_URL_HISTORY = void 0;

var _history = require("history");

const UPDATE_INSIGHTS_URL_HISTORY = 'bonfire/history/UPDATE_INSIGHTS_URL_HISTORY';
exports.UPDATE_INSIGHTS_URL_HISTORY = UPDATE_INSIGHTS_URL_HISTORY;
const UPDATE_MONITORING_URL_HISTORY = 'bonfire/history/UPDATE_MONITORING_URL_HISTORY';
exports.UPDATE_MONITORING_URL_HISTORY = UPDATE_MONITORING_URL_HISTORY;
const UPDATE_ACTIONS_URL_HISTORY = 'bonfire/history/UPDATE_ACTIONS_URL_HISTORY';
exports.UPDATE_ACTIONS_URL_HISTORY = UPDATE_ACTIONS_URL_HISTORY;