"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ACTION_NOT_FOUND = exports.UPLOAD_HYDRA_ATTACHMENT_REQUEST_FAIL = exports.UPLOAD_HYDRA_ATTACHMENT_REQUEST_SUCCESS = exports.UPLOAD_HYDRA_ATTACHMENT_REQUEST = exports.ADD_ACTION_COMPLETE = exports.ADD_ACTION_REQUEST_FAIL = exports.ADD_ACTION_REQUEST_SUCCESS = exports.ADD_ACTION_REQUEST = exports.ADD_ACTION = void 0;
const ADD_ACTION = 'bonfire/_BETA/action/ADD_ACTION';
exports.ADD_ACTION = ADD_ACTION;
const ADD_ACTION_REQUEST = 'bonfire/_BETA/action/ADD_ACTION_REQUEST';
exports.ADD_ACTION_REQUEST = ADD_ACTION_REQUEST;
const ADD_ACTION_REQUEST_SUCCESS = 'bonfire/_BETA/action/ADD_ACTION_REQUEST_SUCCESS';
exports.ADD_ACTION_REQUEST_SUCCESS = ADD_ACTION_REQUEST_SUCCESS;
const ADD_ACTION_REQUEST_FAIL = 'bonfire/_BETA/action/ADD_ACTION_REQUEST_FAIL';
exports.ADD_ACTION_REQUEST_FAIL = ADD_ACTION_REQUEST_FAIL;
const ADD_ACTION_COMPLETE = 'bonfire/_BETA/action/ADD_ACTION_COMPLETE';
exports.ADD_ACTION_COMPLETE = ADD_ACTION_COMPLETE;
const UPLOAD_HYDRA_ATTACHMENT_REQUEST = 'bonfire/_BETA/action/UPLOAD_HYDRA_ATTACHMENT_REQUEST';
exports.UPLOAD_HYDRA_ATTACHMENT_REQUEST = UPLOAD_HYDRA_ATTACHMENT_REQUEST;
const UPLOAD_HYDRA_ATTACHMENT_REQUEST_SUCCESS = 'bonfire/_BETA/action/UPLOAD_HYDRA_ATTACHMENT_REQUEST_SUCCESS';
exports.UPLOAD_HYDRA_ATTACHMENT_REQUEST_SUCCESS = UPLOAD_HYDRA_ATTACHMENT_REQUEST_SUCCESS;
const UPLOAD_HYDRA_ATTACHMENT_REQUEST_FAIL = 'bonfire/_BETA/action/UPLOAD_HYDRA_ATTACHMENT_REQUEST_FAIL';
exports.UPLOAD_HYDRA_ATTACHMENT_REQUEST_FAIL = UPLOAD_HYDRA_ATTACHMENT_REQUEST_FAIL;
const ACTION_NOT_FOUND = 'bonfire/_BETA/action/ACTION_NOT_FOUND';
exports.ACTION_NOT_FOUND = ACTION_NOT_FOUND;