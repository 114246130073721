"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Content = exports.Trigger = exports.Container = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _blocks = require("@bueno/blocks");

const showAnim = (0, _styledComponents.keyframes)`
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
`;
const Container = _styledComponents.default.span`
  display: flex;
  position: relative;
  margin-left: 4px;
`;
exports.Container = Container;
const Trigger = _styledComponents.default.span`
  display: flex;
  padding: 0;
  background: none;
  border: none;
  border-radius: 999px;
  appearance: none;
  cursor: pointer;
  position: relative;
  z-index: 2;
`;
exports.Trigger = Trigger;
const Content = _styledComponents.default.span`
  visibility: hidden;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  padding: 8px;
  z-index: 3;
  width: 188px;

  background-color: #000;
  border-radius: 4px;

  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.12),
    0 16px 24px 0 rgba(0, 0, 0, 0.14);

  text-align: left;
  color: ${_blocks.color.theme.typography.reversed};
  ${_blocks.typography.body}

  cursor: auto;
  user-select: text;

  // extra hit area for moving the cursor from the trigger to the content
  &::after {
    content: '';
    position: absolute;
    top: -24px;
    left: 50%;
    right: 0;
    height: 24px;
    clip-path: polygon(100% 0, 100% 100%, 0% 100%);
  }

  ${Trigger}:hover ~ &,
  &:hover {
    animation-name: ${showAnim};
    animation-duration: ${_blocks.animation.timing.default};
    animation-timing-function: linear;
    animation-delay: 225ms;
    animation-iteration-count: 1;
    animation-fill-mode: both;
  }
`;
exports.Content = Content;