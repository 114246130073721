"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortByLabel = sortByLabel;
exports.sortByEquipTargetName = sortByEquipTargetName;
exports.mapRuleCategoryToActionContext = mapRuleCategoryToActionContext;

var _types = require("../../../components/Analysis/utils/types");

function sortByLabel(data) {
  return [...data].sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    if (b.label.toLowerCase() < a.label.toLowerCase()) return 1;
    return 0;
  });
}

function sortByEquipTargetName(data) {
  return [...data].sort((a, b) => {
    if (a.targetName.toLowerCase() < b.targetName.toLowerCase()) return -1;
    if (b.targetName.toLowerCase() < a.targetName.toLowerCase()) return 1;
    return 0;
  });
}

function mapRuleCategoryToActionContext(ruleCategory) {
  var _ruleCategoryMap$rule;

  console.log(ruleCategory);
  const ruleCategoryMap = {
    [_types.Category.FDD]: 'FDD',
    [_types.Category.Optimisation]: 'Optimisation',
    [_types.Category.DataUptime]: 'Data Uptime',
    [_types.Category.Metering]: 'Metering'
  };
  return (_ruleCategoryMap$rule = ruleCategoryMap[ruleCategory]) !== null && _ruleCategoryMap$rule !== void 0 ? _ruleCategoryMap$rule : '';
}