"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMonthName = getMonthName;

/**
 * Get three letters abbreviation of a month number
 * @param month month number string starting from 1
 */
function getMonthName(month, short = true) {
  month = month.replace(/^0+/, ''); // Remove leading zero

  const list = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const index = parseInt(month, 10) - 1;
  return short ? list[index].substring(0, 3) : list[index];
}