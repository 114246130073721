"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countOldIssues = countOldIssues;
exports.getActionsExportData = getActionsExportData;

var _dateFns = require("date-fns");

/**
 * Count the number of issues that were created before `range` days ago
 * @param {Array<Issue>} issues
 * @param {number} range Number of days to include
 */
function countOldIssues(issues, range = 60) {
  const cutoff = (0, _dateFns.sub)(new Date(), {
    days: range
  });
  return issues.reduce((numOld, issue) => {
    if ((0, _dateFns.isBefore)(new Date(issue.created), cutoff)) return numOld + 1;
    return numOld;
  }, 0);
}

function getAssociatedEquipsName(associatedEquips) {
  if (associatedEquips.length === 0) {
    return '';
  }

  return associatedEquips.reduce((equipsString, equip) => {
    // assemble target names in a single string, and target names
    // are separated by comma plus a space
    return equipsString += `${equip.targetName}, `;
  }, '');
}
/**
 * helper function to return the input if that's not undefined, otherwise return
 * placehoder string
 * @param field string | number | undefined
 */


function emptyFieldCheck(field, placeholder) {
  if (field === undefined) {
    return placeholder || '';
  }

  return field;
}
/**
 * Return CSV formatted data for the Exporter, so
 * users can download the data as CSV file
 * @param {*} rawData
 */


function getActionsExportData(rawData) {
  return rawData.map(action => ({
    projectName: action.project_name,
    siteName: action.components,
    key: action.key,
    summary: action.summary,
    context: action.context,
    createdAt: action.created,
    updatedAt: action.updated,
    priority: emptyFieldCheck(action.priority, 'unprioritised'),
    status: action.bonfire_status,
    transition_reason: emptyFieldCheck(action.transition_reason, 'no transition reason'),
    closed: emptyFieldCheck(action.closed, 'no close date'),
    assignee: emptyFieldCheck(action.bonfire_assignee || action.assignee_display_name),
    responsible: emptyFieldCheck(action.responsible),
    elec: emptyFieldCheck(action.elec),
    gas: emptyFieldCheck(action.gas),
    water: emptyFieldCheck(action.water),
    thermal: emptyFieldCheck(action.thermal),
    total_financial_impact: emptyFieldCheck(action.total_financial_impact),
    operational_savings: emptyFieldCheck(action.operational_savings),
    screenshot_link: emptyFieldCheck(action.screenshot_link),
    report_link: emptyFieldCheck(action.skyspark_link),
    rule: emptyFieldCheck(action.spark_type),
    associated_equips: getAssociatedEquipsName(action.associatedEquips),
    description: emptyFieldCheck(action.description, ''),
    action_url: `${window.location.origin}/actions/${action.key}`
  }));
}