"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledThirds = exports.Content = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const Container = _styledComponents.default.div`
  overflow: visible;
  position: relative;
  width: 100%;
  /* NOTE: This is temporarily disabled because we're now removing columns when
  opening/closing the sidebar and this makes it look jarring. */
  /* transition: width 0.3s ease-in-out; */
  display: flex;
  flex-direction: column;
`;
exports.Container = Container;
const Content = _styledComponents.default.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: space-between;
`;
exports.Content = Content;
const StyledThirds = (0, _styledComponents.default)(_blocks.Thirds)`
  width: 100%;
`;
exports.StyledThirds = StyledThirds;