"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var actionTypes = _interopRequireWildcard(require("./actionTypes"));

const INITIAL_STATE = {
  action: undefined,
  equipRules: [],
  rules: [],
  comments: [],
  isLoading: false,
  isRulesLoading: false,
  isCommentsLoading: false,
  isPending: false,
  submitError: ''
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Adding an action
    case actionTypes.ADD_ACTION:
      return { ...state,
        isPending: true,
        submitError: ''
      };

    case actionTypes.ADD_ACTION_REQUEST_SUCCESS:
      return { ...state,
        isPending: true
      };

    case actionTypes.ADD_ACTION_COMPLETE:
      return { ...state,
        isPending: false
      };

    case actionTypes.ADD_ACTION_REQUEST_FAIL:
      return { ...state,
        submitError: action.error.message,
        isPending: false
      };
    // Attachments

    default:
      return state;
  }
}