"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _types = require("./types");

var _ = require("./");

var _dateFns = require("date-fns");

function useActions() {
  const dispatch = (0, _.useDispatch)();
  /**
   * Retrieves the detailed metrics data for the utilities
   */

  const getMetrics = React.useCallback(async function getMetrics(metricsType, sites) {
    dispatch({
      type: 'LOAD_METRICS'
    });

    try {
      const siteIds = [...new Set(sites)];
      const url = '/v2/metrics/sites/utilities';
      const interval = {
        start: new Date('01/01/2010'),
        // NOTE: Earliest date of data in production
        end: new Date()
      };
      const years = (0, _dateFns.eachYearOfInterval)(interval).map(yearDate => yearDate.getFullYear());
      const months = {
        from: 1,
        to: 12
      };
      const {
        data
      } = await _axios.default.post(url, {
        siteIds,
        utilType: metricsType.toLocaleLowerCase(),
        years,
        months
      });
      const metricsUnit = _types.MetricsUnit[metricsType];
      dispatch({
        type: 'METRICS_REQUEST_SUCCESS',
        metricsType,
        metricsUnit,
        metrics: data
      });
    } catch (error) {// @TODO: Dispatch error state
    }
  }, []);
  /**
   * Set which value prop to use for comparison in horizontal bar list (Moving Annual Total | Direct Monthly Comparison)
   */

  const setRankedCompareBy = React.useCallback(function setRankedCompareBy(value) {
    dispatch({
      type: 'SET_RANKED_COMPARE_BY',
      value
    });
  }, []);
  /**
   * Update selected sites
   */

  const setSelectedSites = React.useCallback(function setSelectedSites(selectedSites) {
    dispatch({
      type: 'SET_SELECTED_SITES',
      selectedSites: [...new Set(selectedSites)]
    });
  }, []);
  /**
   * Update currnet metrics type of utilities
   */

  const setMetricsType = React.useCallback(function SetMetricsType(metricsType) {
    dispatch({
      type: 'SET_METRICS_TYPE',
      metricsType
    });
  }, []);
  return {
    getMetrics,
    setMetricsType,
    setSelectedSites,
    setRankedCompareBy
  };
}