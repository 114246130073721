"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uniq = uniq;
exports.filterList = filterList;
exports.isObjectEmpty = isObjectEmpty;
exports.orderObjectArrayByKey = orderObjectArrayByKey;
exports.createSortMap = createSortMap;
exports.sortInsightData = sortInsightData;
exports.sortMonitoringData = sortMonitoringData;
exports.sortActionData = sortActionData;

var _validation = require("./validation");

var _columns = require("./columns");

/**
 * Return a new array of only unique values taking into account `null` returning
 * a new array of only strings or numbers.
 *
 * @param arr flat array of primitive values
 */
function uniq(arr) {
  const breadcrumbs = {};
  const result = [];
  arr.forEach(x => {
    if ((typeof x === 'string' || typeof x === 'number') && !breadcrumbs[String(x)]) {
      result.push(x);
      breadcrumbs[String(x)] = true;
    }
  });
  return result;
}
/**
 * Filter an array of objects based on the supplied key
 *
 * @param text text string against which to filter group data
 * @param key key against which to limit text matching filter
 * @param list array of objects with a [key] property of type [string]
 *
 * TODO: The argument definitions may not be that strict here
 */


function filterList(text, key, list = []) {
  if (key && text) {
    const parsed = (0, _validation.escapeRegExp)(text).split(' ').map(word => `(?=.*${word})`).join('');
    const re = new RegExp(parsed, 'i');
    return list.filter(item => re.test(item[key]));
  }

  return list;
}

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
/**
 * Sort an array of objects by a given key returning a new array
 *
 * @param arr
 * @param key key of object to sort by (uses default alphabetical sort)
 */


function orderObjectArrayByKey(arr, key) {
  return [...arr].sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
}
/**
 * Create a sort map from an array of keys instead of defining them manually
 *
 * @param keys array of strings to use as keys for the map
 * @param reverse reverse the order of the map
 */


function createSortMap(keys, reverse = false) {
  // Create a new array with the input keys to avoid mutation
  const toMap = reverse ? [...keys].reverse() : [...keys];
  return toMap.reduce((out, key, idx) => {
    // if the key already exists it'll just be overwritten, force no dupes
    // NOTE: TSLint isn't smart enough here to know that this condition may be
    // false. A test has been added to check for this
    // tslint:disable-next-line:strict-type-predicates
    if (out[key] !== undefined) {
      throw Error('Duplicate key found in map input');
    } // set the key with a non-zero index
    // disabling eslint because I can do what I want, you're not my real mum

    /* eslint-disable-next-line */


    out[key] = idx + 1;
    return out;
  }, {});
}
/**
 *
 * @param {object} data array of Issues
 * @param {string} filter key to match against sortFuncs
 */


function sortInsightData(data, filter, direction = 'asc') {
  const column = _columns.insightColumns[filter]; // tslint:disable-next-line:strict-type-predicates

  if (column !== undefined) {
    return column.sortFn(data, direction);
  }

  return data;
}
/**
 *
 * @param {Bonfire.Insights.ComposedOccurrence[]} data array of monitoring data
 * @param {string} filter key to match against sortFuncs
 */


function sortMonitoringData(data, filter, direction = 'asc') {
  const column = _columns.monitoringColumns[filter]; // tslint:disable-next-line:strict-type-predicates

  if (column !== undefined) {
    return column.sortFn(data, direction);
  }

  return data;
}
/**
 *
 * @param {object} data array of Issues
 * @param {string} filter key to match against sortFuncs
 */


function sortActionData(data, filter, direction = 'asc') {
  // @ts-expect-error @TODO
  const column = _columns.actionColumns[filter];

  if (column !== undefined) {
    return column.sortFn(data, direction);
  }

  return data;
}