"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sessionEndpoint = void 0;

/**
 * The API endpoint used to return a session object. This is similar to what an
 * ID token would contain. i.e. `{ id, email, username, displayName }`
 */
const sessionEndpoint = '/v1/users/me';
exports.sessionEndpoint = sessionEndpoint;