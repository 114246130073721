"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Subtitle = exports.Title = exports.Controls = exports.Heading = exports.Header = exports.GridContainer = exports.ChartArea = exports.Content = exports.Block = exports.ContainerFullWidth = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

/**
 * NOTE:
 * This container is needed to set the container width of the chart,
 * because on MacOS when users have the browser scrollbar always appear,
 * the scrollbar width will force the chart to update its width and
 * the chart will expand itself automatically.
 */
const Container = _styledComponents.default.div`
  /* max width - sidebar width - sidebar spacing - container padding */
  width: calc(${_blocks.dimension.maxWidth.mainContent} - 200px - 24px - 64px);
`;
exports.Container = Container;
const ContainerFullWidth = _styledComponents.default.div`
  // account for padding on parent
  width: calc(${_blocks.dimension.maxWidth.mainContent} - 64px);
`;
/** Outermost block for the main chart widget, styled Frame block */

exports.ContainerFullWidth = ContainerFullWidth;
const Block = (0, _styledComponents.default)(_blocks.Frame)`
  margin-bottom: ${_blocks.spacing.extraLoose};
  overflow: hidden;
`;
exports.Block = Block;
const Content = (0, _styledComponents.default)(_blocks.FrameContent)`
  display: block;
  width: 100%;
  min-height: 100px;
  padding: ${_blocks.spacing.default} 0;

  /* TODO: This should be part of the Axis component in blocks */
  .visx-axis-bottom .visx-axis-tick {
    &:last-of-type text {
      text-anchor: end;
    }
    &:first-of-type text {
      text-anchor: start;
    }
  }
`;
exports.Content = Content;
const ChartArea = _styledComponents.default.div`
  margin-bottom: ${_blocks.spacing.default};
  padding: 0 ${_blocks.spacing.default};
`;
exports.ChartArea = ChartArea;
const GridContainer = _styledComponents.default.div`
  padding: 0 ${_blocks.spacing.default} ${_blocks.spacing.default};
  max-height: 500px;
  overflow: auto;
`;
/** Styled FrameHeader, wraps the heading and controls */

exports.GridContainer = GridContainer;
const Header = (0, _styledComponents.default)(_blocks.FrameHeader)`
  ${_blocks.typography.displaySmall};
  display: flex;
  align-items: center;
  border-bottom: none;
  padding-bottom: 0;
`;
/** Wraps both the title and subtitle */

exports.Header = Header;
const Heading = _styledComponents.default.div`
  flex-grow: 1;
`;
/** Wraps chart controls, pushed to the far right */

exports.Heading = Heading;
const Controls = _styledComponents.default.div``;
/** Main title of the header, h3 by default */

exports.Controls = Controls;
const Title = (0, _styledComponents.default)(_blocks.DisplaySmall)`
  em {
    font-style: italic;
  }
`;
/** Typically a description of the chart information, shown below the Title */

exports.Title = Title;
const Subtitle = (0, _styledComponents.default)(_blocks.BodyLarge)`
  color: ${(0, _blocks.hslaConverter)(_blocks.color.theme.typography.subdued, 'hex')};
`;
exports.Subtitle = Subtitle;