"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _ = require(".");

function useActions() {
  const dispatch = (0, _.useRulesDispatch)();
  const getRules = React.useCallback(async function getRules() {
    dispatch({
      type: 'LOAD_RULES'
    });

    try {
      const request = await _axios.default.get('/rules');

      if (request.status === 200) {
        // * format rules to match expected config shape
        const formatted = Array.from(Object.values(request.data)).map(rule => ({
          priority: 0,
          filterable: rule.name,
          rule: {
            id: rule.id,
            name: rule.name
          }
        }));
        const rules = new Map(formatted.map(rule => [rule.rule.id, rule]));
        dispatch({
          type: 'LOAD_RULES_SUCCESS',
          rules,
          ruleList: formatted
        });
      } else {
        dispatch({
          type: 'LOAD_RULES_FAIL',
          error: request.statusText
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'LOAD_RULES_FAIL',
        error
      });
    }
  }, []);
  return {
    getRules
  };
}