"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatPct = formatPct;

function formatPct(pct) {
  return Intl.NumberFormat('en', {
    maximumSignificantDigits: pct.toString().split('.')[0].length > 4 ? 4 : 3
  }).format(Math.abs(pct));
}