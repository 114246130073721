"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _actionTypes = require("./actionTypes");

var Persona;

(function (Persona) {
  Persona["PORTFOLIO_MANAGER"] = "PORTFOLIO_MANAGER";
  Persona["SITE_MANAGER"] = "SITE_MANAGER";
  Persona["CONTRACTOR"] = "CONTRACTOR";
  Persona["POWER_USER"] = "POWER_USER";
  Persona["STAFF"] = "STAFF";
})(Persona || (Persona = {}));

const INITIAL_USER_STATE = {
  access: []
};
/**
 * gets the initial state, conditionally prepopulating
 * with mock values if app is running in the context
 * of a Cypress test
 */

function getInitialState(defaultUser) {
  const e2eUser = window.Cypress && window.Cypress.env('user');
  const user = e2eUser || defaultUser;
  return {
    user,
    loading: false,
    loaded: false,
    loggingIn: false,
    loggingOut: false
  };
}

const INITIAL_STATE = getInitialState(INITIAL_USER_STATE);

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case _actionTypes.LOAD_PORTFOLIO_SUCCESS:
      return { ...state,
        user: { ...state.user,
          ...action.result
        }
      };

    case _actionTypes.LOAD_PORTFOLIO_FAIL:
      return { ...INITIAL_STATE,
        error: action.error
      };

    default:
      return state;
  }
}