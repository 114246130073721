"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Sorting = exports.CompareTo = exports.TimeFrame = void 0;

/** Available current time frames. Stored in number of months */
let TimeFrame;
/** Available comparisons. Stored in number of years */

exports.TimeFrame = TimeFrame;

(function (TimeFrame) {
  TimeFrame[TimeFrame["CurrentMonth"] = 0] = "CurrentMonth";
  TimeFrame[TimeFrame["LastMonth"] = 1] = "LastMonth";
  TimeFrame[TimeFrame["Last3Months"] = 3] = "Last3Months";
  TimeFrame[TimeFrame["Last6Months"] = 6] = "Last6Months";
  TimeFrame[TimeFrame["Last12Months"] = 12] = "Last12Months";
})(TimeFrame || (exports.TimeFrame = TimeFrame = {}));

let CompareTo; // Lodash sort orders. Please do not change

exports.CompareTo = CompareTo;

(function (CompareTo) {
  CompareTo[CompareTo["PreviousPeriod"] = 0] = "PreviousPeriod";
  CompareTo[CompareTo["SamePeriodLastYear"] = 1] = "SamePeriodLastYear";
  CompareTo[CompareTo["SamePeriodTwoYearsAgo"] = 2] = "SamePeriodTwoYearsAgo";
})(CompareTo || (exports.CompareTo = CompareTo = {}));

let Sorting;
exports.Sorting = Sorting;

(function (Sorting) {
  Sorting["Asc"] = "asc";
  Sorting["Desc"] = "desc";
})(Sorting || (exports.Sorting = Sorting = {}));