"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListWrap = exports.NoResultsText = exports.ListSection = exports.InlineAction = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const InlineAction = _styledComponents.default.button.attrs({
  type: 'button'
})`
  display: inline-block;
  border: 0;
  background: transparent;
  margin: 0;
  padding: ${_blocks.spacing.extraTight} ${_blocks.spacing.tight};

  ${_blocks.typography.bodySmall}
  color: ${_blocks.palette.blue7};
`;
exports.InlineAction = InlineAction;
const ListSection = _styledComponents.default.div`
  margin-bottom: ${_blocks.spacing.snug};
  padding: 0 ${_blocks.spacing.extraTight};
  padding-bottom: ${_blocks.spacing.extraTight};
`;
exports.ListSection = ListSection;
const NoResultsText = _styledComponents.default.p`
  ${_blocks.typography.body}
`;
exports.NoResultsText = NoResultsText;
const ListWrap = _styledComponents.default.div``;
exports.ListWrap = ListWrap;