"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useQueryState = void 0;

var React = _interopRequireWildcard(require("react"));

var _constants = require("../constants");

var _ = require("..");

const useQueryState = (history, updateUrlHistory) => {
  const setQuery = React.useCallback(function setQuery(query) {
    (0, _.updateQueryString)(query, history, _constants.SEARCH_KEY);
    updateUrlHistory(history.location, history.action);
  }, [history]);
  return {
    setQuery
  };
};

exports.useQueryState = useQueryState;