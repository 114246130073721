"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.initialState = void 0;

var _types = require("./types");

const initialState = {
  status: _types.Status.idle
};
exports.initialState = initialState;

function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE_FIELDS':
      return { ...state,
        status: _types.Status.loading
      };

    case 'SET_COMPLETE':
      return { ...state,
        status: _types.Status.complete
      };

    case 'SET_ERROR':
      return { ...state,
        status: _types.Status.error
      };

    case 'RESET':
      return initialState;
  }
}