"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Hr = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const Container = _styledComponents.default.div`
  width: 600px;
  padding: ${_blocks.spacing.loose};
`;
exports.Container = Container;
const Hr = _styledComponents.default.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid ${_blocks.color.theme.divider.solid};
`;
exports.Hr = Hr;