"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _actionTypes = require("./actionTypes");

const INITIAL_STATE = {
  projects: {},
  sites: {},
  isLoading: false,
  hasLoaded: false,
  projectGroups: []
};

function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case _actionTypes.LOAD_USER_SITES_REQUEST:
      return { ...state,
        isLoading: true,
        hasLoaded: false
      };

    case _actionTypes.LOAD_USER_SITES_REQUEST_SUCCESS:
      return { ...state,
        isLoading: false
      };

    case _actionTypes.LOAD_USER_SITES_REQUEST_FAIL:
      return { ...state,
        isLoading: false
      };

    case _actionTypes.UPDATE_USER_SITES:
      return { ...state,
        projects: action.projects,
        sites: action.sites,
        projectGroups: action.projectGroups,
        hasLoaded: true
      };

    default:
      return state;
  }
}