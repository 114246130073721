"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_SORT_BY_OPTION = exports.UPDATE_GROUP_BY_OPTION = exports.TOGGLE_SHOW_SNOOZED = void 0;
const TOGGLE_SHOW_SNOOZED = 'bonfire/insights/TOGGLE_SHOW_SNOOZED';
exports.TOGGLE_SHOW_SNOOZED = TOGGLE_SHOW_SNOOZED;
const UPDATE_GROUP_BY_OPTION = 'bonfire/insights/UPDATE_GROUP_BY_OPTION';
exports.UPDATE_GROUP_BY_OPTION = UPDATE_GROUP_BY_OPTION;
const UPDATE_SORT_BY_OPTION = 'bonfire/insights/UPDATE_SORT_BY_OPTION';
exports.UPDATE_SORT_BY_OPTION = UPDATE_SORT_BY_OPTION;