"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EXPANDING_KEY = exports.GROUP_BY_KEY = exports.SORT_BY_KEY = exports.INSIGHTS_LS_KEY = exports.MONITORING_LS_KEY = exports.SEARCH_KEY = void 0;
// TODO: These are a little inconsistent right now and should be revisted at
// some point
const SEARCH_KEY = 'analysisSearch';
exports.SEARCH_KEY = SEARCH_KEY;
const MONITORING_LS_KEY = 'Monitoring-groups';
exports.MONITORING_LS_KEY = MONITORING_LS_KEY;
const INSIGHTS_LS_KEY = 'Insights-groups';
exports.INSIGHTS_LS_KEY = INSIGHTS_LS_KEY;
const SORT_BY_KEY = 'sortBy';
exports.SORT_BY_KEY = SORT_BY_KEY;
const GROUP_BY_KEY = 'groupBy';
exports.GROUP_BY_KEY = GROUP_BY_KEY;
const EXPANDING_KEY = {
  monitoring: 'monitoring-expanded',
  insights: 'insights-expanded'
};
exports.EXPANDING_KEY = EXPANDING_KEY;