"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadGroups = loadGroups;

var _actionTypes = require("./actionTypes");

function loadGroups(form = null) {
  return {
    types: [_actionTypes.LOAD_GROUPS, _actionTypes.LOAD_GROUPS_SUCCESS, _actionTypes.LOAD_GROUPS_FAIL],
    promise: client => client.get('groups/lookup', {
      params: {
        form
      }
    })
  };
}