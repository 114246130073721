"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Text = exports.Increase = exports.Decrease = exports.SingleRow = exports.Row = exports.Header = exports.Container = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _blocks = require("@bueno/blocks");

// shared styled-components between different tooltip components
const Container = _styledComponents.default.section`
  background-color: ${_blocks.color.pureWhite};
  box-shadow: ${_blocks.elevation.modal};
  border-radius: ${_blocks.borderRadius.large};
  padding: ${_blocks.spacing.snug};
`;
exports.Container = Container;
const Header = _styledComponents.default.div`
  margin-bottom: ${_blocks.spacing.tight};
`;
exports.Header = Header;
const Row = _styledComponents.default.div`
  display: grid;
  grid-template-columns: ${({
  gridTemplateColumns = 'max-content auto auto'
}) => gridTemplateColumns};
  grid-template-rows: 1fr;
  gap: 16px;
  align-items: start;
  margin-bottom: ${_blocks.spacing.tight};
  padding-top: ${_blocks.spacing.tight};
  border-top: 1px solid ${_blocks.color.theme.divider.solid};
`;
exports.Row = Row;
const SingleRow = (0, _styledComponents.default)(Row)`
  padding-bottom: 0;
  border: 0;
`;
exports.SingleRow = SingleRow;
const arrowStyles = (0, _styledComponents.css)`
  height: 12px;
  width: 12px;
  background-color: ${_blocks.palette.neutral6};
  padding: 2px;
  border-radius: 2px;
  path {
    fill: ${_blocks.palette.pureWhite};
    stroke: #fff;
    stroke-width: 1;
  }
`;
const Decrease = (0, _styledComponents.default)(_blocks.ArrowPointDown)`
  ${arrowStyles};
  background-color: ${_blocks.palette.green6};
`;
exports.Decrease = Decrease;
const Increase = (0, _styledComponents.default)(_blocks.ArrowPointUp)`
  ${arrowStyles};
  background-color: ${_blocks.palette.red6};
`;
/**
 * Default:
 * fontSize: 14px
 * fontWeight: normal
 * color: color.pureBlack
 */

exports.Increase = Increase;
const Text = _styledComponents.default.div`
  margin-bottom: 0;
  font-style: normal;
  font-weight: ${props => {
  var _props$fontWeight;

  return (_props$fontWeight = props.fontWeight) !== null && _props$fontWeight !== void 0 ? _props$fontWeight : 600;
}};
  font-size: 13px;
  text-align: ${props => {
  var _props$align;

  return (_props$align = props.align) !== null && _props$align !== void 0 ? _props$align : 'left';
}};
  color: ${props => props.color === 'subdued' ? `${_blocks.color.theme.typography.subdued}` : `${_blocks.color.theme.typography.ink}`};
  line-height: 1;
`;
exports.Text = Text;