"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$Setting = exports.$List = exports.$Label = exports.$IconBubble = exports.$Wrapper = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

var _App = require("../../components/AppChrome/App.constants");

var _Menu = require("../../components/Menu/Menu.styles");

const $Wrapper = _styledComponents.default.div`
  position: relative;
  padding-top: 4px;
`;
exports.$Wrapper = $Wrapper;
const $IconBubble = (0, _styledComponents.default)(_blocks.Badge)`
  display: inherit;
  position: absolute;
  top: 2px;
  left: 50%;
  min-width: ${_App.BASE_UNIT * 3}px;
  background: ${_blocks.palette.orange5};
  height: ${_App.BASE_UNIT * 2}px;
  z-index: ${_App.LAYERS.FIRST};
`;
exports.$IconBubble = $IconBubble;
const $Label = _styledComponents.default.button.attrs({
  type: 'button'
})`
  display: inline-flex;
  align-items: center;
  border: none;
  font-size: 14px;
  background: none;

  &:focus {
    outline: 0;
  }
`;
exports.$Label = $Label;

function showList({
  isOpen
}) {
  return isOpen ? 'block' : 'none';
}

const $List = (0, _styledComponents.default)(_Menu.$Menu)`
  display: ${showList};
  left: auto;
  right: 0;
`;
exports.$List = $List;
const $Setting = (0, _styledComponents.default)(_Menu.$ButtonItem)`
  span {
    margin-right: ${_App.SPACING.TIGHT};
  }

  &:disabled {
    opacity: ${_App.OPACITY.SUBDUED};

    span {
      cursor: default;
    }
  }
`;
exports.$Setting = $Setting;