"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOccurrences = getOccurrences;

var _axios = _interopRequireDefault(require("axios"));

var _dateFns = require("date-fns");

var _requestSchedule = require("../../redux/modules/_BETA/requestSchedule");

/**
 * Load analysis data for a given array of sites over a specific time range
 *
 * @param dispatch
 * @param timeRange Over how many days should insights be retrieved
 * @param selectedSites Existing selections of sites
 */
async function getOccurrences(dispatch, timeRange = 3, selectedSites) {
  dispatch({
    type: 'LOAD_OCCURRENCES'
  }); // HACK: This is to prevent any requests happening when they don't need to

  const store = require('../../client').store;

  store.dispatch({
    type: _requestSchedule.UPDATE_SHOULD_REQUEST_INSIGHTS,
    shouldRequestInsights: false
  });

  try {
    const to = (0, _dateFns.format)(new Date(), 'yyyy-MM-dd');
    const from = (0, _dateFns.format)((0, _dateFns.sub)(new Date(), {
      days: timeRange
    }), 'yyyy-MM-dd'); // Make the request

    const endpoint = '/synco/ui/insights';
    const body = {
      sites: selectedSites,
      insightType: 'insights'
    };
    const {
      data
    } = await _axios.default.post(endpoint, body, {
      params: {
        from,
        to
      }
    }); // Store the retrieved occurrences and data groups for later composition

    dispatch({
      type: 'LOAD_OCCURRENCES_SUCCESS',
      rawData: data
    });
  } catch (error) {// TODO: Dispatch error state
  }
}