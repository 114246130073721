"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _actionTypes = require("./actionTypes");

var _utils = require("./utils");

// ! TODO: Remove unnecessary legacy insights logic from this reducer
const INITIAL_STATE = {
  loading: false,
  ready: false,
  groupByOption: _utils.GROUP_BY_OPTIONS.SITE.key,
  sortByOption: _utils.SORT_BY_OPTIONS.A_Z.key,
  composed: undefined,
  siteGroups: undefined,
  error: undefined,
  showSnoozed: true
};

function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case _actionTypes.TOGGLE_SHOW_SNOOZED:
      return { ...state,
        showSnoozed: !state.showSnoozed
      };

    case _actionTypes.UPDATE_GROUP_BY_OPTION:
      return { ...state,
        groupByOption: action.groupByOption
      };

    case _actionTypes.UPDATE_SORT_BY_OPTION:
      return { ...state,
        sortByOption: action.sortByOption
      };

    default:
      return state;
  }
}