"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.EDIT_MODAL_DATA = exports.VIEW_MODAL_DATA = exports.ADD_MODAL_DATA = void 0;

var _helpers = require("../helpers/helpers");

var _actionTypes = require("./actionTypes");

var _actionCreators = require("./actionCreators");

const INITIAL_USER_FORM_DATA = {};
const ADD_MODAL_DATA = {
  title: 'Invite User',
  submitText: 'Invite User',
  submitStyle: 'success'
};
exports.ADD_MODAL_DATA = ADD_MODAL_DATA;
const VIEW_MODAL_DATA = {
  title: 'View User',
  submitText: 'Edit User',
  submitStyle: 'primary'
};
exports.VIEW_MODAL_DATA = VIEW_MODAL_DATA;
const EDIT_MODAL_DATA = {
  title: 'Edit User',
  submitText: 'Save Changes',
  submitStyle: 'success'
};
exports.EDIT_MODAL_DATA = EDIT_MODAL_DATA;
const initialState = {
  users: [],
  statuses: {},
  users_loaded: false,
  adding: false,
  viewing: false,
  editing: false,
  working: false,
  user_form_data: INITIAL_USER_FORM_DATA,
  modal_data: {},
  success: null,
  toggleDeactivateWorking: false
};

function reducer(state = initialState, action = {}) {
  let users = [];
  let newUser = {};
  let userFormData = {};

  switch (action.type) {
    case _actionTypes.LOAD_USERS:
      return { ...state,
        users_loading: true
      };

    case _actionTypes.LOAD_USERS_SUCCESS:
      return { ...state,
        users_loading: false,
        users_loaded: true,
        users: action.result,
        error: null,
        statuses: (0, _helpers.getUniqueList)(action.result, 'status')
      };

    case _actionTypes.LOAD_USERS_FAIL:
      return { ...state,
        users_loading: false,
        users_loaded: false,
        users: null,
        error: action.error
      };

    case _actionTypes.ADDING_USER:
      return { ...state,
        adding: true,
        modal_data: ADD_MODAL_DATA,
        success: null
      };

    case _actionTypes.ADD_USER:
      return { ...state,
        working: true
      };

    case _actionTypes.ADD_USER_FAIL:
      return { ...state,
        working: false,
        error: action.error.message || action.error.error
      };

    case _actionTypes.ADD_USER_SUCCESS:
      users = Object.assign([], state.users);
      newUser = { ...state.user_form_data,
        id: action.result.id,
        status: 'active'
      };
      users.unshift(newUser);
      return { ...state,
        error: null,
        adding: false,
        working: false,
        users,
        user_form_data: INITIAL_USER_FORM_DATA,
        success: `An email has been sent to **${state.user_form_data.email}** to complete sign up.`
      };

    case _actionTypes.SAVE_USER:
      return { ...state,
        working: true
      };

    case _actionTypes.SAVE_USER_FAIL:
      return { ...state,
        working: false,
        error: action.error.message || action.error.error
      };

    case _actionTypes.SAVE_USER_SUCCESS:
      {
        users = [...state.users];
        const userIndex = users.findIndex(user => user.id === state.user_form_data.id);
        users.splice(userIndex, 1, state.user_form_data);
        return { ...state,
          error: null,
          viewing: false,
          editing: false,
          working: false,
          users,
          user_form_data: INITIAL_USER_FORM_DATA,
          success: `Successfully saved changes to **${state.user_form_data.email}**.`
        };
      }

    case _actionTypes.VIEWING_USER:
      return { ...state,
        viewing: true,
        modal_data: VIEW_MODAL_DATA,
        user_form_data: action.row,
        submit_func: _actionCreators.startEditUser,
        // NOTE: why is there a function in the store?
        success: null
      };

    case _actionTypes.EDITING_USER:
      return { ...state,
        editing: true,
        modal_data: EDIT_MODAL_DATA,
        submit_func: _actionCreators.saveUserRequest // NOTE: why is there a function in the store?

      };

    case _actionTypes.EDITING_USER_FORM:
      userFormData = { ...state.user_form_data,
        [action.input_id]: action.input_value
      };
      return { ...state,
        user_form_data: userFormData
      };

    case _actionTypes.END_ACTION:
      return { ...state,
        editing: false,
        viewing: false,
        adding: false,
        working: false,
        user_form_data: INITIAL_USER_FORM_DATA,
        error: null
      };

    case _actionTypes.VALIDATE_FORM_ERROR:
      return { ...state,
        error: action.error.join('<br>')
      };

    case _actionTypes.TOGGLE_DEACTIVATE:
      return { ...state,
        toggleDeactivateWorking: true
      };

    case _actionTypes.TOGGLE_DEACTIVATE_SUCCESS:
      {
        const user = Object.assign({}, state.user_form_data);
        user.status = action.result.status;
        const resultingAction = action.result.status === 'deleted' ? 'deactivated' : 'reactivated';
        const params = {
          list: Object.assign([], state.users),
          new_object: user,
          key: 'id',
          value: state.user_form_data.id
        };
        const updatedUsers = (0, _helpers.updateListWithNewObject)(params);
        return { ...state,
          error: null,
          toggleDeactivateWorking: false,
          adding: false,
          viewing: false,
          editing: false,
          users: updatedUsers,
          toggleDeactivateUser: null,
          toggleDeactivateAction: null,
          statuses: (0, _helpers.getUniqueList)(updatedUsers, 'status'),
          success: `Account for **${action.result.email}** has been **${resultingAction}** successfully.`
        };
      }

    case _actionTypes.TOGGLE_DEACTIVATE_FAIL:
      return { ...state,
        error: action.error,
        toggleDeactivateWorking: false,
        toggleDeactivateUser: null,
        toggleDeactivateAction: null
      };

    default:
      return state;
  }
}