"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchImageFile = exports.matchUnSearchable = exports.matchMDLinks = void 0;

/**
 * Match markdown links
 * Capturing Group 1: Link Text
 * Capturing Group 2: Link URL
 */
const matchMDLinks = /\[([^[\]]+)\]\(([^)]+)\)/g;
/**
 * Match any character that isn't a number, alphabet character, or space
 */

exports.matchMDLinks = matchMDLinks;
const matchUnSearchable = /[^a-z0-9_: -]/gi;
exports.matchUnSearchable = matchUnSearchable;
const matchImageFile = /([a-z\-_0-9/:.]*\.(jpg|jpeg|png|gif))/i;
exports.matchImageFile = matchImageFile;