"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useExpandedState = void 0;

var React = _interopRequireWildcard(require("react"));

var _eventTracking = require("../../../../utils/eventTracking");

var _storage = require("../../../../utils/storage");

var _constants = require("../constants");

const useExpandedState = (firstRender, viewMode) => {
  const [expandAll, setExpandAll] = React.useState(false);
  React.useEffect(() => {
    if (firstRender) {
      firstRender = false;
    } else {
      (0, _storage.clearPersistedUI)(_constants.EXPANDING_KEY[viewMode]);
      (0, _eventTracking.expandAllEvent)(expandAll);
    }
  }, [expandAll]);
  return {
    expandAll,
    setExpandAll
  };
};

exports.useExpandedState = useExpandedState;