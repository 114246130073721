"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extractCookieDomain = extractCookieDomain;
exports.logout = logout;
exports.analyticsIdentify = analyticsIdentify;
exports.zendeskInit = zendeskInit;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _effects = require("redux-saga/effects");

var _reduxPersist = require("redux-persist");

var _jsonwebtoken = _interopRequireDefault(require("jsonwebtoken"));

var _zendesk = require("../../../helpers/zendesk");

var _segment = _interopRequireDefault(require("../../../utils/segment"));

var _actionTypes = require("./actionTypes");

var _utils = require("../_BETA/utils");

var _session = require("../../../context/session");

var _analyticsOptOutPlugin = require("@bueno-systems/analytics-opt-out-plugin");

/* eslint-disable no-eq-null */

/**
 * Returns the domain for a cookie based on the current domain.
 *
 * NOTE: This is only used to remove segment keys and is based on the assumption
 * that cookies are only set on the root domain.
 */
function extractCookieDomain(domain) {
  if (domain.includes('localhost')) return 'localhost';
  const parts = domain.split('.');
  return `.${parts.slice(parts.length - 2).join('.')}`;
}

function* logout() {
  while (true) {
    yield (0, _effects.take)(_actionTypes.LOGOUT); // Reset segment

    _segment.default.reset(); // Reset Zendesk integrations


    if (window.zE != null) {
      window.zE('webWidget', 'logout');
      window.zE('webWidget', 'reset');
      window.zE('webWidget', 'hide');
    } // Reset persisted state
    // TODO: The store value here can't be asserted until client.js is converted
    // to TypeScript


    const store = require('../../../client').store;

    const state = (0, _utils.resetState)(store.getState());
    localStorage.setItem('persist:state', JSON.stringify({
      state
    }));
    (0, _session.signOut)(window.location.origin);
  }
}

function* analyticsIdentify() {
  yield (0, _effects.take)(_reduxPersist.REHYDRATE);
  let loggedInUser = null; // NOTE: Intentionally null

  let existingUser = yield (0, _effects.select)(state => state.auth.user); // If there is nothing from the persisted state, wait until they've logged in
  // and set the logged in user value

  if (!existingUser || !existingUser.username) {
    const {
      result
    } = yield (0, _effects.take)(_actionTypes.LOAD_PORTFOLIO_SUCCESS);
    loggedInUser = result;
  } // eslint-disable-next-line no-eq-null


  if (window.SEGMENT_WRITE_KEY != null) {
    // If the logged in user value is not null, someone has had to log in
    // manually. Set this to the existing user value and track that they've
    // logged in
    if (loggedInUser !== null) {
      existingUser = loggedInUser;
    } // Remove any existing local user info, Segment will use this to re-link
    // their anonymous ID to their existing user ID and details. We no can no
    // longer track individuals with personally identifiable information.


    const idKey = 'ajs_user_id';

    _jsCookie.default.remove(idKey, {
      path: '/',
      domain: extractCookieDomain(window.location.hostname)
    });

    window.localStorage.removeItem(idKey);
    const traitsKey = 'ajs_user_traits';
    const lsTraits = window.localStorage.getItem(traitsKey);

    if (lsTraits != null && lsTraits !== 'null') {
      const parsed = JSON.parse(lsTraits);

      if (parsed.email != null || parsed.name != null) {
        window.localStorage.removeItem(traitsKey);
      }
    }

    _segment.default.load({
      cdnURL: 'https://keith.bueno-analytics.com',
      writeKey: window.SEGMENT_WRITE_KEY,
      plugins: [new _analyticsOptOutPlugin.OptOutPlugin({
        optOut: existingUser.optOutUserTracking
      })]
    }, {
      initialPageview: false
    });

    _segment.default.identify({
      persona: existingUser.persona
    });
  }
}

function* zendeskInit() {
  yield (0, _effects.take)(_reduxPersist.REHYDRATE); // If there's no shared secret, the widget script isn't loaded at all

  if (!window.ZENDESK_SHARED_SECRET) return;
  let user = yield (0, _effects.select)(state => state.auth.user);

  if (!user || !user.token) {
    const {
      result
    } = yield (0, _effects.take)(_actionTypes.LOAD_PORTFOLIO_SUCCESS);
    user = result;
  } // Set some defaults before the widget is initialised


  window.zESettings = { ..._zendesk.zendeskSettings,
    // Disable analytics if user opted out of tracking
    analytics: user.optOutUserTracking === false
  }; // Initialise zendesk widget, hide by default, show when user is authenticated

  if (window.zE != null) {
    const {
      locale
    } = yield (0, _effects.select)(state => state.betaSelections);
    window.zE('webWidget', 'setLocale', locale); // Don't show the widget automatically when the script loads

    window.zE('webWidget', 'hide'); // Hide the widget entirely when the user clicks the close button

    window.zE('webWidget:on', 'close', () => {
      var _window$zE, _window;

      (_window$zE = (_window = window).zE) === null || _window$zE === void 0 ? void 0 : _window$zE.call(_window, 'webWidget', 'hide');
    }); // Show the widget when the user opens the widget

    window.zE('webWidget:on', 'open', () => {
      var _window$zE2, _window2;

      (_window$zE2 = (_window2 = window).zE) === null || _window$zE2 === void 0 ? void 0 : _window$zE2.call(_window2, 'webWidget', 'show');
    });

    if (user.optOutUserTracking === false) {
      const zendeskToken = _jsonwebtoken.default.sign({
        name: user.display_name,
        email: user.email,
        iat: Math.floor(Date.now() / 1000),
        jti: Math.random().toString(36).substring(2) // unique token string

      }, window.ZENDESK_SHARED_SECRET);

      window.zE('webWidget', 'updateSettings', {
        authenticate: {
          jwt: zendeskToken
        }
      });
      window.zE('webWidget', 'identify', {
        name: user.display_name,
        email: user.email
      });
      window.zE('webWidget', 'prefill', {
        name: {
          value: user.display_name,
          readOnly: true
        },
        email: {
          value: user.email,
          readOnly: true
        }
      });
    }
  }
}