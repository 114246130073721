"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserSites = updateUserSites;

var _actionTypes = require("./actionTypes");

function updateUserSites({
  projects,
  sites,
  projectGroups
}) {
  return {
    type: _actionTypes.UPDATE_USER_SITES,
    projects,
    sites,
    projectGroups
  };
}