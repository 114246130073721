"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleShowSnoozed = exports.updateSortByOption = exports.updateGroupByOption = void 0;

var _actionTypes = require("./actionTypes");

const updateGroupByOption = groupByOption => ({
  type: _actionTypes.UPDATE_GROUP_BY_OPTION,
  groupByOption
});

exports.updateGroupByOption = updateGroupByOption;

const updateSortByOption = sortByOption => ({
  type: _actionTypes.UPDATE_SORT_BY_OPTION,
  sortByOption
});

exports.updateSortByOption = updateSortByOption;

const toggleShowSnoozed = () => ({
  type: _actionTypes.TOGGLE_SHOW_SNOOZED
});

exports.toggleShowSnoozed = toggleShowSnoozed;