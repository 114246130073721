"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.signIn = signIn;
exports.signOut = signOut;

/** Convenience function to redirect to the sign in endpoint with a returnTo */
function signIn(returnTo = window.location.href, locale = 'en') {
  const params = new URLSearchParams({
    returnTo,
    locale
  });
  window.location.href = `/api/auth/login?${params.toString()}`;
}
/** Convenience function to redirect to the sign out endpoint with a returnTo */


function signOut(returnTo = window.location.href) {
  const params = new URLSearchParams({
    returnTo
  });
  window.location.href = `/api/auth/logout?${params.toString()}`;
}