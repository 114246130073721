"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

class ToolTip extends _react.Component {
  constructor(props) {
    super(props);
    (0, _defineProperty2.default)(this, "open", () => {
      this.setState({
        isOpen: true
      });
    });
    (0, _defineProperty2.default)(this, "close", () => {
      this.setState({
        isOpen: false
      });
    });
    this.state = {
      isOpen: false
    };
  }

  render() {
    const {
      isOpen
    } = this.state;
    return this.props.children({
      close: this.close,
      open: this.open,
      isOpen,
      ...this.props
    });
  }

}

var _default = ToolTip;
exports.default = _default;
module.exports = exports.default;