"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ListItem = exports.Link = exports.List = exports.Heading = exports.Section = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

/** Outermost wrapper for the sidebar */
const Container = _styledComponents.default.aside`
  margin-right: ${_blocks.spacing.loose};
  min-width: 200px;
  width: 200px;
`;
/** Adds some negative space around each section of the sidebar */

exports.Container = Container;
const Section = _styledComponents.default.div`
  margin-bottom: ${_blocks.spacing.ultraLoose};
`;
/** Subtle titles for each sidebar section */

exports.Section = Section;
const Heading = _styledComponents.default.h3`
  margin-bottom: ${_blocks.spacing.snug};
  ${_blocks.typography.title};
  color: ${_blocks.color.theme.typography.subtle};
`;
exports.Heading = Heading;
const List = _styledComponents.default.ul``;
/** A button disguised as a Link, used for calling filter changes usually */

exports.List = List;
const Link = _styledComponents.default.button.attrs({
  type: 'button'
})`
  display: inline;
  color: inherit;
  ${_blocks.typography.displaySmall};
  padding: 0;
  background: transparent;
  font-weight: ${_blocks.fontWeight.normal};
  pointer-events: ${({
  disabled
}) => disabled ? 'none' : 'default'};

  em {
    font-style: italic;
  }
`;
exports.Link = Link;

function getFillColor(props) {
  return props.isActive ? _blocks.color.theme.typography.ink : _blocks.color.theme.typography.subdued;
}
/**  */


const ListItem = _styledComponents.default.li`
  display: flex;
  align-items: center;
  margin-bottom: ${_blocks.spacing.tight};
  color: ${getFillColor};

  &:hover {
    text-decoration: ${props => props.isActive ? 'none' : 'underline'};
  }

  svg {
    margin-right: ${_blocks.spacing.tight};
    stroke: ${getFillColor};
  }
`;
exports.ListItem = ListItem;