"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.analysisColumns = exports.monitoringColumns = exports.equipColumns = exports.ruleColumns = exports.insightColumns = exports.actionColumns = exports.Columns = void 0;

var _lodash = _interopRequireDefault(require("lodash.orderby"));

var _sorting = require("./sorting");

var _dateFns = require("date-fns");

/*
 * These columns define all available columns across the application. Subsequent
 * objects should be derived from this.
 *
 * It's assumed that all columns present will be rendered in the view. Options
 * are available to turn them off in the sort menu only.
 *
 * disabled: Visible in the sort menu, but checked and disabled
 * hidden: Not visibile in the sort menu
 */
let Columns;
exports.Columns = Columns;

(function (Columns) {
  Columns["ID"] = "ID";
  Columns["PRIORITY"] = "PRIORITY";
  Columns["SITE"] = "SITE";
  Columns["SUMMARY"] = "SUMMARY";
  Columns["CONTEXT"] = "CONTEXT";
  Columns["RESPONSIBLE"] = "RESPONSIBLE";
  Columns["DATES"] = "DATES";
  Columns["STATUS"] = "STATUS";
  Columns["LATEST"] = "LATEST";
  Columns["REGION"] = "REGION";
  Columns["RULE_NAME"] = "RULE_NAME";
  Columns["EQUIP_NAME"] = "EQUIP_NAME";
  Columns["EQUIP_DETAILS"] = "EQUIP_DETAILS";
  Columns["TRIGGERED_RULES"] = "TRIGGERED_RULES";
  Columns["AFFECTED_EQUIPS"] = "AFFECTED_EQUIPS";
  Columns["RULE_TYPE"] = "RULE_TYPE";
  Columns["SEVERITY"] = "SEVERITY";
  Columns["ACTIONS_COUNT"] = "ACTIONS_COUNT";
  Columns["ESTIMATED_SAVINGS"] = "ESTIMATED_SAVINGS";
})(Columns || (exports.Columns = Columns = {}));

const prioritySortMap = (0, _sorting.createSortMap)(['Blocker', 'Critical', 'Major', 'Moderate', 'Minor', 'Trivial'], true);
const ID = {
  key: Columns.ID,
  label: i18n => i18n._({
    id: 'columns.id',
    message: `ID`
  }),
  width: '70px',
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => {
    if (issue.key === undefined) return 1;
    return Number(issue.key.split('-')[1]);
  }, direction),
  sortDirection: 'desc',
  disabled: true,
  hidden: false
};
const MONITORING_PRIORITY = {
  key: Columns.PRIORITY,
  label: i18n => i18n._({
    id: 'columns.priority',
    message: `Priority`
  }),
  width: '120px',
  sortFn: (insights,
  /* @TODO */
  direction) => (0, _lodash.default)(insights, i => i.priority, direction),
  sortDirection: 'desc',
  disabled: false,
  hidden: false
};
const SITE = {
  key: Columns.SITE,
  label: i18n => i18n._({
    id: 'columns.site',
    message: `Site`
  }),
  width: '180px',
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => issue.components.toLowerCase(), direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const SUMMARY = {
  key: Columns.SUMMARY,
  label: i18n => i18n._({
    id: 'columns.summary',
    message: `Summary`
  }),
  width: '290px',
  sortFn: (issues,
  /* @TODO */
  direction) => // NOTE: The issue summary may sometimes have a leading space, thus
  // calling `.trim()` here. This should (in my opinion) be resolved by
  // trimming before it's served to the frontend. -- Jack 11/01/2019
  (0, _lodash.default)(issues, issue => issue.summary.toLowerCase().trim(), direction),
  sortDirection: 'asc',
  disabled: true,
  hidden: false
};
const CONTEXT = {
  key: Columns.CONTEXT,
  label: i18n => i18n._({
    id: 'columns.type',
    message: `Type`
  }),
  width: '110px',
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => {
    var _issue$context;

    return (_issue$context = issue.context) === null || _issue$context === void 0 ? void 0 : _issue$context.toLowerCase();
  }, direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const RESPONSIBLE = {
  key: Columns.RESPONSIBLE,
  label: i18n => i18n._({
    id: 'columns.team',
    message: `Team`
  }),
  width: '140px',
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => issue.responsible.toLowerCase(), direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const DATES = {
  key: Columns.DATES,
  label: i18n => i18n._({
    id: 'columns.updated',
    message: `Updated`
  }),
  width: '120px',
  sortFn: (issues,
  /* @TODO */
  direction = 'desc') => (0, _lodash.default)(issues, issue => (0, _dateFns.getUnixTime)(new Date(issue.updated)), direction),
  sortDirection: 'desc',
  disabled: false,
  hidden: false
};
const STATUS = {
  key: Columns.STATUS,
  label: i18n => i18n._({
    id: 'columns.status',
    message: `Status`
  }),
  width: '115px',
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => issue.bonfire_status.toLowerCase(), direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const ESTIMATED_SAVINGS = {
  key: Columns.ESTIMATED_SAVINGS,
  label: i18n => i18n._({
    id: 'columns.est_savings',
    message: `Est. Savings`
  }),
  width: '140px',
  sortFn: (issues,
  /* @TODO */
  direction) => {
    return (0, _lodash.default)(issues, issue => {
      var _issue$operational_sa, _issue$total_financia;

      return (// eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        ((_issue$operational_sa = issue.operational_savings) !== null && _issue$operational_sa !== void 0 ? _issue$operational_sa : 0) + ((_issue$total_financia = issue.total_financial_impact) !== null && _issue$total_financia !== void 0 ? _issue$total_financia : 0)
      );
    }, direction);
  },
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const LATEST = {
  key: Columns.LATEST,
  label: i18n => i18n._({
    id: 'columns.latest',
    message: `Latest`
  }),
  width: '90px',
  minWidth: '90px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, s => s.latestUnix, direction),
  sortDirection: 'desc',
  disabled: false,
  hidden: false
};
const REGION = {
  key: Columns.REGION,
  label: i18n => i18n._({
    id: 'columns.region',
    message: `Region`
  }),
  width: '80px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, s => s.site.state, direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const RULE_NAME = {
  key: Columns.RULE_NAME,
  label: i18n => i18n._({
    id: 'columns.rule_name',
    message: `Rule`
  }),
  width: '420px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, r => r.ruleName, direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const EQUIP_NAME = {
  key: Columns.EQUIP_NAME,
  label: i18n => i18n._({
    id: 'columns.equip_name',
    message: `Equip`
  }),
  width: '140px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, r => r.targetName, direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const EQUIP_DETAILS = {
  key: Columns.EQUIP_DETAILS,
  label: i18n => i18n._({
    id: 'columns.equip_details',
    message: `Details`
  }),
  width: '350px',
  sortFn: (hits,
  /* @TODO */
  direction) => (0, _lodash.default)(hits, h => h.description, direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const TRIGGERED_RULES = {
  key: Columns.TRIGGERED_RULES,
  label: i18n => i18n._({
    id: 'columns.triggered_rules',
    message: `Hits`
  }),
  width: '100px',
  minWidth: '120px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, s => s.activeHits, direction),
  sortDirection: 'desc',
  disabled: false,
  hidden: false
};
const AFFECTED_EQUIPS = {
  key: Columns.AFFECTED_EQUIPS,
  label: i18n => i18n._({
    id: 'columns.affected_equips',
    message: `Equips`
  }),
  width: '100px',
  minWidth: '120px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, s => s.activeTargetCount, direction),
  sortDirection: 'desc',
  disabled: false,
  hidden: false
};
const RULE_TYPE = {
  key: Columns.RULE_TYPE,
  label: i18n => i18n._({
    id: 'columns.rule_type',
    message: `Rule Type`
  }),
  width: '120px',
  minWidth: '100px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, r => r.ruleType === '' ? '~' : r.ruleType, direction),
  sortDirection: 'asc',
  disabled: false,
  hidden: false
};
const SEVERITY = {
  key: Columns.SEVERITY,
  label: i18n => i18n._({
    id: 'columns.severity',
    message: `Severity`
  }),
  width: '110px',
  sortFn: (issues,
  /* @TODO */
  direction) => (0, _lodash.default)(issues, issue => prioritySortMap[issue.priority], direction),
  sortDirection: 'desc',
  disabled: false,
  hidden: false
};
const INSIGHT_MONITORING_SEVERITY = {
  key: Columns.SEVERITY,
  label: i18n => i18n._({
    id: 'columns.severity',
    message: `Severity`
  }),
  width: '110px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, [r => prioritySortMap[r.severity], r => r.latestUnix], direction),
  sortDirection: 'desc',
  disabled: false,
  hidden: false
};
const ACTIONS_COUNT = {
  key: Columns.ACTIONS_COUNT,
  label: i18n => i18n._({
    id: 'columns.actions_count',
    message: `Actions`
  }),
  width: '100px',
  sortFn: (summaries,
  /* @TODO */
  direction) => (0, _lodash.default)(summaries, [s => s.actionsCount, r => r.severity], direction),
  sortDirection: 'desc',
  disabled: false,
  hidden: false
};
const actionColumns = {
  ID,
  SEVERITY,
  SITE,
  SUMMARY,
  CONTEXT,
  RESPONSIBLE,
  DATES,
  STATUS,
  ESTIMATED_SAVINGS
};
exports.actionColumns = actionColumns;
const insightColumns = {
  LATEST,
  PRIORITY: MONITORING_PRIORITY,
  SITE: { ...SITE,
    width: '450px',
    sortFn: (hits,
    /* @TODO */
    direction) => (0, _lodash.default)(hits, h => h.site.label, direction)
  },
  REGION,
  RULE_NAME,
  TRIGGERED_RULES,
  AFFECTED_EQUIPS,
  RULE_TYPE,
  SEVERITY: INSIGHT_MONITORING_SEVERITY,
  ACTIONS_COUNT
};
exports.insightColumns = insightColumns;
const ruleColumns = {
  LATEST,
  SEVERITY,
  RULE_NAME,
  AFFECTED_EQUIPS: { ...AFFECTED_EQUIPS,
    width: '220px'
  },
  RULE_TYPE,
  ACTIONS_COUNT
};
exports.ruleColumns = ruleColumns;
const equipColumns = {
  LATEST: { ...LATEST,
    sortFn: (summaries,
    /* @TODO */
    direction) => (0, _lodash.default)(summaries, s => (0, _dateFns.getUnixTime)(new Date(s.occurredOn)), direction)
  },
  SEVERITY,
  EQUIP_NAME,
  EQUIP_DETAILS,
  TRIGGERED_RULES: { ...TRIGGERED_RULES,
    width: '140px',
    sortFn: (hits,
    /* @TODO */
    direction) => (0, _lodash.default)(hits, h => h.times.length, direction)
  },
  RULE_TYPE,
  ACTIONS_COUNT: { ...ACTIONS_COUNT,
    sortFn: (hits,
    /* @TODO */
    direction) => (0, _lodash.default)(hits, [h => h.issueKey == null ? '~' : h.issueKey], direction),
    sortDirection: 'asc'
  }
};
exports.equipColumns = equipColumns;
const monitoringColumns = {
  LATEST,
  PRIORITY: MONITORING_PRIORITY,
  SITE: { ...SITE,
    width: '450px',
    sortFn: (hits,
    /* @TODO */
    direction) => (0, _lodash.default)(hits, h => h.site.label, direction)
  },
  REGION,
  RULE_NAME,
  TRIGGERED_RULES,
  AFFECTED_EQUIPS,
  RULE_TYPE,
  SEVERITY: INSIGHT_MONITORING_SEVERITY,
  ACTIONS_COUNT
};
exports.monitoringColumns = monitoringColumns;
const analysisColumns = {
  monitoring: monitoringColumns,
  insights: insightColumns
};
exports.analysisColumns = analysisColumns;