"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actionNotFound = actionNotFound;
exports.addAction = addAction;
exports.addActionComplete = addActionComplete;
exports.addActionRequestChain = addActionRequestChain;
exports.uploadAttachmentRequestChain = uploadAttachmentRequestChain;

var actionTypes = _interopRequireWildcard(require("./actionTypes"));

function actionNotFound() {
  return {
    type: actionTypes.ACTION_NOT_FOUND
  };
} // Creating a new action in Jira


function addAction(action, equipList) {
  return {
    type: actionTypes.ADD_ACTION,
    action,
    equipList
  };
}

function addActionComplete() {
  return {
    type: actionTypes.ADD_ACTION_COMPLETE
  };
}

function addActionRequestChain(action) {
  return {
    types: [actionTypes.ADD_ACTION_REQUEST, actionTypes.ADD_ACTION_REQUEST_SUCCESS, actionTypes.ADD_ACTION_REQUEST_FAIL],
    promise: client => client.post('/issue', {
      data: action
    })
  };
}

function uploadAttachmentRequestChain({
  actionId,
  attachment,
  organisationId,
  userId
}) {
  return {
    types: [actionTypes.UPLOAD_HYDRA_ATTACHMENT_REQUEST, actionTypes.UPLOAD_HYDRA_ATTACHMENT_REQUEST_SUCCESS, actionTypes.UPLOAD_HYDRA_ATTACHMENT_REQUEST_FAIL],
    promise: client => {
      const data = {
        organisationId,
        filename: attachment.name,
        actionId,
        createdBy: userId
      };
      const body = new Blob([JSON.stringify(data)], {
        type: 'application/json'
      });
      return client.post('/v1/attachments', {
        fields: [{
          key: 'body',
          value: body
        }],
        attachments: [{
          key: 'file',
          value: attachment
        }]
      });
    }
  };
}