"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDisabledStatuses = useDisabledStatuses;

var _react = require("react");

var _store = require("../../../redux/store");

function useDisabledStatuses() {
  const permissions = (0, _store.useAppSelector)(state => state.auth.user.access);
  return (0, _react.useMemo)(() => {
    if ((permissions !== null && permissions !== void 0 ? permissions : []).includes('admin_statuses')) {
      return ['Closed'];
    }

    return ['Closed', 'Triage'];
  }, [permissions]);
}