"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripSitesQuery = exports.parseAssociateInsightWithActionEventPayload = exports.parseActionForAssociateEvent = exports.parseViewedActionEventPayload = exports.parseAddedCommentToActionEventPayload = exports.parseAddedActionEventPayload = exports.parseAssociateSuccessResult = exports.parseSnoozeEquipRuleEventPayload = exports.parsePostCommentEventPayload = exports.parseReportViewedEventPayload = exports.parseRuleViewedEventPayload = exports.parseDateToISOString = void 0;

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _dateFns = require("date-fns");

var _utils = require("../redux/modules/insights/utils");

var _toFixed = require("./toFixed");

/**
 * FYI: Mixpanel expects date type as ISOString
 */
const parseDateToISOString = dateInput => {
  if (dateInput === null || dateInput === undefined || dateInput === '') {
    return null;
  }

  const dateToParse = typeof dateInput === 'number' ? new Date(dateInput * 1000) : (0, _dateFns.parseISO)(dateInput);
  return dateToParse.toISOString();
};
/** Parse action to eliminate duplication of code */


exports.parseDateToISOString = parseDateToISOString;

const parseAction = action => ({
  actionKey: action.key || null,
  actionSummary: action.summary || null,
  siteName: action.components || null,
  actionPriority: action.priority || null,
  actionResponsible: action.responsible || null,
  actionContext: action.context || null,
  actionStatus: action.bonfire_status || null
});
/** Event: When user view a rule in Insights page */


const parseRuleViewedEventPayload = ruleGroup => {
  const rule = (0, _utils.composeInsights)({
    0: ruleGroup
  })[0];
  return {
    ruleId: rule.ruleId,
    ruleName: rule.ruleName,
    siteName: rule.site.label,
    severity: rule.severity,
    totalDuration: (0, _toFixed.toFixed)(rule.duration, 2),
    snoozedDuration: rule.duration - rule.activeDuration,
    activeDuration: (0, _toFixed.toFixed)(rule.activeDuration, 2),
    ruleType: rule.service,
    actionsCount: rule.actionsCount,
    totalHits: rule.hits,
    activeHits: rule.activeHits,
    snoozedHits: rule.hits - rule.activeHits,
    totalEquips: rule.targetCount,
    activeEquips: rule.activeTargetCount,
    snoozedEquips: rule.targetCount - rule.activeTargetCount,
    latest: rule.latestLabel
  };
};
/** Event: When user view a report in Insights page by clicking reportURL for SS */


exports.parseRuleViewedEventPayload = parseRuleViewedEventPayload;

const parseReportViewedEventPayload = asset => ({
  ruleId: asset.ruleId,
  ruleName: asset.ruleName,
  siteName: asset.site.label,
  projectName: asset.project,
  occurredOn: parseDateToISOString(asset.occurredOn),
  ruleType: asset.ruleType,
  equipName: asset.targetName || null,
  equipDescription: asset.description,
  numberOfHits: asset.times.length,
  totalDuration: asset.duration,
  averageDuration: asset.averageDur,
  associatedActionKey: asset.issueKey,
  isSnoozed: asset.isSnoozed,
  // NOTE: the snoozeEnd should be number type but it's a number in string
  snoozeEnd: asset.isSnoozed ? parseDateToISOString(Number(asset.snoozeEnd)) : null,
  isAssociated: asset.issueKey != null,
  reportURL: asset.reportURL
});
/** Event: When user post a comment under actions, an event triggered to track interactions */


exports.parseReportViewedEventPayload = parseReportViewedEventPayload;

const parsePostCommentEventPayload = (issue, commentResult) => {
  let equips = [];

  if (Array.isArray(issue.equipRules) && issue.equipRules.length > 0) {
    equips = issue.equipRules.map(equipRule => ({
      targetName: equipRule.target.name,
      ruleName: equipRule.rule.name,
      ruleType: equipRule.rule.type,
      severity: equipRule.rule.severity,
      hitOccurredOn: parseDateToISOString(equipRule.hit.occurredOn)
    }));
  }

  return {
    actionCreatedOn: parseDateToISOString(issue.created),
    equipRules: equips,
    comment: commentResult.result && commentResult.result.body ? commentResult.result.body : null,
    ...parseAction(issue)
  };
};
/**
 * helper method used to minimise duplicate mapping
 */


exports.parsePostCommentEventPayload = parsePostCommentEventPayload;

const parseRule = rule => ({
  ruleId: rule.id,
  ruleName: rule.name,
  ruleType: rule.type,
  severity: rule.severity
});
/** Event: When user snooze and equip rule by clicking snooze icon in Insights page */


const parseSnoozeEquipRuleEventPayload = (siteName, duration, equipRuleId, successResult) => ({
  equipName: successResult.target.name,
  snoozeEnd: parseDateToISOString(successResult.snoozeEnd),
  siteName,
  equipRuleId,
  duration,
  ...parseRule(successResult.rule)
});
/**
 * Parse function used to parse associate result for both Added Action event and associate action event
 */


exports.parseSnoozeEquipRuleEventPayload = parseSnoozeEquipRuleEventPayload;

const parseAssociateSuccessResult = successResult => successResult === null || successResult === undefined ? [] : successResult.map(equipRule => ({
  equipRuleId: equipRule.id,
  actionKey: equipRule.issueKey,
  snoozeEnd: equipRule.snoozeEnd,
  targetId: equipRule.target.id,
  targetName: equipRule.target.name,
  ...parseRule(equipRule.rule)
}));
/** Event: When user Add/Create an action, a event triggered to track interactions */


exports.parseAssociateSuccessResult = parseAssociateSuccessResult;

const parseAddedActionEventPayload = (issueKey, issue, site = null, associateResult = null, associateError = null) => ({
  actionContext: issue.issueContext,
  actionKey: issueKey,
  actionPriority: issue.priority,
  actionResponsible: issue.responsible,
  actionStatus: issue.status.value,
  actionSummary: issue.summary,
  equipRuleIds: issue.target !== '' ? issue.target : null,
  ruleName: issue.sparkType,
  siteName: site ? site.label : issue.components[0].name,
  equipRules: parseAssociateSuccessResult(associateResult),
  associateError: associateError || null
});
/** Event: When user Adds a comment to an action */


exports.parseAddedActionEventPayload = parseAddedActionEventPayload;

const parseAddedCommentToActionEventPayload = (action, comment) => ({
  actionKey: action.key,
  actionSummary: action.summary,
  actionPriority: action.priority,
  actionResponsible: action.responsible,
  actionStatus: action.status,
  equipRuleIds: action.target !== '' ? action.target : null,
  comment
});
/** Event: When user views the action modal from action list or from email link */


exports.parseAddedCommentToActionEventPayload = parseAddedCommentToActionEventPayload;

const parseViewedActionEventPayload = actionId => ({
  actionId
});

exports.parseViewedActionEventPayload = parseViewedActionEventPayload;

const parseActionForAssociateEvent = (betaIssues, successResult) => {
  const issueKeys = new Set(successResult.map(equipRule => equipRule.issueKey));
  const issueKey = [...issueKeys.values()][0];
  const issue = (betaIssues.length > 0 ? betaIssues.find(action => action.key === issueKey) : {}) || {};
  return { ...parseAction(issue)
  };
};
/** Event: When user associate an equip rule to an existing issue in Insights */


exports.parseActionForAssociateEvent = parseActionForAssociateEvent;

const parseAssociateInsightWithActionEventPayload = (betaIssues, successResult) => ({
  equipRules: parseAssociateSuccessResult(successResult),
  ...parseActionForAssociateEvent(betaIssues, successResult)
});

exports.parseAssociateInsightWithActionEventPayload = parseAssociateInsightWithActionEventPayload;

const stripSitesQuery = search => {
  const qs = _queryStringForAll.default.parse(search);

  return { ...qs,
    sites: undefined
  };
};

exports.stripSitesQuery = stripSitesQuery;