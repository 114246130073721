"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseName = exports.parseId = void 0;

// @ts-check

/**
 * Format a name into the first letter of first name + last name
 *
 * ie. Turn "Jack Cuthbert" into "J. Cuthbert"
 * @param {string} name
 */
const parseName = name => {
  const [first, last] = name.split(' ');
  return `${first.charAt(0)}. ${last}`;
};
/**
 * Parse a JIRA key into a number
 *
 * ie. Turn "DEXUS-1234" into "1234"
 * @param {string} id
 */


exports.parseName = parseName;

const parseId = (id = '') => id.split('-')[1];

exports.parseId = parseId;