"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _actionTypes = require("./actionTypes");

var _types = require("../../../components/Analysis/utils/types");

const INITIAL_STATE = {
  hit: {},
  site: {},
  priority: {},
  rule: {},
  category: {},
  siteGroups: false,
  composed: [],
  sortByOption: _types.SortBy.AlphaAsc,
  loading: false,
  ready: false
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case _actionTypes.LOAD_MONITORING:
      return { ...state,
        loading: true
      };

    case _actionTypes.LOAD_MONITORING_REQUEST_SUCCESS:
      return { ...state,
        ...action.result,
        loading: false
      };

    case _actionTypes.LOAD_MONITORING_REQUEST_FAIL:
      return { ...state,
        loading: false
      };

    case _actionTypes.UPDATE_MONITORING:
      return { ...state,
        ready: true,
        loading: false,
        siteGroups: action.siteGroups,
        composed: action.composed
      };

    case _actionTypes.UPDATE_SORT_BY_OPTION:
      return { ...state,
        sortByOption: action.sortByOption
      };

    default:
      return state;
  }
}