"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUtilitiesEffect = useUtilitiesEffect;

var React = _interopRequireWildcard(require("react"));

var _utilities = require("../../../../context/reports/utilities");

var _useActions = require("../../../../context/reports/utilities/useActions");

var _navigate = require("../../../../utils/routes/navigate");

/**
 * Handles utilities effect
 * @param metricsType
 * @param selectedSites
 * @param currentSiteId current site identifer is set on single site scene only
 */
function useUtilitiesEffect(metricsType, selectedSites, currentSiteId) {
  const [state] = (0, _utilities.useUtilities)();
  const {
    getMetrics,
    setMetricsType,
    setSelectedSites
  } = (0, _useActions.useActions)();
  /**
   * Set metrics type into state if it's been changed
   */

  React.useEffect(() => {
    setMetricsType(metricsType);
  }, [metricsType]);
  /**
   * Set selected sites into state if it's been changed
   */

  React.useEffect(() => {
    const selected = [...new Set(selectedSites)];

    if (state.selectedSites.length !== selected.length || state.selectedSites.every((value, index) => value !== selected[index])) {
      setSelectedSites(selectedSites);
    }
  }, [selectedSites]);
  /**
   * Specific effect for single site scene
   * Observe selected sites and navigate to portfolio view, but only when
   * selected sites does not include current single site id
   */

  React.useEffect(() => {
    if (currentSiteId !== undefined && !selectedSites.includes(currentSiteId)) {
      (0, _navigate.navigate)(`/reports/performance/utilities/${metricsType.toLowerCase()}`)();
    }
  }, [selectedSites, currentSiteId]);
  /**
   * Handle metrics requests
   */

  React.useEffect(() => {
    if (!state.metricsLoaded[metricsType] && !state.metricsLoading) {
      // eslint-disable-next-line no-void
      void getMetrics(metricsType, selectedSites);
    }
  }, [metricsType, state.metricsLoaded, state.metricsLoading]);
}