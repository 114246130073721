"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getClientRoutes = getClientRoutes;

var _routes = require("../../utils/routes");

function getInitials(displayName = '') {
  return displayName.split(' ').map(name => name[0]).join('');
}

function getClientRoutes(user, enableInsightsSubscriptions, apertureBaseUrl) {
  var _user$access, _user$access$includes, _user$access2, _user$access$includes2, _user$access3;

  const appLinks = [];
  const adminLinks = {
    profile: undefined,
    insightSubscriptions: undefined,
    userAdmin: undefined,
    siteSettings: undefined
  };
  const userDetails = {
    name: '',
    initials: ''
  };
  const projectSettingAccess = user.access !== undefined && user.groups !== undefined && user.rolesRestriction !== undefined && (user.rolesRestriction.includes('Administrators') || user.rolesRestriction.includes('Engineering Admins')); // UserMenu and userDetails conditions

  if (user.username !== undefined) {
    adminLinks.profile = {
      label: i18n => i18n._({
        id: 'user_menu.my_profile',
        message: `My Profile`
      }),
      href: apertureBaseUrl + '/settings/profile'
    };
    userDetails.name = user.display_name;
    userDetails.initials = getInitials(user.display_name);
  }

  if (enableInsightsSubscriptions && (_user$access = user.access) !== null && _user$access !== void 0 && _user$access.includes('viewTriagePage') && user.username !== undefined) {
    adminLinks.insightSubscriptions = {
      label: i18n => i18n._({
        id: 'user_menu.insight_subscriptions',
        message: `Insight Subscriptions`
      }),
      href: apertureBaseUrl + '/settings/insight-subscriptions'
    };
    userDetails.name = user.display_name;
    userDetails.initials = getInitials(user.display_name);
  }

  if ((_user$access$includes = (_user$access2 = user.access) === null || _user$access2 === void 0 ? void 0 : _user$access2.includes('userAdmin')) !== null && _user$access$includes !== void 0 ? _user$access$includes : false) {
    adminLinks.userAdmin = {
      label: i18n => i18n._({
        id: 'user_menu.user_settings',
        message: `User Settings`
      }),
      href: '/users'
    };
  }

  if (projectSettingAccess) {
    adminLinks.siteSettings = {
      label: i18n => i18n._({
        id: 'user_menu.project_settings',
        message: `Project Settings`
      }),
      href: _routes.SETTINGS_INSIGHTS
    };
  }

  if (user.username === undefined) {
    adminLinks.auth = {
      label: i18n => i18n._({
        id: 'user_menu.login',
        message: `Login`
      }),
      href: '/login'
    };
  } else {
    adminLinks.auth = {
      label: i18n => i18n._({
        id: 'user_menu.logout',
        message: `Logout`
      }),
      href: '/logout'
    };
  } // AppMenu Link conditions


  if (user.access !== undefined && user.groups !== undefined && user.groups.includes('Bueno')) {
    appLinks.push({
      label: i18n => i18n._({
        id: 'app_chrome.monitoring',
        message: `Monitoring`
      }),
      href: '/analysis/monitoring'
    });
  }

  if ((_user$access$includes2 = (_user$access3 = user.access) === null || _user$access3 === void 0 ? void 0 : _user$access3.includes('viewTriagePage')) !== null && _user$access$includes2 !== void 0 ? _user$access$includes2 : false) {
    appLinks.push({
      label: i18n => i18n._({
        id: 'app_chrome.insights',
        message: `Insights`
      }),
      href: '/analysis/insights'
    });
  }

  if (user.username !== undefined) {
    appLinks.push({
      label: i18n => i18n._({
        id: 'app_chrome.actions',
        message: `Actions`
      }),
      href: '/actions'
    });
  }

  return {
    userDetails,
    appLinks,
    adminLinks
  };
}