"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledWrap = exports.StyledBackgroundRow = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

var _Page = require("../Layout/Page");

const StyledBackgroundRow = _styledComponents.default.div`
  background: #fff;
  border-bottom: 1px solid ${_blocks.palette.neutral4};
`;
exports.StyledBackgroundRow = StyledBackgroundRow;
const StyledWrap = (0, _styledComponents.default)(_Page.Wrap)`
  display: flex;
  padding-top: ${_blocks.spacing.extraLoose};
  padding-bottom: ${_blocks.spacing.extraLoose};
`;
exports.StyledWrap = StyledWrap;