"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _actionTypes = require("./actionTypes");

const initialState = {
  groups_loaded: false,
  error: null,
  roles: {
    Users: 'Users',
    'Power Users': 'Power Users',
    'Deployment Engineers': 'Deployment Engineers',
    'Project Admins': 'Project Admins',
    'Engineering Admins': 'Engineering Admins',
    Administrators: 'Administrators'
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case _actionTypes.LOAD_GROUPS_SUCCESS:
      return { ...state,
        groups: action.result,
        groups_loaded: true,
        error: null
      };

    case _actionTypes.LOAD_GROUPS_FAIL:
      return { ...state,
        groups_loaded: false,
        error: action.error.message || action.error.error
      };

    default:
      return state;
  }
}