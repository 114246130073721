"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useProjectSettings = useProjectSettings;

var React = _interopRequireWildcard(require("react"));

var _config = require("../../../context/settings/config");

var _useActions = require("../../../context/settings/config/useActions");

function useProjectSettings(projectList) {
  const configState = (0, _config.useConfigState)();
  const {
    setSelectedRule,
    updateActiveProject,
    getProjectConfig
  } = (0, _useActions.useActions)();
  const initialProject = projectList[0].jira_key;
  React.useEffect(() => {
    void updateActiveProject(initialProject);
  }, [projectList]);
  const handleProjectSelect = React.useCallback(function onProjectSelect(projectKey) {
    return () => {
      void updateActiveProject(projectKey);
    };
  }, []);
  React.useEffect(() => {
    if (configState.activeProject && !configState.isLoading) {
      void getProjectConfig(configState.activeProject, configState.insightType);
    }
  }, [configState.activeProject, configState.insightType]);
  const handleRuleSelect = React.useCallback(function onRuleSelect(rule) {
    setSelectedRule(rule);
  }, []);
  return {
    handleProjectSelect,
    handleRuleSelect,
    isSaving: configState.isSaving,
    activeProject: configState.activeProject || initialProject,
    selectedRule: configState.selectedRule
  };
}