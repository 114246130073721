"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadPortfolio = loadPortfolio;
exports.logout = logout;
exports.sessionSuccess = sessionSuccess;
exports.sessionFailure = sessionFailure;

var _actionTypes = require("./actionTypes");

function loadPortfolio() {
  return {
    types: [_actionTypes.LOAD_PORTFOLIO, _actionTypes.LOAD_PORTFOLIO_SUCCESS, _actionTypes.LOAD_PORTFOLIO_FAIL],
    // TODO: The URL param here is unused in the server, but required to avoid
    // 404
    promise: client => client.get(`/user/__unused__`)
  };
}

function logout() {
  return {
    type: _actionTypes.LOGOUT
  };
}

function sessionSuccess(session) {
  return {
    type: _actionTypes.SESSION_SUCCESS,
    session
  };
}

function sessionFailure() {
  return {
    type: _actionTypes.SESSION_FAILURE
  };
}