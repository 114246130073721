"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.priorityMap = exports.statusMap = void 0;

/**
 * maps to expected values of priority prop
 * in <Chip type='priority' priority={} />
 */
const statusMap = {
  Triage: 'lowest',
  'To Be Raised MM': 'lowest',
  'New Action': 'lowest',
  'Work In Progress': 'low',
  'Scope Required': 'medium',
  'To Be Quoted': 'medium',
  'Pending PO': 'medium',
  'In Review': 'low',
  Parked: 'high',
  Closed: 'none',
  Rejected: 'none'
};
/**
 * maps to expected values of priority prop
 * in <Chip type='priority' priority={} />
 */

exports.statusMap = statusMap;
const priorityMap = {
  Blocker: 'highest',
  Critical: 'highest',
  Major: 'high',
  Moderate: 'non',
  Minor: 'none',
  Trivial: 'none'
};
exports.priorityMap = priorityMap;