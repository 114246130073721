"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _ = require("./");

function useActions() {
  const dispatch = (0, _.useDispatch)();
  /**
   * Update the view mode
   */

  const setViewMode = React.useCallback(function setViewMode(viewMode) {
    dispatch({
      type: 'SET_VIEW_MODE',
      viewMode
    });
  }, []);
  return {
    setViewMode
  };
}