"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _lodash = _interopRequireDefault(require("lodash.isequal"));

var _axios = _interopRequireDefault(require("axios"));

var _ = require(".");

function useActions() {
  const dispatch = (0, _.useConfigDispatch)();
  const updateConfigRules = React.useCallback(function updateConfigRules(rule) {
    dispatch({
      type: 'UPDATE_CONFIG_RULES',
      rule
    });
  }, []);
  const updateInsightType = React.useCallback(function updateInsightType(insightType) {
    dispatch({
      type: 'UPDATE_INSIGHT_TYPE',
      insightType
    });
  }, []);
  const updateActiveProject = React.useCallback(function updateActiveProject(activeProject) {
    dispatch({
      type: 'UPDATE_ACTIVE_PROJECT',
      activeProject
    });
  }, []);
  const setSelectedRule = React.useCallback(function setSelectedRule(selectedRule) {
    dispatch({
      type: 'SET_SELECTED_RULE',
      selectedRule
    });
  }, []);
  /**
   * Update single rule
   */

  const updateRuleConfig = React.useCallback(function updateRuleConfig(rule) {
    dispatch({
      type: 'UPDATE_RULE_CONFIG',
      rule
    });
  }, []);
  /**
   * Update multiple rules at one
   */

  const bulkUpdateRuleConfig = React.useCallback(function bulkUpdateRuleConfig(rules) {
    dispatch({
      type: 'BULK_UPDATE_RULE_CONFIG',
      rules
    });
  }, []);
  /**
   * Discard `active` state and reset `active`
   * to current `saved` state
   */

  const resetConfig = React.useCallback(function resetConfig() {
    dispatch({
      type: 'RESET_CONFIG'
    });
  }, []);
  const getProjectConfig = React.useCallback(async function getProjectConfig(projectKey, insightType) {
    dispatch({
      type: 'LOAD_CONFIG'
    });

    try {
      const request = await _axios.default.post(`/v1/project-rules/search?type=${insightType}`, {
        projectKeys: [projectKey]
      });

      if (request.status === 200) {
        const saved = Object.entries(request.data).reduce((acc, [key, val]) => {
          acc[key] = { ...val[0],
            rule: { ...val[0].rule,
              id: Number(val[0].rule.id)
            }
          };
          return acc;
        }, {});
        dispatch({
          type: 'LOAD_CONFIG_SUCCESS',
          saved
        });
      } else {
        dispatch({
          type: 'LOAD_CONFIG_FAIL'
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  const saveProjectConfig = React.useCallback(async function saveProjectConfig(projectKey, saved, active, insightType) {
    // * Sets the `isSaving` prop
    dispatch({
      type: 'SAVE_CONFIG'
    }); // * If rule exists in `saved` but doesn't in `active`
    // * add the `id` from saved to array of rules to disable

    const disable = Object.entries(saved).reduce((del, [key, val]) => {
      if (active[Number(key)] === undefined) {
        del.push(val.id);
      }

      return del;
    }, []); // * If rule doesn't match a saved rule (i.e., undefined or different properties)
    // * add rule to config payload

    const enable = Object.values(active).reduce((acc, rule) => {
      if (rule && !(0, _lodash.default)(saved[rule.rule.id], rule)) {
        acc.push({ ...rule,
          projectKey: projectKey,
          type: insightType
        });
        return acc;
      }

      return acc;
    }, []);

    try {
      const response = await _axios.default.post('/v1/project-rules/update', {
        enable,
        disable
      });

      if (response.status === 200) {
        // * Fetch the latest up to date saved config
        void getProjectConfig(projectKey, insightType);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'LOAD_CONFIG_FAIL'
      });
    }
  }, []);
  return {
    updateConfigRules,
    updateInsightType,
    updateActiveProject,
    setSelectedRule,
    updateRuleConfig,
    bulkUpdateRuleConfig,
    resetConfig,
    getProjectConfig,
    saveProjectConfig
  };
}