"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseLatestYear = parseLatestYear;

var _types = require("../../../../context/reports/utilities/types");

var _toFixed = require("../../../../utils/toFixed");

var _getMonthName = require("./getMonthName");

function getPctChangeMat(current, previous) {
  if (previous === undefined) return current;
  const cMat = current.movingAnnualTotal;
  const pMat = previous.movingAnnualTotal;
  return { ...current,
    pctChangeMAT: pMat !== undefined && cMat !== undefined && pMat !== 0 ? (0, _toFixed.toFixed)((cMat - pMat) / pMat * 100, 1) : undefined
  };
}
/**
 * Retreive latest 13 months of data from series and return
 * them in order, from least recent to most recent, across multiple years
 */


function getLast13Months(portfolio) {
  const years = Object.keys(portfolio).map(year => Number(year));
  const latest = Math.max(...years);
  const current = portfolio[latest];
  const latestYearMonths = Object.keys(current.months).length;
  const empty = {
    actual: 0,
    estimated: 0,
    total: 0,
    movingAnnualTotal: 0
  };
  const final = [];
  let year = latestYearMonths === 12 ? latest : latest - 1;
  let i = 13;
  let key = latestYearMonths;

  while (i > 0) {
    var _portfolio$year$month, _portfolio$year, _portfolio$year$month2, _portfolio$year$month3;

    final.push({
      monthInt: key,
      year,
      values: (_portfolio$year$month = (_portfolio$year = portfolio[year]) === null || _portfolio$year === void 0 ? void 0 : (_portfolio$year$month2 = _portfolio$year.months) === null || _portfolio$year$month2 === void 0 ? void 0 : (_portfolio$year$month3 = _portfolio$year$month2[key]) === null || _portfolio$year$month3 === void 0 ? void 0 : _portfolio$year$month3.values) !== null && _portfolio$year$month !== void 0 ? _portfolio$year$month : empty
    });

    if (key === 12) {
      key = 1;
      year = year + 1;
    } else {
      key += 1;
    }

    i--;
  }

  return final;
}

function parseLatestYear(portfolio, type) {
  const final = {}; // Previous month for calculating relative change, month to month

  let tail;
  const empty = {
    actual: 0,
    estimated: 0,
    total: 0,
    movingAnnualTotal: 0
  };
  const ordered = getLast13Months(portfolio);
  ordered.forEach(({
    monthInt,
    year,
    values
  }, i) => {
    var _getPctChangeMat;

    if (i === 0) {
      tail = values;
      return;
    }

    final[i] = {
      id: monthInt.toString(),
      name: i === 1 || monthInt === 1 ? `${(0, _getMonthName.getMonthName)(monthInt.toString())} ${year}` : (0, _getMonthName.getMonthName)(monthInt.toString()),
      key: i,
      unit: _types.MetricsUnit[type],
      group: `${(0, _getMonthName.getMonthName)(monthInt.toString(), false)} ${year}`,
      data: {
        [type]: (_getPctChangeMat = getPctChangeMat(values, tail)) !== null && _getPctChangeMat !== void 0 ? _getPctChangeMat : empty
      }
    };
    tail = values;
  });
  return final;
}