"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMetricsTypeFromUrl = getMetricsTypeFromUrl;

var _types = require("../../../context/reports/utilities/types");

/**
 * Retrieve metrics type from URL parameter.
 */
function getMetricsTypeFromUrl(match) {
  const requestedType = match.params.type ? match.params.type.toLowerCase() : undefined;
  const metricsType = requestedType === _types.MetricsType.Gas.toLowerCase() ? _types.MetricsType.Gas : requestedType === _types.MetricsType.Water.toLowerCase() ? _types.MetricsType.Water : _types.MetricsType.Electricity;
  return metricsType;
}