"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Controls = exports.MentionWrapper = exports.ServerError = exports.Container = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const Container = _styledComponents.default.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${_blocks.palette.neutral4};
  border-radius: ${_blocks.borderRadius.default};
`;
exports.Container = Container;
const ServerError = _styledComponents.default.p`
  display: flex;
  align-items: center;
  padding: ${_blocks.spacing.tight} 0;
  color: ${_blocks.palette.red5};
  font-size: ${_blocks.typography.body};

  svg {
    margin-right: ${_blocks.spacing.tight};
    fill: ${_blocks.palette.red5};
  }
`;
exports.ServerError = ServerError;
const MentionWrapper = _styledComponents.default.div`
  .Mentions__control {
    height: 92px;
    background-color: ${_blocks.color.pureWhite};
  }

  .Mentions__input {
    border: none;
    padding: ${_blocks.spacing.default};
    color: inherit;
    font-size: 14px !important;
    font-weight: normal;
    outline: none;
    overflow: auto !important; /* Override mentions inline 'hidden' declaration */
    &::placeholder {
      color: ${_blocks.color.theme.typography.subdued} !important;
    }
  }

  .Mentions__suggestions {
    transform: translateY(-100%);
    border: 1px solid ${_blocks.palette.neutral4};
    max-height: 400px;
    font-size: 14px;
    overflow-y: auto;
  }

  .Mentions__suggestions__item {
    padding: 2px ${_blocks.spacing.tight};
  }

  .Mentions__suggestions__item--focused {
    background: ${_blocks.palette.neutral2};
  }
`;
exports.MentionWrapper = MentionWrapper;
const Controls = _styledComponents.default.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${_blocks.palette.neutral3};
  padding: ${_blocks.spacing.tight};
  background: ${_blocks.palette.neutral1};
`;
exports.Controls = Controls;