"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dispatchGeneratorFunc = dispatchGeneratorFunc;
exports.updateListWithNewObject = exports.getUniqueList = exports.returnAction = void 0;

var _effects = require("redux-saga/effects");

function* dispatchGeneratorFunc(func, ...param) {
  // A generator that calls action creators and dispatch actions
  const action = yield func(...param);
  yield (0, _effects.put)(action);
}

const returnAction = (action, response) => ({
  type: action,
  ...response
});

exports.returnAction = returnAction;

const setToObject = set => {
  const obj = {};

  for (const item of set.values()) {
    if (item && item.length > 0) obj[item] = item;
  }

  return obj;
};

const getUniqueList = (data, property) => {
  const uniqueSet = new Set();

  for (const item of data) {
    uniqueSet.add(item[property]);
  }

  return setToObject(uniqueSet);
};

exports.getUniqueList = getUniqueList;

const updateListWithNewObject = params => {
  /** *
   * Find the object from the list by key value and replace with new object
   * @params params.list the array/list to search for the object
   * @params params.new_object the new object to replace the old object in the list
   * @params params.key the object key to use as a search criteria
   * @params params.value the value for the key defined to find from the list
   *
   * Example:
   * const params = {
   *  list : [{id: 1, name: 'Karen'}, {id: 2, name: 'Richard'}, {id: 3, name: 'Li'}, {id: 4, name: 'Sean'}],
   *  new_object : {id: 1, name: 'Steve'},
   *  key : 'id',
   *  value : 1
   * }
   *
   * updateListWithNewObject(params) should return:
   * [{id: 1, name: 'Steve'}, {id: 2, name: 'Richard'}, {id: 3, name: 'Li'}, {id: 4, name: 'Sean'}]
   */
  const index = params.list.findIndex(item => item[params.key] === params.value);
  params.list.splice(index, 1, params.new_object);
  return params.list;
};

exports.updateListWithNewObject = updateListWithNewObject;