"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveUserRequest = saveUserRequest;
exports.handleChange = exports.startViewUser = exports.handleToggleDeactivate = exports.handleSubmit = exports.addUserRequest = exports.handleClose = exports.startAddUser = exports.loadUsers = exports.startEditUser = void 0;

var _helpers = require("../helpers/helpers");

var _actionTypes = require("./actionTypes");

const startEditUser = () => ({
  type: _actionTypes.EDITING_USER
});

exports.startEditUser = startEditUser;

function saveUserRequest() {
  return {
    type: _actionTypes.SAVE_USER_REQUEST
  };
}

const loadUsers = () => ({
  types: [_actionTypes.LOAD_USERS, _actionTypes.LOAD_USERS_SUCCESS, _actionTypes.LOAD_USERS_FAIL],
  promise: client => client.get('/v2/users')
});

exports.loadUsers = loadUsers;

const startAddUser = () => (0, _helpers.returnAction)(_actionTypes.ADDING_USER);

exports.startAddUser = startAddUser;

const handleClose = () => (0, _helpers.returnAction)(_actionTypes.END_ACTION);

exports.handleClose = handleClose;

const addUserRequest = () => (0, _helpers.returnAction)(_actionTypes.ADD_USER_REQUEST);

exports.addUserRequest = addUserRequest;

const handleSubmit = func => func();

exports.handleSubmit = handleSubmit;

const handleToggleDeactivate = (userId, email, status) => {
  const action = status === 'deleted' ? 'reactivate' : 'deactivate';
  return {
    types: [_actionTypes.TOGGLE_DEACTIVATE, _actionTypes.TOGGLE_DEACTIVATE_SUCCESS, _actionTypes.TOGGLE_DEACTIVATE_FAIL],
    promise: client => client.patch(`/v2/users/${userId}/${action}`)
  };
};

exports.handleToggleDeactivate = handleToggleDeactivate;

const startViewUser = row => ({
  type: _actionTypes.VIEWING_USER,
  row
});

exports.startViewUser = startViewUser;

const handleChange = (e, inputId = null) => {
  let inputValue = '';

  if (!inputId) {
    // eslint-disable-next-line
    inputId = e.target.id;

    if (e.target.type === 'checkbox') {
      inputValue = e.target.checked;
    } else {
      inputValue = e.target.value;
    }
  } else {
    inputValue = e;
  }

  return {
    type: _actionTypes.EDITING_USER_FORM,
    input_id: inputId,
    input_value: inputValue
  };
};

exports.handleChange = handleChange;