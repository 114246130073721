"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = void 0;

const reducer = (state, action) => {
  const actionType = action.type;

  switch (action.type) {
    case 'SET_VIEW_MODE':
      return { ...state,
        viewMode: action.viewMode
      };

    default:
      throw new Error(`Unhandled action type: ${actionType}`);
  }
};

exports.reducer = reducer;