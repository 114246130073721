"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;

var _actionTypes = require("./actionTypes");

var routes = _interopRequireWildcard(require("../../../utils/routes"));

const INITIAL_HISTORY = {
  search: '',
  pathname: '',
  hash: '',
  key: '',
  path: '',
  state: ''
};
const INITIAL_STATE = {
  insightsHistory: { ...INITIAL_HISTORY,
    path: routes.INSIGHTS,
    pathname: routes.INSIGHTS
  },
  monitoringHistory: { ...INITIAL_HISTORY,
    path: routes.MONITORING,
    pathname: routes.MONITORING
  },
  actionsHistory: { ...INITIAL_HISTORY,
    path: routes.ACTIONS,
    pathname: routes.ACTIONS
  }
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case _actionTypes.UPDATE_INSIGHTS_URL_HISTORY:
      return { ...state,
        insightsHistory: action.location
      };

    case _actionTypes.UPDATE_MONITORING_URL_HISTORY:
      return { ...state,
        monitoringHistory: action.location
      };

    case _actionTypes.UPDATE_ACTIONS_URL_HISTORY:
      return { ...state,
        actionsHistory: action.location
      };

    default:
      return state;
  }
}