"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _memoizeOne = _interopRequireDefault(require("memoize-one"));

var _bowser = _interopRequireDefault(require("bowser"));

var _analyticsNext = require("@segment/analytics-next");

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable no-eq-null */
const getBrowserInfo = (0, _memoizeOne.default)(_bowser.default.parse);
/**
 * Class that wraps the Segment JS node package that handles queuing tracking
 * calls before the library has finished loading from the CDN. It also provides
 * methods that will automatically attach the necessary context properties to
 * properly operate in cloud mode.
 */

class Segment {
  constructor() {
    (0, _defineProperty2.default)(this, "warned", false);
    (0, _defineProperty2.default)(this, "_analytics", void 0);
  }

  /**
   * Escape hatch to access to the underlying analytics class.
   * Rather use the instance methods below.
   */
  get analytics() {
    if (this._analytics == null && !this.warned) {
      console.warn('AnalyticsBrowser is not defined, did you call Segment.load?');
      this.warned = true;
    }

    return this._analytics;
  }

  load(...args) {
    this._analytics = _analyticsNext.AnalyticsBrowser.load(...args);
    return this._analytics;
  }

  identify(userId, traits) {
    var _this$analytics;

    (_this$analytics = this.analytics) === null || _this$analytics === void 0 ? void 0 : _this$analytics.identify(userId, traits);
  }

  alias(to, from, options, callback) {
    var _this$analytics2;

    (_this$analytics2 = this.analytics) === null || _this$analytics2 === void 0 ? void 0 : _this$analytics2.alias(to, from, options, callback);
  }

  track(name, properties = {}, options = {}) {
    var _options$context, _options$context2, _options$context3, _this$analytics3;

    const browser = getBrowserInfo(navigator.userAgent);
    const mergedProperties = { ...properties,
      userDeviceInfo: {
        type: browser.platform.type,
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
        browserEngine: browser.engine.name,
        ...properties.userDeviceInfo
      }
    };
    const mergedOptions = { ...options,
      // Device context passed to Segment. These are usually sent
      // automatically, but we use "Cloud Mode" so we have to manually
      // add some extra properties. These will then automatically be
      // transformed to "Mixpanel Properties" by Segment.
      // see: https://segment.com/docs/connections/spec/common/#context
      context: { ...options.context,
        device: {
          model: browser.platform.model,
          manufacturer: browser.platform.vendor,
          ...((_options$context = options.context) === null || _options$context === void 0 ? void 0 : _options$context.device)
        },
        os: {
          name: browser.os.name,
          version: browser.os.version,
          ...((_options$context2 = options.context) === null || _options$context2 === void 0 ? void 0 : _options$context2.os)
        },
        screen: {
          width: screen.width,
          height: screen.height,
          ...((_options$context3 = options.context) === null || _options$context3 === void 0 ? void 0 : _options$context3.screen)
        }
      }
    };
    (_this$analytics3 = this.analytics) === null || _this$analytics3 === void 0 ? void 0 : _this$analytics3.track(name, mergedProperties, mergedOptions);
  }

  reset() {
    var _this$analytics4;

    (_this$analytics4 = this.analytics) === null || _this$analytics4 === void 0 ? void 0 : _this$analytics4.reset();
  }

}

var _default = new Segment();

exports.default = _default;
module.exports = exports.default;