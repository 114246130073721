"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calcSelectAllConfig = calcSelectAllConfig;
exports.calcRemoveAllConfig = calcRemoveAllConfig;

function calcSelectAllConfig(ruleList, configState) {
  const active = ruleList.reduce((acc, rule) => {
    const existing = configState.active[rule.rule.id] || configState.saved[rule.rule.id];

    if (existing) {
      const updated = {
        id: existing.id,
        rule: existing.rule,
        priority: existing.priority
      };
      acc[rule.rule.id] = updated;
      return acc;
    }

    acc[rule.rule.id] = {
      id: rule.id,
      rule: rule.rule,
      priority: 1
    };
    return acc;
  }, { ...configState.active
  });
  return active;
}

function calcRemoveAllConfig(ruleList, configState) {
  const active = { ...configState.active
  };
  ruleList.forEach(rule => {
    if (active[rule.rule.id]) {
      delete active[rule.rule.id];
    }
  });
  return active;
}