"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.handleCreateAndAssociate = handleCreateAndAssociate;
exports.watchCreateAndAssociate = watchCreateAndAssociate;
exports.createActionRequestChain = exports.createAction = exports.CREATE_ACTION_REQUEST_FAILURE = exports.CREATE_ACTION_REQUEST_SUCCESS = exports.CREATE_ACTION_REQUEST = void 0;

var _effects = require("redux-saga/effects");

var _associate = require("./associate");

var _eventTracking = require("../../../utils/eventTracking");

// ! TODO: Replace this with context.
const INITIAL_STATE = {
  isCreating: false
};
const CREATE_ACTION = 'bonfire/issues/CREATE_ACTION';
const CREATE_ACTION_REQUEST = 'bonfire/issues/CREATE_ACTION_REQUEST';
exports.CREATE_ACTION_REQUEST = CREATE_ACTION_REQUEST;
const CREATE_ACTION_REQUEST_SUCCESS = 'bonfire/issues/CREATE_ACTION_REQUEST_SUCCESS';
exports.CREATE_ACTION_REQUEST_SUCCESS = CREATE_ACTION_REQUEST_SUCCESS;
const CREATE_ACTION_REQUEST_FAILURE = 'bonfire/issues/CREATE_ACTION_REQUEST_FAIL';
exports.CREATE_ACTION_REQUEST_FAILURE = CREATE_ACTION_REQUEST_FAILURE;

const createAction = issue => ({
  type: CREATE_ACTION,
  issue
});

exports.createAction = createAction;

const createActionRequestChain = issue => ({
  types: [CREATE_ACTION_REQUEST, CREATE_ACTION_REQUEST_SUCCESS, CREATE_ACTION_REQUEST_FAILURE],
  promise: client => client.post('/issue', {
    data: { ...issue
    }
  })
});

exports.createActionRequestChain = createActionRequestChain;

function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case CREATE_ACTION_REQUEST:
      return { ...state,
        isCreating: true
      };

    case CREATE_ACTION_REQUEST_SUCCESS:
      return { ...state,
        isCreating: false
      };

    case CREATE_ACTION_REQUEST_FAILURE:
      return { ...state,
        isCreating: false
      };

    default:
      return state;
  }
} // TODO: This should probably live in the `associate.js` sagas


function* handleCreateAndAssociate(action) {
  try {
    const {
      projects
    } = yield (0, _effects.select)(state => state.userSites);
    const site = yield (0, _effects.select)(state => state.betaSelections.activeSite);
    const projectKey = projects[site.projectId].jira_key;
    const {
      target
    } = action.issue;
    const issue = { ...action.issue,
      projectKey,
      components: [{
        name: site.id.split('~')[1]
      }],
      target: '',
      issueType: 'Spark',
      transition: action.issue.status,
      labels: ['bonfire-created', 'user-created']
    };
    yield (0, _effects.put)(createActionRequestChain(issue));
    const {
      success,
      failure
    } = yield (0, _effects.race)({
      success: (0, _effects.take)(CREATE_ACTION_REQUEST_SUCCESS),
      failure: (0, _effects.take)(CREATE_ACTION_REQUEST_FAILURE)
    });
    if (failure) throw new Error(`Error creating issue: ${failure.error.message}`);

    if (success) {
      // Associate equip/rules to newly created issue
      const {
        issueKey
      } = success.result;
      yield (0, _effects.put)((0, _associate.associateEquipRule)({
        equipRuleIds: target,
        issueKey
      }));
      const {
        associateSuccess,
        associateFailure
      } = yield (0, _effects.race)({
        associateSuccess: (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
        associateFailure: (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_FAIL)
      });
      const associateResult = associateSuccess ? associateSuccess.result : null;
      const associateError = associateFailure ? 'Failed to associate' : null;
      (0, _eventTracking.addedActionEvent)(success.result.issueKey, action.issue, site, associateResult, associateError);
    }
  } catch (err) {
    yield (0, _effects.put)({
      type: CREATE_ACTION_REQUEST_FAILURE,
      error: err
    });
  }
}

function* watchCreateAndAssociate() {
  yield (0, _effects.takeLatest)(CREATE_ACTION, handleCreateAndAssociate);
}