"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var _axios = _interopRequireDefault(require("axios"));

var _ = require("./");

var _eventTracking = require("../../../../utils/eventTracking");

function useActions() {
  const dispatch = (0, _.useDispatch)();

  function showModal(actionId, userDisplayName) {
    dispatch({
      type: 'SHOW_ACTION_MODAL',
      actionId,
      userDisplayName
    });
    (0, _eventTracking.viewedActionEvent)(actionId);
  }

  function hideModal(cb) {
    dispatch({
      type: 'HIDE_ACTION_MODAL'
    });

    if (cb !== undefined) {
      cb();
    }
  }

  async function loadAction(actionId) {
    dispatch({
      type: 'LOAD_ACTION',
      actionId
    });

    try {
      const endpoint = `/issue/${actionId}`;
      const response = await _axios.default.get(endpoint);
      dispatch({
        type: 'SET_ACTION',
        action: response.data
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        err
      });
    }
  }

  function setAction(action) {
    dispatch({
      type: 'SET_ACTION',
      action
    });
  }

  function setUserDisplayName(userDisplayName) {
    dispatch({
      type: 'SET_USER_DISPLAY_NAME',
      userDisplayName
    });
  }

  async function loadMessages(actionId) {
    dispatch({
      type: 'LOAD_MESSAGES',
      actionId
    });

    try {
      const endpoint = `/comments/${actionId}`;
      const response = await _axios.default.get(endpoint);
      dispatch({
        type: 'SET_MESSAGES',
        messages: response.data
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        err
      });
    }
  }

  async function loadMentionsList(projectId, jiraComponent) {
    dispatch({
      type: 'LOAD_MENTIONS_LIST'
    });

    try {
      const endpoint = `/hashtagUsernamelist/${projectId}/${jiraComponent}`;
      const response = await _axios.default.get(endpoint);
      dispatch({
        type: 'SET_MENTIONS_LIST',
        mentionsList: response.data
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        err
      });
    }
  }

  function setAddMessageStatus(status) {
    dispatch({
      type: 'SET_ADD_MESSAGE_STATUS',
      status
    });
  }

  function clearAction() {
    dispatch({
      type: 'CLEAR_ACTION'
    });
  }

  return {
    showModal,
    hideModal,
    loadAction,
    setAction,
    setUserDisplayName,
    loadMessages,
    loadMentionsList,
    setAddMessageStatus,
    clearAction
  };
}