"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateActionsUrlHistory = exports.updateMonitoringUrlHistory = exports.updateInsightsUrlHistory = void 0;

var _actionTypes = require("./actionTypes");

function generateAction(actionType) {
  return (hist, updateType) => {
    const location = { ...hist,
      path: `${hist.pathname}${hist.search}`
    };
    return {
      type: actionType,
      location,
      updateType
    };
  };
}

const updateInsightsUrlHistory = generateAction(_actionTypes.UPDATE_INSIGHTS_URL_HISTORY);
exports.updateInsightsUrlHistory = updateInsightsUrlHistory;
const updateMonitoringUrlHistory = generateAction(_actionTypes.UPDATE_MONITORING_URL_HISTORY);
exports.updateMonitoringUrlHistory = updateMonitoringUrlHistory;
const updateActionsUrlHistory = generateAction(_actionTypes.UPDATE_ACTIONS_URL_HISTORY);
exports.updateActionsUrlHistory = updateActionsUrlHistory;