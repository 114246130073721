"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.serialiseCreateAction = serialiseCreateAction;

function valuePair(label) {
  return {
    label,
    value: label
  };
}
/**
 * Create an issue object the create API is able to consume. Eventually with a new API
 * the need for this should go away
 */


function serialiseCreateAction(actionForm) {
  const [projectKey, siteName] = actionForm.site.split('~'); // TODO: This is the payload consumed by Portico, this should be revisted soon

  const action = {
    projectKey,
    components: [{
      name: siteName
    }],
    summary: actionForm.summary,
    description: actionForm.description,
    target: '',
    issueType: 'Spark',
    sparkType: actionForm.rule,
    priority: actionForm.priority,
    transition: actionForm.status,
    status: valuePair(actionForm.status),
    responsible: actionForm.responsible,
    labels: ['bonfire-created', 'user-created'],
    issueContext: actionForm.context,
    assignee: actionForm.assignee,
    siteId: actionForm.siteId,
    assigneeId: actionForm.assigneeId
  }; // Conditionally send optional fields

  if (actionForm.skysparkLink !== '') {
    action.reportUrl = actionForm.skysparkLink;
  }

  if (actionForm.elec !== 0) {
    action.electricity = actionForm.elec;
  }

  if (actionForm.thermal !== 0) {
    action.thermal = actionForm.thermal;
  }

  if (actionForm.gas !== 0) {
    action.gas = actionForm.gas;
  }

  if (actionForm.water !== 0) {
    action.water = actionForm.water;
  }

  if (actionForm.totalFinancialImpact !== 0) {
    action.totalSavings = actionForm.totalFinancialImpact;
  }

  if (actionForm.operationalSavings !== 0) {
    action.operationalSavings = actionForm.operationalSavings;
  }

  return action;
}