"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.unassociateEquipRuleFlow = unassociateEquipRuleFlow;
exports.unassociateEquipRuleWatcher = unassociateEquipRuleWatcher;
exports.deleteAssociateEquipRule = exports.unassociateEquipRule = exports.UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL = exports.UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = exports.UNASSOCIATE_RULE_EQUIP_REQUEST = exports.UNASSOCIATE_RULE_EQUIP = void 0;

var _effects = require("redux-saga/effects");

var _helpers = require("../helpers/helpers");

var _requestSchedule = require("./requestSchedule");

const INITIAL_STATE = {
  pending: true,
  complete: false,
  error: undefined
};
const UNASSOCIATE_RULE_EQUIP = 'bonfire/_BETA/triage/UNASSOCIATE_RULE_EQUIP';
exports.UNASSOCIATE_RULE_EQUIP = UNASSOCIATE_RULE_EQUIP;
const UNASSOCIATE_RULE_EQUIP_REQUEST = 'bonfire/_BETA/triage/UNASSOCIATE_RULE_EQUIP_REQUEST';
exports.UNASSOCIATE_RULE_EQUIP_REQUEST = UNASSOCIATE_RULE_EQUIP_REQUEST;
const UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = 'bonfire/_BETA/triage/UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS';
exports.UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS = UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS;
const UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL = 'bonfire/_BETA/triage/UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL';
exports.UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL = UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL;

function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UNASSOCIATE_RULE_EQUIP_REQUEST:
      return { ...state,
        pending: true,
        complete: false,
        error: undefined
      };

    case UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS:
      return { ...state,
        pending: false,
        complete: true,
        error: undefined
      };

    case UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL:
      return { ...state,
        pending: false,
        complete: true,
        error: action.error.message
      };

    default:
      {
        return state;
      }
  }
}

const unassociateEquipRule = ({
  equipRuleIds,
  actionId
}) => ({
  type: UNASSOCIATE_RULE_EQUIP,
  equipRuleIds,
  actionId
});

exports.unassociateEquipRule = unassociateEquipRule;

const deleteAssociateEquipRule = ({
  equipRuleIds = [],
  actionId
}) => ({
  types: [UNASSOCIATE_RULE_EQUIP_REQUEST, UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS, UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL],
  promise: client => client.post(`/v2/actions/${actionId}/dissociate`, {
    data: {
      equipRuleIds
    }
  })
});

exports.deleteAssociateEquipRule = deleteAssociateEquipRule;

const getAuthUser = state => state.auth.user;

function* unassociateEquipRuleFlow(equipRuleIds, actionId) {
  try {
    const user = yield (0, _effects.select)(getAuthUser); // Check that user has necessary permissions to make request

    if (!user.rolesRestriction) throw Error('Unable to authenticate user'); // validate that at least one equipRuleId has been given

    if (equipRuleIds.length < 1) throw Error('No equip rules given');
    yield (0, _effects.fork)(_helpers.dispatchGeneratorFunc, deleteAssociateEquipRule, {
      equipRuleIds,
      actionId
    });
    const {
      success,
      failure
    } = yield (0, _effects.race)({
      success: (0, _effects.take)(UNASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
      failure: (0, _effects.take)(UNASSOCIATE_RULE_EQUIP_REQUEST_FAIL)
    });

    if (failure) {
      throw new Error('Failed to unassociate');
    }

    if (success) {
      yield (0, _effects.put)((0, _requestSchedule.setShouldRequestInsights)(true));
      yield (0, _effects.put)((0, _requestSchedule.setShouldRequestMonitoring)(true));
    }
  } catch (err) {
    console.error(`Something went wrong attempting to associate equip rule: ${err}`);
  }
}

function* unassociateEquipRuleWatcher() {
  while (true) {
    const {
      equipRuleIds,
      actionId
    } = yield (0, _effects.take)(UNASSOCIATE_RULE_EQUIP);
    yield (0, _effects.fork)(unassociateEquipRuleFlow, equipRuleIds, actionId);
  }
}