"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseSites = parseSites;

var _utils = require("@bueno/blocks/lib/charts/utils");

var _types = require("../../../../context/reports/utilities/types");

var _state = require("../../../../utils/state");

/**
 * calculate sum of all complete months, relative to the current year
 */
function sumMonthsYTD(months) {
  const currentMonth = new Date().getMonth() + 1;
  return Object.entries(months).reduce((ytd, [monthInt, month]) => {
    if (Number(monthInt) <= currentMonth) {
      ytd.actual += Number(month.values.actual);
      ytd.estimated += Number(month.values.estimated);
      ytd.total += Number(month.values.actual + month.values.estimated); // TODO: Change back to v.total once utilities api is fixed.
    }

    return ytd;
  }, {
    actual: 0,
    estimated: 0,
    total: 0
  });
}

function parseSites(data, type) {
  const {
    sites,
    portfolio
  } = data;
  const final = {};
  const siteIds = Object.keys(sites);
  const years = Object.keys(portfolio);
  siteIds.forEach(id => {
    years.forEach(year => {
      var _getSiteById$label, _getSiteById;

      const current = sites[id][year];
      const previous = sites[id][Number(year) - 1];
      if (current === undefined) return;
      const currentValues = sumMonthsYTD(current.months);
      const previousValues = previous !== undefined ? sumMonthsYTD(previous.months) : undefined;
      const pctChange = previousValues !== undefined ? (0, _utils.toFixed)((currentValues.total - previousValues.total) / previousValues.total * 100, 1) : undefined;
      const name = (_getSiteById$label = (_getSiteById = (0, _state.getSiteById)(id)) === null || _getSiteById === void 0 ? void 0 : _getSiteById.label) !== null && _getSiteById$label !== void 0 ? _getSiteById$label : '';

      if (final[id] === undefined) {
        final[id] = {
          id,
          name,
          group: id,
          key: id,
          unit: _types.MetricsUnit[type],
          data: {
            [year]: { ...currentValues,
              pctChange
            }
          }
        };
      } else {
        final[id].data = { ...final[id].data,
          [year]: { ...currentValues,
            pctChange
          }
        };
      }
    });
  });
  return final;
}