"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChatIcon = exports.EmptyActivityTip = exports.EmptyActivityTitle = exports.EmptyActivityWrapper = exports.Controls = exports.Title = exports.ScrollFadeCSS = exports.Feed = exports.Wrapper = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _blocks = require("@bueno/blocks");

var _ActionModal = require("../../ActionModal.styles");

const Wrapper = _styledComponents.default.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 0;
  overflow: hidden;
`;
exports.Wrapper = Wrapper;
const Feed = _styledComponents.default.div`
  flex-grow: 1;
  overflow: auto;
  padding: ${_blocks.spacing.default} ${_blocks.spacing.loose} 40px;
`;
exports.Feed = Feed;
const ScrollFadeCSS = (0, _styledComponents.css)`
  content: '';
  display: block;
  position: absolute;
  right: ${_blocks.spacing.default};
  left: ${_blocks.spacing.default};
  height: 40px;
`;
exports.ScrollFadeCSS = ScrollFadeCSS;
const Title = (0, _styledComponents.default)(_ActionModal.ActionTitle)`
  position: relative;
  padding: ${_blocks.spacing.loose} ${_blocks.spacing.loose} 0;
  &:after {
    ${ScrollFadeCSS};
    height: 20px;
    bottom: 0;
    transform: translate(0, 100%);
    background: linear-gradient(${_blocks.palette.neutral1} 25%, transparent);
  }
`;
exports.Title = Title;
const Controls = _styledComponents.default.div`
  position: relative;
  padding: 0 ${_blocks.spacing.loose} ${_blocks.spacing.loose};

  &:before {
    ${ScrollFadeCSS};
    top: 0;
    transform: translate(0, -100%);
    background: linear-gradient(transparent 25%, ${_blocks.palette.neutral1});
  }
`;
exports.Controls = Controls;
const EmptyActivityWrapper = _styledComponents.default.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-right: ${_blocks.spacing.ultraLoose};
  padding-left: ${_blocks.spacing.ultraLoose};
  height: 100%;
`;
exports.EmptyActivityWrapper = EmptyActivityWrapper;
const EmptyActivityTitle = (0, _styledComponents.default)(_blocks.DisplaySmall)`
  color: ${_blocks.color.theme.typography.subdued};
  font-weight: ${_blocks.fontWeight.bold};
  line-height: 1.29;

  margin-bottom: ${_blocks.spacing.default};
`;
exports.EmptyActivityTitle = EmptyActivityTitle;
const EmptyActivityTip = (0, _styledComponents.default)(_blocks.Body)`
  color: ${_blocks.color.theme.typography.subdued};
  line-height: 1.28;
`;
exports.EmptyActivityTip = EmptyActivityTip;
const ChatIcon = (0, _styledComponents.default)(_blocks.Chat)`
  margin-bottom: ${_blocks.spacing.tight};
  fill: ${_blocks.color.theme.typography.subtle};
`;
exports.ChatIcon = ChatIcon;