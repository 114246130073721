"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMonitoring = updateMonitoring;
exports.loadMonitoring = loadMonitoring;
exports.loadMonitoringRequest = exports.updateSortByOption = void 0;

var _dateFns = require("date-fns");

var _actionTypes = require("./actionTypes");

function updateMonitoring({
  siteGroups,
  composed
}) {
  /* FIXME: argument type */
  return {
    type: _actionTypes.UPDATE_MONITORING,
    siteGroups,
    composed
  };
}

const updateSortByOption = sortByOption => ({
  type: _actionTypes.UPDATE_SORT_BY_OPTION,
  sortByOption
});

exports.updateSortByOption = updateSortByOption;

function loadMonitoring() {
  return {
    type: _actionTypes.LOAD_MONITORING
  };
} // @FIXME: returning `any` as Saga `put` method does not support our async action.


const loadMonitoringRequest = ({
  siteIds,
  daysRange = 3
}) => {
  const today = (0, _dateFns.format)(new Date(), 'yyyy-MM-dd');
  const range = (0, _dateFns.format)((0, _dateFns.sub)(new Date(), {
    days: daysRange
  }), 'yyyy-MM-dd');
  return {
    types: [_actionTypes.LOAD_MONITORING_REQUEST, _actionTypes.LOAD_MONITORING_REQUEST_SUCCESS, _actionTypes.LOAD_MONITORING_REQUEST_FAIL],
    promise: client => client.post('/synco/ui/insights', {
      params: {
        from: range,
        to: today
      },
      data: {
        sites: siteIds,
        insightType: 'monitoring'
      }
    })
  };
};

exports.loadMonitoringRequest = loadMonitoringRequest;