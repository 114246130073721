"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = void 0;

/** @deprecated */
let Status; // Types

/** @deprecated */

exports.Status = Status;

(function (Status) {
  Status[Status["idle"] = 0] = "idle";
  Status[Status["loading"] = 1] = "loading";
  Status[Status["ready"] = 2] = "ready";
  Status[Status["complete"] = 3] = "complete";
  Status[Status["error"] = 4] = "error";
})(Status || (exports.Status = Status = {}));