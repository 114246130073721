"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUniqIssues = getUniqIssues;
exports.getEveryDefinedIssue = getEveryDefinedIssue;
exports.sumRawHits = sumRawHits;
exports.sumHits = sumHits;
exports.getUniqEquip = getUniqEquip;
exports.getTicketCount = getTicketCount;
exports.composeInsights = composeInsights;
exports.composeOccurrences = composeOccurrences;
exports.groupByRule = groupByRule;
exports.groupBySite = groupBySite;
exports.createSiteGroups = createSiteGroups;
exports.SORT_BY_OPTIONS = exports.GROUP_BY_OPTIONS = void 0;

var _lodash = _interopRequireDefault(require("lodash.groupby"));

var _lodash2 = _interopRequireDefault(require("lodash.orderby"));

var _time = require("../../../utils/time");

var _sorting = require("../../../utils/sorting");

/**
 * @param {array} rules array of rule objects
 * @returns {array} array of unique issue ID strings
 */
function getUniqIssues(rules) {
  const issues = rules.map(rule => rule.issueKey);
  return (0, _sorting.uniq)(issues);
}
/**
 * @param {array} rules array of rule objects
 * @returns {array} array of every issue ID string, which could be duplicated
 */


function getEveryDefinedIssue(rules) {
  const issueKeys = rules.reduce((keys, rule) => {
    if (rule.issueKey != null) {
      keys.push(rule.issueKey);
    }

    return keys;
  }, []);
  return issueKeys;
}
/**
 *
 * @param {array} rules array of string float values
 * @returns {number} sum of all rule durations, to 2 decimal places
 */


function sumDuration(rules = []) {
  const sum = rules.reduce((total, rule) => total + rule.duration, 0);
  return sum;
}
/**
 * @param {array} rules Array of occurrence objects
 * @returns {number} sum of all times a rule has been triggered within the 24 hour period
 */


function sumRawHits(rules = []) {
  return rules.reduce((hits, rule) => hits + rule.times.length, 0);
}

function sumHits(rules = []) {
  return rules.reduce((hits, rule) => hits + rule.activeHits, 0);
}

function getUniqEquip(composedRules) {
  const targetIds = composedRules.reduce((flat, rule) => {
    flat.push(...rule.targets);
    return flat;
  }, []).map(target => target.targetId);
  return targetIds;
}

function getGroupEquipCount(composedRules) {
  return composedRules.reduce((total, rule) => total + rule.targetCount, 0);
}

function getGroupUnassociatedEquipCount(composedRules) {
  return composedRules.reduce((total, rule) => total + (rule.targetCount - rule.actionsCount), 0);
}

function getTicketCount(composedRules) {
  return composedRules.map(rule => rule.actionsCount).reduce((sum, count) => sum + count, 0);
}
/**
 * @param {array} groupedInsights array of grouped insights
 * @param {boolean} uniqActionCount whether we want to return unique actions counts or not
 * @returns {array} array of componsed insights
 */


function composeInsights(groupedInsights, uniqActionCount) {
  return Object.entries(groupedInsights).map(([id, insight]) => {
    var _instance$ruleType, _instance$ruleType2, _instance$latestLabel;

    const targets = insight;
    const activeTargets = insight.filter(({
      isSnoozed
    }) => !isSnoozed);
    const issues = uniqActionCount === true ? getUniqIssues(targets) : getEveryDefinedIssue(targets);
    const activeIssues = uniqActionCount === true ? getUniqIssues(activeTargets) : getEveryDefinedIssue(activeTargets);
    const instance = insight.sort((a, b) => new Date(b.occurredOn).getTime() - new Date(a.occurredOn).getTime())[0];
    const {
      ruleName,
      severity,
      ruleType
    } = instance;
    const latest = instance.occurredOn;
    const latestUnix = new Date(instance.occurredOn).setHours(0, 0, 0, 0) / 1000;
    const hits = sumRawHits(targets);
    const activeHits = sumRawHits(activeTargets);
    const service = (_instance$ruleType = instance.ruleType) !== null && _instance$ruleType !== void 0 ? _instance$ruleType : '';
    const rawDuration = sumDuration(targets);
    const activeRawDuration = sumDuration(activeTargets);
    const duration = rawDuration;
    const activeDuration = activeRawDuration;
    const averageDur = rawDuration / hits;
    const activeAverageDur = activeRawDuration / hits;
    const composed = {
      id,
      site: instance.site,
      project: instance.project,
      targets,
      activeTargets,
      targetCount: targets.length,
      activeTargetCount: activeTargets.length,
      isAllSnoozed: activeTargets.length === 0,
      ruleName,
      ruleType,
      ruleId: instance.ruleId,
      latest,
      latestUnix,
      latestLabel: instance.latestLabel,
      duration,
      // hours
      activeDuration,
      // hours
      averageDur,
      // hours
      activeAverageDur,
      // hours
      hits,
      activeHits,
      service,
      severity,
      issues,
      actionsCount: issues.length,
      activeActionsCount: activeIssues.length,
      priority: instance.priority
    };
    return { ...composed,
      valueString: `${ruleName !== null && ruleName !== void 0 ? ruleName : ''} ${(_instance$ruleType2 = instance.ruleType) !== null && _instance$ruleType2 !== void 0 ? _instance$ruleType2 : ''} ${(_instance$latestLabel = instance.latestLabel) !== null && _instance$latestLabel !== void 0 ? _instance$latestLabel : ''} ${severity !== null && severity !== void 0 ? severity : ''}`
    };
  });
}

function timesToHours(times) {
  return times.reduce((seconds, time) => seconds + time.d, 0) / 60 / 60;
}

function composeOccurrences(occurrences, sites, projects) {
  // @ts-expect-error
  const result = occurrences.map(o => {
    const {
      siteId,
      reportUrl,
      skysparkDomain,
      severity,
      targetName,
      targetId,
      occurredOn,
      issueKey,
      description,
      ruleType,
      ruleName,
      snoozeEnd,
      ...rest
    } = o;
    const times = o.times;
    const duration = timesToHours(times); // hours

    const latestLabel = (0, _time.todayYesterday)(occurredOn);
    const averageDur = duration / times.length; // hours

    const nowUnix = new Date().getTime() / 1000;
    const isSnoozed = snoozeEnd !== null && snoozeEnd !== undefined ? parseInt(snoozeEnd, 10) > nowUnix : false;
    const site = sites[siteId];
    const project = projects[site.projectId].project_name;
    return { ...rest,
      site,
      project,
      siteId,
      timezone: site.timezone,
      snoozeEnd,
      isSnoozed,
      severity,
      ruleType,
      ruleName,
      targetName,
      targetId,
      skysparkId: '',
      reportURL: `${skysparkDomain}${reportUrl}`,
      duration,
      description: typeof description === 'string' ? description : '',
      latestLabel,
      occurredOn,
      times,
      averageDur,
      issueKey,
      targetValueString: `${targetName !== null && targetName !== void 0 ? targetName : ''} ${site.label} ${site.state} ${project} ${severity !== null && severity !== void 0 ? severity : ''} ${targetId} ${issueKey !== null && issueKey !== void 0 ? issueKey : ''} ${description !== null && description !== void 0 ? description : ''} ${ruleType !== null && ruleType !== void 0 ? ruleType : ''} ${ruleName}`
    };
  });
  return result;
}

function groupByRule(insights = []) {
  if (insights.length < 1) return [];
  const ruleGroups = (0, _lodash.default)(insights, o => o.ruleId);
  return Object.values(ruleGroups).map(siteInsights => {
    const groupedInsights = (0, _lodash.default)(siteInsights, o => o.siteId);
    return composeInsights(groupedInsights);
  });
}

function groupBySite(insights = []) {
  if (insights.length < 1) return [];
  const ruleGroups = (0, _lodash.default)(insights, o => o.siteId);
  return Object.values(ruleGroups).map(siteInsights => {
    const groupedInsights = (0, _lodash.default)(siteInsights, o => o.ruleId);
    return composeInsights(groupedInsights);
  });
}

function createSiteGroups( // eslint-disable-next-line @typescript-eslint/default-param-last
insights = [], sites, projects) {
  if (insights.length < 1) return {};
  const siteGroups = (0, _lodash.default)(insights, o => o.siteId);
  const result = Object.entries(siteGroups).reduce((combined, [key, siteInsights]) => {
    const instance = siteInsights[0];
    const site = sites[instance.site.syncoId];
    const project = projects[site.projectId].project_name;
    const ruleGroups = (0, _lodash.default)(siteInsights, o => o.ruleId);
    const composed = {
      site,
      project,
      ruleGroups,
      insights: composeInsights(ruleGroups)
    };
    combined[key] = composed;
    return combined;
  }, {});
  return result;
}

const GROUP_BY_OPTIONS = {
  RULE: {
    label: 'Group by Rule',
    sub: 'Rule',
    key: 'RULE',
    value: 'rule',
    groupFunc: groupByRule
  },
  SITE: {
    label: 'Group by Site',
    sub: 'Site',
    key: 'SITE',
    value: 'site',
    groupFunc: groupBySite
  }
};
exports.GROUP_BY_OPTIONS = GROUP_BY_OPTIONS;
const SORT_BY_OPTIONS = {
  HITS: {
    label: 'Number of Hits',
    sub: 'Hits',
    key: 'HITS',
    value: 'Hits',
    sortFn: groups => {
      return (0, _lodash2.default)(groups, g => sumHits(g), 'desc');
    }
  },
  EQUIPS: {
    label: 'Number of Equips',
    value: 'Equips',
    sub: 'Equips',
    key: 'EQUIPS',
    sortFn: groups => (0, _lodash2.default)(groups, g => getGroupEquipCount(g), 'desc')
  },
  UNASSOCIATED_EQUIPS: {
    label: 'Number of Unassociated Equips',
    value: 'Unassociated Equips',
    sub: 'Equips',
    key: 'UNASSOCIATED_EQUIPS',
    sortFn: groups => (0, _lodash2.default)(groups, g => getGroupUnassociatedEquipCount(g), 'desc')
  },
  INSIGHTS_DESC: {
    label: 'Insight (High-Low)',
    sub: 'Insights',
    value: 'Insights Desc',
    key: 'INSIGHTS_DESC',
    sortFn: (groups, groupByOption) => {
      if (groupByOption === GROUP_BY_OPTIONS.RULE.key) {
        return (0, _lodash2.default)(groups, g => g.length, 'desc');
      }

      if (groupByOption === GROUP_BY_OPTIONS.SITE.key) {
        return (0, _lodash2.default)(groups, g => g.length, 'desc');
      }

      return groups;
    }
  },
  INSIGHTS_ASC: {
    label: 'Insight (Low-High)',
    sub: 'Insights',
    value: 'Insights ASC',
    key: 'INSIGHTS_ASC',
    sortFn: (groups, groupByOption) => {
      if (groupByOption === GROUP_BY_OPTIONS.RULE.key) {
        return (0, _lodash2.default)(groups, g => g.length, 'asc');
      }

      if (groupByOption === GROUP_BY_OPTIONS.SITE.key) {
        return (0, _lodash2.default)(groups, g => g.length, 'asc');
      }

      return groups;
    }
  },
  A_Z: {
    label: 'Alphabetical (A-Z)',
    sub: 'A-Z',
    value: 'A-Z',
    key: 'A_Z',
    sortFn: (groups, groupByOption) => {
      if (groupByOption === GROUP_BY_OPTIONS.RULE.key) {
        return (0, _lodash2.default)(groups, g => `${g[0].ruleName}`.toLowerCase(), 'asc');
      }

      if (groupByOption === GROUP_BY_OPTIONS.SITE.key) {
        return (0, _lodash2.default)(groups, g => `${g[0].site.label}`.toLowerCase(), 'asc');
      }

      return groups;
    }
  },
  Z_A: {
    label: 'Alphabetical (Z-A)',
    sub: 'Z-A',
    value: 'Z-A',
    key: 'Z_A',
    sortFn: (groups, groupByOption) => {
      if (groupByOption === GROUP_BY_OPTIONS.RULE.key) {
        return (0, _lodash2.default)(groups, g => `${g[0].ruleName}`.toLowerCase(), 'desc');
      }

      if (groupByOption === GROUP_BY_OPTIONS.SITE.key) {
        return (0, _lodash2.default)(groups, g => `${g[0].site.label}`.toLowerCase(), 'desc');
      }

      return groups;
    }
  }
};
exports.SORT_BY_OPTIONS = SORT_BY_OPTIONS;