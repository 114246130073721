"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.handleDefaultPriority = handleDefaultPriority;

var _routes = require("../../../utils/routes");

function handleDefaultPriority(rule, insightType) {
  const updated = { ...rule
  };

  if (updated.filterable !== undefined) {
    delete updated.filterable;
  }

  if (insightType === _routes.InsightType.Monitoring && rule.priority === 0) {
    return { ...updated,
      priority: 1
    };
  }

  return updated;
}