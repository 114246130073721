"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RedirectLogin = void 0;

var _browserHistory = require("../../browserHistory");

var _react = require("react");

/* eslint-disable no-eq-null */
const RedirectLogin = () => {
  // If `redirect` property exists in the search, go to it. Otherwise go to /
  (0, _react.useEffect)(() => {
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get('redirect');
    redirect != null ? _browserHistory.history.push(redirect) : _browserHistory.history.push('/');
  }, []);
  return null;
};

exports.RedirectLogin = RedirectLogin;