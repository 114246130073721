"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useActions = useActions;

var React = _interopRequireWildcard(require("react"));

var _axios = _interopRequireDefault(require("axios"));

var _types = require("./types");

var _ = require("./");

/* eslint-disable no-eq-null */

/** Upload a file to Hydra's attachment storage */
async function uploadAttachmentHydra({
  organisationId,
  actionId,
  userId,
  commentId,
  attachment: file
}) {
  const formData = new FormData();
  formData.append('file', file);
  const body = {
    organisationId,
    filename: file.name,
    actionId,
    createdBy: userId,
    commentId
  };
  formData.append('body', new Blob([JSON.stringify(body)], {
    type: 'application/json'
  }));
  const hydraResponse = await _axios.default.post('/v1/attachments', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return hydraResponse.data;
}

function useActions() {
  const dispatch = (0, _.useDispatch)();
  const state = (0, _.useState)();
  const addAttachment = React.useCallback(async function addAttachment(attachment) {
    dispatch({
      type: 'SET_ATTACHMENT',
      attachment
    });
  }, []);
  /** Using the signed URL in state, upload the attachment to AWS */

  const uploadAttachment = React.useCallback(async function uploadAttachment(hydraUploadProps) {
    try {
      if (state.status !== _types.Status.ready) {
        throw Error('No attachment present');
      }

      if (state.attachment === undefined) {
        throw Error('No attachment present');
      }

      dispatch({
        type: 'UPLOAD_ATTACHMENT'
      });
      await uploadAttachmentHydra(hydraUploadProps);
      dispatch({
        type: 'COMPLETE'
      });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        error: `Failed to upload attachment: ${String(error)}`
      });
      throw error;
    }
  }, [state.attachment]);

  const resetAttachment = () => {
    dispatch({
      type: 'RESET'
    });
  };

  return {
    addAttachment,
    uploadAttachment,
    resetAttachment,
    status: state.status,
    error: state.error
  };
}