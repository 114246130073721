"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useInsightsEffects = useInsightsEffects;

var React = _interopRequireWildcard(require("react"));

var _lodash = _interopRequireDefault(require("lodash.groupby"));

var _lodash2 = _interopRequireDefault(require("lodash.intersection"));

var _insights = require("../../../context/insights");

var _actions = require("../../../context/insights/actions");

var _utils = require("../../../redux/modules/insights/utils");

var _occurrenceTransforms = require("../../../components/Analysis/utils/occurrenceTransforms");

var _types = require("../../../components/Analysis/utils/types");

var _utils2 = require("../../../components/Analysis/utils");

var _blocks = require("@bueno/blocks");

function indexOccurrencesById(occurrences) {
  return occurrences.reduce((group, occurrence) => {
    group[occurrence.occurrenceId] = occurrence;
    return group;
  }, {});
}
/**
 * A hook that requires everything for making a new request to the insights
 * endpoint. Under the hood it's merely a flow control wrapper around the base
 * `useInsights` hook that manages state and dispatch.
 *
 * @param timeRange
 * @param selectedSites
 * @param sites
 * @param projects
 */


function useInsightsEffects(timeRange = 3, selectedSites, shouldRequest = false, sites, projects) {
  const [state, dispatch] = (0, _insights.useInsights)(); // Retrieve initial data on FIRST load and when time range changes

  React.useEffect(() => {
    if (shouldRequest && !state.isLoading || !state.isLoaded && !state.isLoading) {
      // eslint-disable-next-line no-void
      void (0, _actions.getOccurrences)(dispatch, timeRange, selectedSites);
    }
  }, [shouldRequest]); // When the request has finished loading, compose occurrences

  React.useEffect(() => {
    if (!state.isLoaded || state.isLoading) return;
    const composedOccurrences = (0, _utils.composeOccurrences)(Object.values(state.rawData.hit), sites, projects); // Process siteGroups here as it's a one-off expensive operation

    const composedOccurrencesBySite = (0, _lodash.default)(composedOccurrences, o => o.siteId);
    dispatch({
      type: 'SET_COMPOSED_GROUPED_OCCURRENCES',
      composedOccurrences,
      composedOccurrencesBySite
    });
  }, [state.isLoaded, state.rawData, state.isLoading]); // Handle initial filtering of incoming/updated data

  React.useEffect(() => {
    const search = state.search || (0, _utils2.getInstantSearchQueryFromUrl)();
    let filtered;

    if (state.composedOccurrences.length > 0) {
      if (search !== undefined) {
        filtered = (0, _blocks.fuzzyFilter)(search, 'targetValueString', state.composedOccurrences);
      } else {
        filtered = state.composedOccurrences;
      }

      dispatch({
        type: 'SET_COMPOSED_FILTERED_OCCURRENCES',
        composedFilteredOccurrences: filtered,
        search: search
      });
    }
  }, [state.composedOccurrences, state.rawData]); // Process initial data once retrieved

  React.useEffect(() => {
    if (!state.isComposed || state.isLoading || !state.isFiltered) return; // Group filtered occurrences into the current analysis group

    const composedOccurrencesById = indexOccurrencesById(state.composedFilteredOccurrences);
    const groupKey = (0, _occurrenceTransforms.mapEnumToAnalysisGroupKey)(state.groupBy);
    const categoryKey = (0, _occurrenceTransforms.mapSelectedCategoryToCategoryKey)(state.category);
    const composedAndGroupedOccurrences = Object.values(state.rawData[groupKey]).reduce((acc, ids) => {
      // remove occurrence ids that don't exist in the grouped key or in the
      // currently selected category
      const {
        category: categoryData
      } = state.rawData; // NOTE: All will always return everything, the "other" selection in the
      // nav maps to "null" and may not show anything.

      const categorisedKeys = state.category === _types.Category.All ? ids : (0, _lodash2.default)(categoryData[categoryKey], ids);
      acc.push(categorisedKeys.filter(id => !!composedOccurrencesById[id]).map(id => composedOccurrencesById[id]));
      return acc;
    }, []).filter(arr => arr.length !== 0); // Convert analysis group to insights for render

    const composedAndGroupedInsights = (0, _occurrenceTransforms.sortInsights)((0, _occurrenceTransforms.groupOccurrencesIntoInsights)(composedAndGroupedOccurrences, state.groupBy), state.groupBy, state.sortBy); // HACK: re-sort here to put priority 0 at the bottom

    const sorted = state.groupBy !== _types.GroupBy.Priority ? composedAndGroupedInsights : composedAndGroupedInsights.sort((a, b) => {
      if (a[0].priority === 0) return 1;
      if (b[0].priority === 0) return -1;
      return 0;
    }); // Dispatch processed data and grouped data

    dispatch({
      type: 'SET_PROCESSED_INSIGHTS',
      occurrences: composedAndGroupedOccurrences,
      insights: sorted
    });
  }, [state.isLoaded, state.isLoading, state.groupBy, state.search, state.isComposed, state.category, state.isFiltered, state.composedFilteredOccurrences]); // Synchronise composedInsights with the sortBy state

  React.useEffect(() => {
    if (!state.isProcessed) return;
    const composedAndGroupedInsights = (0, _occurrenceTransforms.sortInsights)(state.composedAndGroupedInsights, state.groupBy, state.sortBy); // HACK: re-sort here to put priority 0 at the bottom

    const sorted = state.groupBy !== _types.GroupBy.Priority ? composedAndGroupedInsights : composedAndGroupedInsights.sort((a, b) => {
      if (a[0].priority === 0) return 1;
      if (b[0].priority === 0) return -1;
      return 0;
    });
    dispatch({
      type: 'SET_COMPOSED_AND_GROUPED_INSIGHTS',
      composedAndGroupedInsights: sorted
    });
  }, [state.sortBy, state.isProcessed, state.isFiltered]);
}