"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformObjectToCsvString = transformObjectToCsvString;
exports.getCsvBlobUrl = getCsvBlobUrl;
exports.handleCsvExport = handleCsvExport;
exports.getDateRange = getDateRange;

var _dateFns = require("date-fns");

var _eventTracking = require("../../utils/eventTracking");

/**
 * Retrieve CSV content from an object
 * @param obj
 */
function transformObjectToCsvString(obj) {
  let csvString = '';

  if (Object.keys(obj).length === 0) {
    return csvString;
  } // keys as first row


  csvString += Object.keys(obj[0]).join(',') + '\n'; // values afterwards

  csvString += obj.map(item => {
    return Object.values(item).map(value => {
      value = value.toString().replace(/"/g, '');
      return `${JSON.stringify(value)}`;
    }).join(',');
  }).join('\n');
  return csvString;
}
/**
 * Retrieve CSV content from an object
 * @param object
 */


function getCsvBlobUrl(obj) {
  const csvString = transformObjectToCsvString(obj);
  const blob = new Blob([csvString], {
    type: 'text/csv;charset=utf-8;'
  });
  return URL.createObjectURL(blob);
}

function handleCsvExport(rawData, fileName, dataFormatter, apertureUrl, exportType, days) {
  const formattedData = dataFormatter(rawData, apertureUrl);
  const csvData = getCsvBlobUrl(formattedData);
  const element = document.createElement('a');
  element.setAttribute('href', csvData);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
  const {
    startDate,
    endDate
  } = getDateRange(days);

  switch (exportType) {
    case 'action':
      (0, _eventTracking.exportActionDataEvent)(formattedData, startDate, endDate);
      break;

    case 'insight':
      (0, _eventTracking.exportInsightDataEvent)(formattedData, startDate, endDate);
      break;

    default:
      break;
  }
}

function getDateRange(days) {
  return {
    startDate: (0, _dateFns.format)((0, _dateFns.sub)(new Date(), {
      days: days
    }), 'yyyy-MM-dd'),
    endDate: (0, _dateFns.format)(new Date(), 'yyyy-MM-dd')
  };
}