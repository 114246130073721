"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = void 0;

var _lodash = _interopRequireDefault(require("lodash.isequal"));

var _utils = require("./utils");

const reducer = (state, action) => {
  const actionType = action.type;

  switch (action.type) {
    case 'UPDATE_INSIGHT_TYPE':
      return { ...state,
        shouldReset: false,
        insightType: action.insightType
      };

    case 'UPDATE_ACTIVE_PROJECT':
      {
        if (state.activeProject === action.activeProject) return state;
        let confirm = true;

        if (!state.pristine) {
          confirm = window.confirm("You're about to discard all unsaved changes, continue?");
        }

        if (confirm) {
          return { ...state,
            activeProject: action.activeProject,
            selectedRule: undefined
          };
        }

        return state;
      }

    case 'SAVE_CONFIG':
      return { ...state,
        isSaving: true
      };

    case 'LOAD_CONFIG':
      return { ...state,
        isLoading: true
      };

    case 'LOAD_CONFIG_SUCCESS':
      {
        return { ...state,
          shouldReset: false,
          isLoading: false,
          isSaving: false,
          saved: action.saved,
          active: action.saved,
          pristine: true
        };
      }

    case 'LOAD_CONFIG_FAIL':
      return { ...state,
        isLoading: false,
        isSaving: false
      };

    case 'UPDATE_CONFIG_RULES':
      {
        const active = { ...state.active
        }; // tslint:disable-next-line: strict-type-predicates

        const removeRule = active[action.rule.rule.id] !== undefined;

        if (removeRule) {
          delete active[action.rule.rule.id];
        } else {
          // NOTE: Strip unused rule properties ruleType and severity
          const existing = state.saved[action.rule.rule.id] || { ...action.rule,
            priority: 1
          };
          const updated = { ...existing,
            ...action.rule
          };
          const final = (0, _utils.handleDefaultPriority)(updated, state.insightType);
          active[action.rule.rule.id] = final;
        }

        const pristine = (0, _lodash.default)(active, state.saved);
        return { ...state,
          active,
          pristine,
          shouldReset: false
        };
      }

    case 'SET_SELECTED_RULE':
      {
        const selectedRule = state.active[action.selectedRule.rule.id] || state.saved[action.selectedRule.rule.id] || action.selectedRule;
        return { ...state,
          selectedRule,
          shouldReset: false
        };
      }

    case 'UPDATE_RULE_CONFIG':
      {
        const existing = state.saved[action.rule.rule.id] || {};
        const active = { ...state.active,
          [action.rule.rule.id]: { ...existing,
            ...action.rule
          }
        };
        const pristine = (0, _lodash.default)(active, state.saved);
        return { ...state,
          active,
          selectedRule: action.rule,
          shouldReset: false,
          pristine
        };
      }

    case 'BULK_UPDATE_RULE_CONFIG':
      const pristine = (0, _lodash.default)(action.rules, state.saved);
      return { ...state,
        active: action.rules,
        pristine
      };

    case 'RESET_CONFIG':
      return { ...state,
        active: state.saved,
        shouldReset: true,
        pristine: true
      };
    // TODO: Error state
    // case 'LOAD_RULES_FAIL':

    default:
      throw new Error(`Unhandled action type: ${actionType}`);
  }
};

exports.reducer = reducer;