"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useChart = void 0;

var React = _interopRequireWildcard(require("react"));

var _utilities = require("../../../../context/reports/utilities");

var _utils = require("../utils");

var _parseLatestYear = require("../utils/parseLatestYear");

/**
 * Retrieve charts configurations for utilities
 * @param filterBy
 */
const useChart = siteId => {
  const [state] = (0, _utilities.useUtilities)();
  const {
    metrics
  } = state; // Parsed portfolio data

  const portfolio = React.useMemo(() => {
    var _metrics$state$metric;

    const data = (_metrics$state$metric = metrics[state.metricsType]) === null || _metrics$state$metric === void 0 ? void 0 : _metrics$state$metric.portfolio;
    if (data === undefined) return;
    return (0, _utils.parsePortfolio)(data, state.metricsType);
  }, [metrics, state.metricsType]); // Parsed sites data

  const sites = React.useMemo(() => {
    const data = metrics[state.metricsType];
    if (data === undefined) return;
    return (0, _utils.parseSites)(data, state.metricsType);
  }, [metrics, state.metricsType, state.rankedCompareBy]); // Parsed single site  data

  const singleSite = React.useMemo(() => {
    var _metrics$state$metric2;

    const data = (_metrics$state$metric2 = metrics[state.metricsType]) === null || _metrics$state$metric2 === void 0 ? void 0 : _metrics$state$metric2.sites;
    if (siteId === undefined || data === undefined) return;
    if (data[siteId] === undefined) return;
    return (0, _utils.parsePortfolio)(data[siteId], state.metricsType);
  }, [metrics, siteId, state.metricsType]); // Parsed MAT data

  const mat = React.useMemo(() => {
    var _metrics$state$metric3;

    const data = (_metrics$state$metric3 = metrics[state.metricsType]) === null || _metrics$state$metric3 === void 0 ? void 0 : _metrics$state$metric3.portfolio;
    if (data === undefined) return;
    return (0, _parseLatestYear.parseLatestYear)(data, state.metricsType);
  }, [metrics, state.metricsType]); // Defaults

  const years = React.useMemo(() => {
    const data = state === null || state === void 0 ? void 0 : state.metrics[state.metricsType];
    if (data === undefined) return undefined;
    return Object.keys(data.portfolio);
  }, [state.metrics, state.metricsType]);
  const defaultActiveYears = React.useMemo(() => {
    if (years === undefined) return undefined;
    return years.sort((a, b) => Number(b) - Number(a)).slice(0, 3);
  }, [years]);
  const siteMat = React.useMemo(() => {
    var _metrics$state$metric4;

    const data = (_metrics$state$metric4 = metrics[state.metricsType]) === null || _metrics$state$metric4 === void 0 ? void 0 : _metrics$state$metric4.sites;
    if (siteId === undefined || data === undefined) return;
    if (data[siteId] === undefined) return;
    return (0, _parseLatestYear.parseLatestYear)(data[siteId], state.metricsType);
  }, [metrics, siteId, state.metricsType]); // Color Palette

  const colorPalette = React.useMemo(() => {
    if (years === undefined) return [];
    return (0, _utils.getTypeColors)(state.metricsType).range.reverse();
  }, [state.metricsType, state.metrics, years]);
  const crosshatchColor = React.useMemo(() => {
    if (years === undefined) return undefined;
    return (0, _utils.getTypeColors)(state.metricsType).crosshatch;
  }, [state.metricsType, state.metrics, years]);
  return {
    portfolio,
    mat,
    siteMat,
    sites,
    singleSite,
    defaultActiveYears,
    colorPalette,
    crosshatchColor
  };
};

exports.useChart = useChart;