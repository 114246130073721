"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Heavy = exports.TipTitle = exports.TipRow = exports.TipWrapper = exports.Tip = exports.ToolTip = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

function openClose({
  isOpen
}) {
  return isOpen ? 'block' : 'none';
}

const ToolTip = _styledComponents.default.div`
  display: ${openClose};
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background: ${_blocks.color.pureWhite};
  overflow: visible;
  z-index: ${_blocks.layer.contextNavigation};
`;
exports.ToolTip = ToolTip;
const Tip = _styledComponents.default.div`
  height: auto;
  max-height: 400px;
  max-width: 300px;
  min-width: 240px;
  border-radius: ${_blocks.borderRadius.default};
  z-index: ${_blocks.layer.contextNavigation};
  padding: ${_blocks.spacing.default};
  background: ${_blocks.color.pureWhite};
  box-shadow: ${_blocks.elevation.contentNavigation};
  overflow: auto;
  transform: translate(-25%, -100%);
  cursor: default;
`;
exports.Tip = Tip;
const TipWrapper = _styledComponents.default.span`
  position: relative;
  display: inline-block;
`;
exports.TipWrapper = TipWrapper;
const TipRow = _styledComponents.default.div`
  margin-bottom: ${_blocks.spacing.extraTight};
  font-size: 12px;
  font-weight: 400;
`;
exports.TipRow = TipRow;
const TipTitle = (0, _styledComponents.default)(TipRow)`
  margin-bottom: ${_blocks.spacing.tight};
  font-weight: 700;
`;
exports.TipTitle = TipTitle;
const Heavy = _styledComponents.default.strong`
  display: block;
  font-size: 14px;
  text-decoration: underline dashed;
  text-underline-position: under;
  margin-bottom: ${_blocks.spacing.extraTight};
`;
exports.Heavy = Heavy;