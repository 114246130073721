"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAxiosSwr = useAxiosSwr;
exports.useHashtagUsernameList = useHashtagUsernameList;

var _axios = _interopRequireDefault(require("axios"));

var _swr = _interopRequireDefault(require("swr"));

/* eslint-disable no-eq-null */

/**
 * A hook that wraps SWR and Axios together to quickly make simple requests
 * without manually creating React effects (which bonfire does a lot of)
 *
 * @example
 * const { data, error } = useAxiosSwr<Project[]>(`/projects`, {
 *   requestConfig: { method: 'get' },
 *   swrConfig: { revalidateOnFocus: false }
 * })
 */
function useAxiosSwr(url, config = {
  requestConfig: {},
  swrConfig: {}
}) {
  const {
    requestConfig = {},
    swrConfig = {}
  } = config;
  return (0, _swr.default)(url, async url => (await (0, _axios.default)(url, requestConfig)).data, swrConfig);
}
/** Fetch Bonfire Assignees without revalidating using `useAxiosSwr` */


function useHashtagUsernameList(projectKey, siteLabel, {
  skip
} = {
  skip: false
}) {
  return useAxiosSwr(skip === true ? null : `/hashtagUsernamelist/${projectKey}/${siteLabel}`, {
    requestConfig: {
      method: 'get'
    },
    swrConfig: {
      revalidateOnFocus: false,
      shouldRetryOnError: false
    }
  });
}