"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$LoadingItem = exports.$Loading = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _blocks = require("@bueno/blocks");

const anim = (0, _styledComponents.keyframes)`
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1.0);
  }
`;
const animationRule = (0, _styledComponents.css)`
  ${anim} 1.4s infinite ease-in-out both
`;
const $LoadingItem = _styledComponents.default.div`
  width: 24px;
  height: 24px;
  background-color: ${_blocks.palette.neutral4};
  border-radius: 100%;
  display: inline-block;
  animation: ${animationRule};

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
`;
exports.$LoadingItem = $LoadingItem;
const $Loading = _styledComponents.default.div`
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 100px;
  margin: 0 auto;
`;
exports.$Loading = $Loading;