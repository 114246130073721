"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.unsnoozeEquipRuleFlow = unsnoozeEquipRuleFlow;
exports.deleteSnoozeEquipRule = exports.unsnoozeEquipRules = exports.UNSNOOZE_RULE_EQUIP_REQUEST_FAIL = exports.UNSNOOZE_RULE_EQUIP_REQUEST_SUCCESS = exports.UNSNOOZE_RULE_EQUIP_REQUEST = exports.UNSNOOZE_RULE_EQUIP = void 0;

var _effects = require("redux-saga/effects");

var _helpers = require("../helpers/helpers");

var _requestSchedule = require("./requestSchedule");

const INITIAL_STATE = {
  pending: true,
  complete: false,
  error: undefined
};
const UNSNOOZE_RULE_EQUIP = 'bonfire/_BETA/triage/UNSNOOZE_RULE_EQUIP';
exports.UNSNOOZE_RULE_EQUIP = UNSNOOZE_RULE_EQUIP;
const UNSNOOZE_RULE_EQUIP_REQUEST = 'bonfire/_BETA/triage/UNSNOOZE_RULE_EQUIP_REQUEST';
exports.UNSNOOZE_RULE_EQUIP_REQUEST = UNSNOOZE_RULE_EQUIP_REQUEST;
const UNSNOOZE_RULE_EQUIP_REQUEST_SUCCESS = 'bonfire/_BETA/triage/UNSNOOZE_RULE_EQUIP_REQUEST_SUCCESS';
exports.UNSNOOZE_RULE_EQUIP_REQUEST_SUCCESS = UNSNOOZE_RULE_EQUIP_REQUEST_SUCCESS;
const UNSNOOZE_RULE_EQUIP_REQUEST_FAIL = 'bonfire/_BETA/triage/UNSNOOZE_RULE_EQUIP_REQUEST_FAIL';
exports.UNSNOOZE_RULE_EQUIP_REQUEST_FAIL = UNSNOOZE_RULE_EQUIP_REQUEST_FAIL;

function reducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case UNSNOOZE_RULE_EQUIP_REQUEST:
      return { ...state,
        pending: true,
        complete: false,
        error: undefined
      };

    case UNSNOOZE_RULE_EQUIP_REQUEST_SUCCESS:
      return { ...state,
        pending: false,
        complete: true,
        error: undefined
      };

    case UNSNOOZE_RULE_EQUIP_REQUEST_FAIL:
      return { ...state,
        pending: false,
        complete: true,
        error: action.error
      };

    default:
      {
        return state;
      }
  }
}

const unsnoozeEquipRules = ({
  equipRuleIds
}) => ({
  type: UNSNOOZE_RULE_EQUIP,
  equipRuleIds
});

exports.unsnoozeEquipRules = unsnoozeEquipRules;

const deleteSnoozeEquipRule = ({
  equipRuleIds
}) => ({
  types: [UNSNOOZE_RULE_EQUIP_REQUEST, UNSNOOZE_RULE_EQUIP_REQUEST_SUCCESS, UNSNOOZE_RULE_EQUIP_REQUEST_FAIL],
  promise: client => client.delete('/synco/equipRule/snooze', {
    data: {
      equipRuleIds
    }
  })
});

exports.deleteSnoozeEquipRule = deleteSnoozeEquipRule;

const getAuthUser = state => state.auth.user;

function* unsnoozeEquipRuleFlow() {
  while (true) {
    const {
      equipRuleIds
    } = yield (0, _effects.take)(UNSNOOZE_RULE_EQUIP);

    try {
      const user = yield (0, _effects.select)(getAuthUser); // Check that user has necessary permissions to make request

      if (!user.rolesRestriction) throw Error('Unable to authenticate user'); // validate that at least one equipRuleId has been given

      if (!equipRuleIds) throw Error('No equipRuleIds given');
      yield (0, _effects.fork)(_helpers.dispatchGeneratorFunc, deleteSnoozeEquipRule, {
        equipRuleIds
      });
      const {
        success,
        failure
      } = yield (0, _effects.race)({
        success: (0, _effects.take)(UNSNOOZE_RULE_EQUIP_REQUEST_SUCCESS),
        failure: (0, _effects.take)(UNSNOOZE_RULE_EQUIP_REQUEST_FAIL)
      });

      if (failure) {
        throw new Error('Failed to snooze ruleEquips');
      }

      if (success) {
        yield (0, _effects.put)((0, _requestSchedule.setShouldRequestInsights)(true));
        yield (0, _effects.put)((0, _requestSchedule.setShouldRequestMonitoring)(true));
      }
    } catch (err) {
      console.error(`Something went wrong attempting to wake up rule equip: ${err}`);
    }
  }
}