"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSortFunc = updateSortFunc;
exports.reducer = reducer;
exports.toggleIncludeOpenIssues = exports.ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS = exports.INITIAL_STATE = void 0;

var _columns = require("../../../utils/columns");

const INITIAL_STATE = {
  sortFilter: _columns.actionColumns.DATES.key,
  sortDirection: _columns.actionColumns.DATES.sortDirection
};
exports.INITIAL_STATE = INITIAL_STATE;
const ACTIONS_UI_UPDATE_SORT = 'bonfire/actionsUI/ACTIONS_UI_UPDATE_SORT';
const ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS = 'bonfire/actionsUI/ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS';
exports.ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS = ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS;

function updateSortFunc(sortFuncKey) {
  return {
    type: ACTIONS_UI_UPDATE_SORT,
    sortFuncKey
  };
}

const toggleIncludeOpenIssues = () => ({
  type: ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS
});

exports.toggleIncludeOpenIssues = toggleIncludeOpenIssues;

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONS_UI_UPDATE_SORT:
      {
        if (state.sortFilter === action.sortFuncKey) {
          return { ...state,
            sortDirection: state.sortDirection === 'asc' ? 'desc' : 'asc'
          };
        }

        return { ...state,
          sortFilter: action.sortFuncKey,
          sortDirection: _columns.actionColumns[action.sortFuncKey].sortDirection
        };
      }

    default:
      return state;
  }
}