"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PerformanceTertiaryLinks = exports.Route = void 0;
let Route;
exports.Route = Route;

(function (Route) {
  Route["Root"] = "/reports";
  Route["Performance"] = "/reports/performance";
  Route["Summary"] = "/reports/summary";
  Route["Generator"] = "/reports/generate";
  Route["UtilitiesPortfolio"] = "/reports/performance/utilities";
  Route["UtilitiesType"] = "/reports/performance/utilities/:type";
  Route["UtilitiesSite"] = "/reports/performance/utilities/:type/:siteId";
  Route["ComfortZones"] = "/reports/performance/comfort/:siteId";
  Route["Equipment"] = "/reports/performance/equipment";
})(Route || (exports.Route = Route = {}));

const PerformanceTertiaryLinks = [{
  label: i18n => i18n._({
    id: 'reports.performance_utilities',
    message: `Utilities`
  }),
  href: Route.UtilitiesPortfolio
}];
exports.PerformanceTertiaryLinks = PerformanceTertiaryLinks;