"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parsePortfolio = parsePortfolio;

var _types = require("../../../../context/reports/utilities/types");

var _toFixed = require("../../../../utils/toFixed");

var _getMonthName = require("./getMonthName");

function getMonths(portfolio) {
  const monthsSet = new Set();
  Object.values(portfolio).forEach(yearBin => {
    Object.keys(yearBin.months).forEach(monthNum => {
      monthsSet.add(monthNum);
    });
  });
  return Array.from(monthsSet.values()).sort((a, b) => Number(a) - Number(b));
}

function getPctChange(current, previous) {
  if (previous === undefined) return current;
  const cTotal = current.actual + current.estimated;
  const pTotal = previous.actual + previous.estimated;
  return { ...current,
    pctChange: (0, _toFixed.toFixed)((cTotal - pTotal) / pTotal * 100, 2)
  };
}

function parsePortfolio(portfolio, type) {
  const final = {};
  const years = Object.keys(portfolio);
  const months = getMonths(portfolio);
  years.forEach(year => {
    months.forEach(month => {
      const current = portfolio[year];
      const previous = portfolio[Number(year) - 1];
      const y = current;
      const m = y.months[month];
      const pm = previous === null || previous === void 0 ? void 0 : previous.months[month];
      if (m === undefined) return;

      if (y.current && m.current) {
        return;
      }

      if (final[month] === undefined) {
        final[month] = {
          id: (0, _getMonthName.getMonthName)(month),
          name: (0, _getMonthName.getMonthName)(month),
          key: month,
          unit: _types.MetricsUnit[type],
          data: {
            [year]: getPctChange(m.values, pm === null || pm === void 0 ? void 0 : pm.values)
          }
        };
      } else {
        final[month].data = { ...final[month].data,
          [year]: getPctChange(m.values, pm === null || pm === void 0 ? void 0 : pm.values)
        };
      }
    });
  });
  return final;
}