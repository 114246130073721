"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Color = exports.Icon = exports.EquipListRow = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _blocks = require("@bueno/blocks");

const ActiveBG = (0, _styledComponents.css)`
  .DataGrid-Cell {
    background-color: ${_blocks.color.theme.state.currentActive.background};
  }
`;
const EquipListRow = (0, _styledComponents.default)(_blocks.DataGridRow)`
  cursor: default;
  overflow: visible !important;

  .Row-actions-snoozeIcon,
  .Row-actions-reportIcon,
  .Row-actions-actionIcon {
    display: inline-block;
    margin-right: ${_blocks.spacing.tight};
  }
  &:hover {
    .Row-actions-snoozeIcon,
    .Row-actions-reportIcon,
    .Row-actions-actionIcon {
      opacity: 1;
    }
  }

  .Row-actions-reportIcon,
  .Row-actions-actionIcon {
    opacity: ${({
  isActive
}) => isActive ? 1 : 0};
  }

  .Row-actions-snoozeIcon {
    opacity: ${({
  isSnoozed,
  isActive
}) => isSnoozed || isActive ? 1 : 0};
  }

  ${({
  isActive
}) => isActive && ActiveBG}
`;
exports.EquipListRow = EquipListRow;
const Icon = _styledComponents.default.div`
  display: inline-flex;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  fill: ${_blocks.color.theme.icon.default};
`;
exports.Icon = Icon;
const Color = _styledComponents.default.span`
  color: ${_blocks.color.pureWhite};
`;
exports.Color = Color;