"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.history = void 0;

var _history = require("history");

const history = (0, _history.createBrowserHistory)();
exports.history = history;

if (window.Cypress) {
  window.browserHistory = history;
}