"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseTickValue = parseTickValue;

function parseTickValue(tickValue) {
  switch (true) {
    case tickValue === 0:
      {
        return '0';
      }

    case tickValue >= 1000000:
      {
        return (tickValue / 1000000).toString() + 'M';
      }

    case tickValue < 1000000:
      {
        return (tickValue / 1000).toString() + 'K';
      }

    default:
      {
        return (tickValue / 1000).toString() + 'K';
      }
  }
}