"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reducer = reducer;
exports.shouldRequestIssuesFlow = shouldRequestIssuesFlow;
exports.shouldRequestInsightsFlow = shouldRequestInsightsFlow;
exports.shouldRequestMonitoringFlow = shouldRequestMonitoringFlow;
exports.getShouldRequestMonitoring = exports.getShouldRequestInsights = exports.getShouldRequestIssues = exports.setShouldRequestMonitoring = exports.setShouldRequestInsights = exports.setShouldRequestIssues = exports.UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY = exports.UPDATE_SHOULD_REQUEST_ISSUES = exports.UPDATE_SHOULD_REQUEST_MONITORING = exports.UPDATE_SHOULD_REQUEST_INSIGHTS = void 0;

var _effects = require("redux-saga/effects");

var _selections = require("./selections");

var _issues = require("./issues");

var _actionsUI = require("./actionsUI");

var _actionTypes = require("../monitoring/actionTypes");

var _actionTypes2 = require("../action/actionTypes");

var _associate = require("./associate");

const UPDATE_SHOULD_REQUEST_INSIGHTS = 'bonfire/_BETA/requestSchedule/UPDATE_SHOULD_REQUEST_INSIGHTS';
exports.UPDATE_SHOULD_REQUEST_INSIGHTS = UPDATE_SHOULD_REQUEST_INSIGHTS;
const UPDATE_SHOULD_REQUEST_MONITORING = 'bonfire/_BETA/requestSchedule/UPDATE_SHOULD_REQUEST_MONITORING';
exports.UPDATE_SHOULD_REQUEST_MONITORING = UPDATE_SHOULD_REQUEST_MONITORING;
const UPDATE_SHOULD_REQUEST_ISSUES = 'bonfire/_BETA/requestSchedule/UPDATE_SHOULD_REQUEST_ISSUES';
exports.UPDATE_SHOULD_REQUEST_ISSUES = UPDATE_SHOULD_REQUEST_ISSUES;
const UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY = 'bonfire/_BETA/requestSchedule/UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY';
exports.UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY = UPDATE_SHOULD_REQUEST_MONTHLY_SUMMARY;
const INITIAL_STATE = {
  shouldRequestInsights: true,
  shouldRequestMonitoring: true,
  shouldRequestIssues: true
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_SHOULD_REQUEST_INSIGHTS:
      return { ...state,
        shouldRequestInsights: action.shouldRequestInsights
      };

    case UPDATE_SHOULD_REQUEST_MONITORING:
      return { ...state,
        shouldRequestMonitoring: action.shouldRequestMonitoring
      };

    case UPDATE_SHOULD_REQUEST_ISSUES:
      return { ...state,
        shouldRequestIssues: action.shouldRequestIssues
      };

    default:
      return state;
  }
}

const setShouldRequestIssues = shouldRequestIssues => ({
  type: UPDATE_SHOULD_REQUEST_ISSUES,
  shouldRequestIssues
});

exports.setShouldRequestIssues = setShouldRequestIssues;

const setShouldRequestInsights = shouldRequestInsights => ({
  type: UPDATE_SHOULD_REQUEST_INSIGHTS,
  shouldRequestInsights
});

exports.setShouldRequestInsights = setShouldRequestInsights;

const setShouldRequestMonitoring = shouldRequestMonitoring => ({
  type: UPDATE_SHOULD_REQUEST_MONITORING,
  shouldRequestMonitoring
}); // Selectors


exports.setShouldRequestMonitoring = setShouldRequestMonitoring;

const getShouldRequestIssues = state => state.requestSchedule.shouldRequestIssues;

exports.getShouldRequestIssues = getShouldRequestIssues;

const getShouldRequestInsights = state => state.requestSchedule.shouldRequestInsights;

exports.getShouldRequestInsights = getShouldRequestInsights;

const getShouldRequestMonitoring = state => state.requestSchedule.shouldRequestMonitoring; // Sagas


exports.getShouldRequestMonitoring = getShouldRequestMonitoring;

function* shouldRequestIssuesFlow() {
  while (true) {
    yield (0, _effects.race)({
      siteChanged: (0, _effects.take)(_selections.UPDATE_SELECTED_SITES),
      actionAdded: (0, _effects.take)(_actionTypes2.ADD_ACTION_REQUEST_SUCCESS),
      includeOpenStatusChanged: (0, _effects.take)(_actionsUI.ACTIONS_UI_TOGGLE_INCLUDE_OPEN_STATUS)
    });
    yield (0, _effects.put)(setShouldRequestIssues(true));
    yield (0, _effects.take)(_issues.LOAD_BETA_ISSUES_REQUEST_SUCCESS);
    yield (0, _effects.put)(setShouldRequestIssues(false));
  }
}

function* shouldRequestInsightsFlow() {
  while (true) {
    yield (0, _effects.race)({
      equipAssociated: (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
      siteChanged: (0, _effects.take)(_selections.UPDATE_SELECTED_SITES),
      daysRangeChanged: (0, _effects.take)(_selections.UPDATE_DAYS_RANGE)
    });
    yield (0, _effects.put)(setShouldRequestInsights(true));
  }
}

function* shouldRequestMonitoringFlow() {
  while (true) {
    yield (0, _effects.race)({
      equipAssociated: (0, _effects.take)(_associate.ASSOCIATE_RULE_EQUIP_REQUEST_SUCCESS),
      siteChanged: (0, _effects.take)(_selections.UPDATE_SELECTED_SITES),
      daysRangeChanged: (0, _effects.take)(_selections.UPDATE_DAYS_RANGE)
    });
    yield (0, _effects.put)(setShouldRequestMonitoring(true));
    yield (0, _effects.take)(_actionTypes.LOAD_MONITORING_REQUEST_SUCCESS);
    yield (0, _effects.put)(setShouldRequestMonitoring(false));
  }
}