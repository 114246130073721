"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appSetupFlow = appSetupFlow;

var _effects = require("redux-saga/effects");

var _reduxPersist = require("redux-persist");

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

var _browserHistory = require("../../../browserHistory");

var _actionCreators = require("../auth/actionCreators");

var _actionTypes = require("../auth/actionTypes");

var _actionTypes2 = require("./actionTypes");

var _actionTypes3 = require("../userSites/actionTypes");

var _selections = require("../_BETA/selections");

var _options = require("../../../components/Analysis/utils/options");

/**
 * Flow for parsing out:
 * ?dateRange=
 * ?sites=
 * (monitoring|insights)/:siteId/
 * Captures requested params from url and appends valid
 * params to app state during app setup
 */
function* handleUrlParamsFlow() {
  // If no sites in the url, grab em from the store and append the siteId to the end of URL
  const qs = _queryStringForAll.default.parse(_browserHistory.history.location.search, {
    parseNumbers: true,
    sort: false
  }); // HACK: Have to account for equip view, where siteId is a url parameter


  const equipViewRE = /\/analysis\/(:?insights|monitoring)\/(.+)\/\d+/;
  const urlSites = new Set();
  const siteParam = equipViewRE.exec(window.location.pathname); // if daysRange in search, and value is valid, update state
  // otherwise use default

  if ('daysRange' in qs && _options.TIME_RANGE_OPTIONS.monitoring.find(daysRangeOption => daysRangeOption.value === Number(qs.daysRange)) != null) {
    yield (0, _effects.put)((0, _selections.updateDaysRange)(Number(qs.daysRange)));
  } else {
    const defaultDaysRange = yield (0, _effects.select)(state => state.betaSelections.daysRange);

    const parsedDefaultDaysRangeURL = _queryStringForAll.default.stringify({ ...qs,
      daysRange: defaultDaysRange
    }, {
      arrayFormat: 'comma',
      sort: false
    });

    _browserHistory.history.replace({
      pathname: _browserHistory.history.location.pathname,
      search: parsedDefaultDaysRangeURL
    });
  } // Check for siteId param (equp view url)


  if (siteParam !== null) {
    urlSites.add(siteParam[2]);
  } // Check for sites qs parameter


  if ('sites' in qs) {
    const arr = qs.sites.split(',');
    arr.forEach(s => urlSites.add(s));
  } // Validate sites from URL (params + search), and append to selected sites in store


  if (urlSites.size === 0) {
    const {
      selectedSites
    } = yield (0, _effects.select)(state => state.betaSelections);

    const parsedDefaultSitesURL = _queryStringForAll.default.stringify({ ...qs,
      sites: selectedSites
    }, {
      arrayFormat: 'comma',
      sort: false
    });

    _browserHistory.history.replace({
      pathname: _browserHistory.history.location.pathname,
      search: parsedDefaultSitesURL
    });

    yield (0, _effects.put)((0, _selections.selectActiveSite)(selectedSites[0]));
  } else {
    const {
      sites
    } = yield (0, _effects.select)(state => state.auth.user.portfolio);
    const userSelectedSites = new Set(Array.from(urlSites));
    const siteIdsFromUrl = Array.from(userSelectedSites); // Validate requested sites against user access
    // Filter out invalid sites and log error (if invalid sites present)

    const userSiteIds = Object.keys(sites);
    const validatedSiteIds = siteIdsFromUrl.filter(siteIdFromUrl => userSiteIds.includes(siteIdFromUrl));
    const userCanAccessSitesFromUrl = validatedSiteIds.length === siteIdsFromUrl.length;
    yield (0, _effects.put)((0, _selections.updateSelectedSites)(validatedSiteIds));

    if (siteParam !== null && validatedSiteIds.includes(siteParam[2])) {
      yield (0, _effects.put)((0, _selections.selectActiveSite)(siteParam[2]));
    } else {
      yield (0, _effects.put)((0, _selections.selectActiveSite)(validatedSiteIds[0]));
    }

    try {
      if (!userCanAccessSitesFromUrl) {
        const validatedSearchParams = _queryStringForAll.default.stringify({ ...qs,
          sites: validatedSiteIds
        }, {
          arrayFormat: 'comma',
          sort: false
        });

        _browserHistory.history.replace({
          pathname: _browserHistory.history.location.pathname,
          search: validatedSearchParams
        });

        throw new Error("User doesn't have permission");
      }
    } catch (e) {
      console.error(e);
    }
  }
}

function* appSetupFlow() {
  yield (0, _effects.take)(_reduxPersist.REHYDRATE);

  while (true) {
    // Wait for the session provider to dispatch its events
    const {
      sessionSuccessAction,
      sessionFailureAction
    } = yield (0, _effects.race)({
      sessionSuccessAction: (0, _effects.take)(_actionTypes.SESSION_SUCCESS),
      sessionFailureAction: (0, _effects.take)(_actionTypes.SESSION_FAILURE)
    });

    if (sessionFailureAction) {
      yield (0, _effects.put)({
        type: _actionTypes2.APP_SETUP_ERROR,
        error: 'Failed to authenticate logged in user'
      });
    }

    if (sessionSuccessAction) {
      yield (0, _effects.put)((0, _actionCreators.loadPortfolio)());
      const {
        authUserSuccess,
        authUserFailure
      } = yield (0, _effects.race)({
        authUserSuccess: (0, _effects.take)(_actionTypes.LOAD_PORTFOLIO_SUCCESS),
        authUserFailure: (0, _effects.take)(_actionTypes.LOAD_PORTFOLIO_FAIL)
      });

      if (authUserSuccess) {
        // Wait for user sites to be loaded
        yield (0, _effects.take)(_actionTypes3.LOAD_USER_SITES_COMPLETE); // Grab any url sites/date ranges and apply to app state

        yield (0, _effects.call)(handleUrlParamsFlow);
        yield (0, _effects.put)({
          type: _actionTypes2.APP_SETUP_COMPLETE
        });
      }

      if (authUserFailure) {
        yield (0, _effects.put)({
          type: _actionTypes2.APP_SETUP_ERROR,
          error: 'Failed to authenticate logged in user'
        });
      }
    }
  }
}