"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionedMain = exports.ActionedCell = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

const ActionedCell = (0, _styledComponents.default)(_blocks.DataGridCell)`
  background-color: ${_blocks.palette.neutral2} !important;
`;
exports.ActionedCell = ActionedCell;
const ActionedMain = (0, _styledComponents.default)(_blocks.Main)`
  color: ${_blocks.color.theme.typography.subdued};
`;
exports.ActionedMain = ActionedMain;