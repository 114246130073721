"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getApertureHistoryLink = getApertureHistoryLink;

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

/* eslint-disable no-eq-null */
function getApertureHistoryLink({
  baseUrl,
  organisationId,
  siteId,
  equipId,
  ruleId,
  occurrenceId,
  range: [from, to]
}) {
  const query = _queryStringForAll.default.stringify({
    organisationId,
    siteId,
    equipId,
    ruleId,
    occurrenceId,
    from: from.toISOString(),
    to: to.toISOString()
  });

  return `${baseUrl}/inbound/bonfire/insight?${query}`;
}